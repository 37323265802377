import Badge from "@material-ui/core/Badge";
import { Theme, withStyles } from "@material-ui/core/styles";
import React, { useContext, useMemo } from "react";
import { DatabaseContext } from "../database/diffable/context";
import { DataAccessInformation } from "../database/diffable/dataaccess";
import { DiffableDatabase, MutableRow } from "../database/diffable/interfaces";
import { IDed, PreparedQuery } from "../database/sql";
import { MissingValueKey } from "../services/documentservice";
import ListItemNavLink, { ListItemNavLinkProps } from "./ListItemNavLink";
import { useDocumentTracker } from "./documents/DocumentTracker";

/**
 * Defines the properties for the CaseNavLink.
 */
type CaseNavLinkProps = ListItemNavLinkProps & {
  dataAccess: Record<string, DataAccessInformation>;
  tableBindings: PreparedQuery[] | undefined;
  definedValueKeys: MissingValueKey[] | undefined;
};

export function rowsForBindings(
  tableBindings: PreparedQuery[] | undefined,
  database: DiffableDatabase
) {
  const rows: Record<string, MutableRow<IDed>[]> = {};
  (tableBindings ?? []).forEach((bindingQuery: PreparedQuery) => {
    if (!(bindingQuery.tableName in rows)) {
      rows[bindingQuery.tableName] = [];
    }

    rows[bindingQuery.tableName] = [
      ...rows[bindingQuery.tableName],
      ...database.selectAllResults(bindingQuery),
    ];
  });
  return rows;
}

export default function CaseNavLink(props: CaseNavLinkProps) {
  const { tracker } = useDocumentTracker();
  const database = useContext(DatabaseContext)!;

  const rows = useMemo(() => {
    return rowsForBindings(props.tableBindings, database);
  }, [props.tableBindings, database]);

  const fieldColors = tracker.hasDocuments()
    ? Object.values(props.dataAccess)
        .map((dai: DataAccessInformation) => {
          // If the data access indicates a full table lookup, simply look for the field name
          // on the table.
          if (dai.isFullTableAccess) {
            for (const fieldName of dai.fieldsAccessed) {
              const dtg = tracker.documentForColumn(dai.tableName, fieldName);
              if (dtg !== undefined) {
                return dtg.color;
              }
            }
          } else {
            // Otherwise, check for each row.
            const tableRows = rows[dai.tableName];
            if (tableRows?.length > 0) {
              for (const fieldName of dai.fieldsAccessed) {
                for (const row of tableRows) {
                  const dtg = tracker.documentForField(
                    dai.tableName,
                    row.id(),
                    fieldName
                  );
                  if (dtg !== undefined) {
                    return dtg.color;
                  }
                }
              }
            }
          }

          return undefined;
        })
        .filter((color: string | undefined) => !!color) || []
    : [];

  const customColors =
    (props.definedValueKeys || [])
      .map(
        (key: MissingValueKey) => tracker.documentForMissingValueKey(key)?.color
      )
      .filter((color: string | undefined) => !!color) || [];
  const allColors = [...fieldColors, ...customColors];

  if (allColors && allColors.length) {
    const StyledBadge = withStyles((theme: Theme) => ({
      badge: {
        backgroundColor: allColors[0],
      },
    }))(Badge);

    return (
      <ListItemNavLink
        {...props}
        icon={
          <StyledBadge variant="dot" badgeContent=" " color="primary">
            {props.icon}
          </StyledBadge>
        }
      />
    );
  }

  return <ListItemNavLink {...props} />;
}
