import {
  DataFieldDefinitions,
  context,
  field,
} from "../../services/datafielddefinitions";
import { Address } from "../../sharedschema/address";
import { fullAddress } from "../shareddata";

export const CurrentAddress = context<Address>("address");

const OptionalAddress = field(
  {
    description: (sd) => `The street address of ${sd}, including newlines`,
    parameters: {
      address: CurrentAddress,
    },
    example: "123 Fake Street\nApartment 1A",
    isHelperField: true,
  },
  async ({ ctx, address }) => {
    const addr = ctx.db.optional(address);
    return addr.address2 ? `${addr.address}\n${addr.address2}` : addr.address;
  }
);

const OptionalInlineAddress = field(
  {
    description: (sd) => `The street address of ${sd}, without newlines`,
    parameters: {
      address: CurrentAddress,
    },
    example: "123 Fake Street, Apartment 1A",
    isHelperField: true,
  },
  async ({ ctx, address }) => {
    const addr = ctx.db.optional(address);
    return addr.address2 ? `${addr.address}, ${addr.address2}` : addr.address;
  }
);

const OptionalFullAddressInline = field(
  {
    description: (sd) => `The full address of ${sd}, including newlines`,
    parameters: {
      address: CurrentAddress,
    },
    example: "123 Fake Street, Apartment 1A, Fake City, FS 12345",
  },
  async ({ ctx, address }) => {
    const addr = ctx.db.optional(address);
    return fullAddress(addr, ctx.db);
  }
);

const OptionalAddressCity = field(
  {
    description: (sd) => `The city of the address of ${sd}`,
    parameters: {
      address: CurrentAddress,
    },
    example: "Fake City",
    isHelperField: true,
  },
  async ({ ctx, address }) => {
    const addr = ctx.db.optional(address);
    return addr.city;
  }
);

const OptionalAddressState = field(
  {
    description: (sd) =>
      `The two-letter state abbreviation for the address of ${sd}`,
    parameters: {
      address: CurrentAddress,
    },
    example: "PA",
    isHelperField: true,
  },
  async ({ ctx, address }) => {
    const addr = ctx.db.optional(address);
    return addr.state;
  }
);

const OptionalAddressCounty = field(
  {
    description: (sd) => `The county of the address of ${sd}`,
    parameters: {
      address: CurrentAddress,
    },
    example: "Bucks",
    isHelperField: true,
  },
  async ({ ctx, address }) => {
    const addr = ctx.db.optional(address);
    return addr.county;
  }
);

const OptionalAddressZipCode = field(
  {
    description: (sd) => `The zip code of the address of ${sd}`,
    parameters: {
      address: CurrentAddress,
    },
    example: "19001",
    isHelperField: true,
  },
  async ({ ctx, address }) => {
    const addr = ctx.db.optional(address);
    return addr.zipcode;
  }
);

export const OptionalAddressFields: DataFieldDefinitions = {
  Address: OptionalAddress,
  Address_Inline: OptionalInlineAddress,
  City: OptionalAddressCity,
  State: OptionalAddressState,
  County: OptionalAddressCounty,
  Zip_Code: OptionalAddressZipCode,
  Full_Address_Inline: OptionalFullAddressInline,
};
