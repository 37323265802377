import dayjs from "dayjs";
import moment from "moment";
import ordinal from "ordinal";
import { MISSING_FIRM_CASE_REFERENCE_KEY } from "../components/SettingsPane";
import {
  deserializeFromString,
  summarizeDocDate,
} from "../components/fields/DocumentDateField";
import { DiffableDatabase } from "../database/diffable/interfaces";
import { sql } from "../database/sql";
import {
  DocumentDataBuilder,
  TrackedDatabase,
  TrackedDatabaseState,
} from "../services/documentservice";
import { Address, Address as AddressDef } from "../sharedschema/address";
import { Case } from "../types/case";

function caseReferenceCheck(
  cse: Case,
  db: DiffableDatabase
): [string, boolean] {
  if (cse.firmCaseReference) {
    return ["", true];
  }

  return ["Please enter a firm case reference number", false];
}

export function firmFields(
  cse: Case,
  db: TrackedDatabase
): Record<string, DocumentDataBuilder> {
  return {
    Firm_Name: async () => cse.firm?.title!,
    Firm_Phone: async () => cse.firm?.phoneNumber!,
    Firm_Address: async () => cse.firm?.address!,
    Firm_Fax: async () => cse.firm?.faxNumber!,
    Firm_Address_Inline: async () => cse.firm?.address!.split("\n").join(" ")!,
    Firm_Case_No: async () => {
      if (!cse.firmCaseReference) {
        if (db.state() === TrackedDatabaseState.HAS_UNSAVED_CHANGED) {
          return "";
        }

        db.addCustomMissingField({
          key: MISSING_FIRM_CASE_REFERENCE_KEY,
          checker: caseReferenceCheck,
        });
      }

      return cse.firmCaseReference;
    },
  };
}

export const emptyAddress: AddressDef = {
  id: -1,
  address: " ",
  address2: " ",
  city: " ",
  county: " ",
  state: " ",
  zipcode: " ",
};

export function exportAddresses(
  db: TrackedDatabase,
  tableName: string,
  prefix: string,
  expectedCount?: number
) {
  const addresses = db.list<Address>(sql`select * from ${tableName}`);
  return exportAllAddresses(db, addresses, prefix, expectedCount);
}

export function exportAllAddresses(
  db: TrackedDatabase,
  addresses: Address[],
  prefix: string,
  expectedCount?: number
) {
  let finalObject = {};
  addresses.forEach((address: Address, index: number) => {
    finalObject = {
      ...finalObject,
      ...exportAddress(
        db,
        `${prefix}_Address`,
        address,
        prefix,
        `_${index + 1}`
      ),
    };
  });

  if (expectedCount !== undefined && addresses.length < expectedCount) {
    for (var i = addresses.length; i < expectedCount; ++i) {
      finalObject = {
        ...finalObject,
        ...exportAddress(
          db,
          `${prefix}_Address`,
          emptyAddress,
          prefix,
          `_${i + 1}`
        ),
      };
    }
  }

  return finalObject;
}

const joinPiecesWithDelimeter = (
  delimeter: string | undefined,
  ...args: string[]
) => {
  return Array.from(args)
    .filter((piece: string) => piece.length)
    .join(delimeter ?? ", ");
};

const isEmptyAddress = (db: TrackedDatabase, addr: AddressDef | undefined) => {
  if (addr === undefined) {
    return true;
  }

  const address = db.optional(addr);
  return !(
    address.address.trim() &&
    address.city.trim() &&
    address.state.trim() &&
    address.zipcode.trim()
  );
};

export function exportRequiredAddress(
  db: TrackedDatabase,
  prefix: string,
  address: AddressDef,
  mainPrefix?: string,
  suffix?: string
): Record<string, DocumentDataBuilder> {
  suffix = suffix ?? "";

  return {
    [`${mainPrefix || prefix}_Address${suffix}`]: async () =>
      db.optional(address).address2
        ? `${address.address}\n${address.address2}`
        : address.address,
    [`${mainPrefix || prefix}_Address${suffix}_Inline`]: async () =>
      db.optional(address).address2
        ? `${address.address}, ${address.address2}`
        : address.address,
    [`${prefix}${suffix}_City`]: async () => address.city,
    [`${prefix}${suffix}_State`]: async () => address.state,
    [`${prefix}${suffix}_County`]: async () => address.county,
    [`${prefix}${suffix}_Zip_Code`]: async () => address.zipcode,
    [`${mainPrefix || prefix}_Full_Address${suffix}_Inline`]: async () =>
      fullAddress(address, db),
  };
}

export function exportAddress(
  db: TrackedDatabase,
  prefix: string,
  address?: AddressDef,
  mainPrefix?: string,
  suffix?: string
): Record<string, DocumentDataBuilder> {
  suffix = suffix ?? "";

  if (isEmptyAddress(db, address)) {
    return {
      [`${mainPrefix || prefix}_Address${suffix}`]: async () => "",
      [`${mainPrefix || prefix}_Address${suffix}_Inline`]: async () => "",
      [`${prefix}${suffix}_City`]: async () => "",
      [`${prefix}${suffix}_State`]: async () => "",
      [`${prefix}${suffix}_County`]: async () => "",
      [`${prefix}${suffix}_Zip_Code`]: async () => "",
      [`${mainPrefix || prefix}_Full_Address${suffix}_Inline`]: async () =>
        "<<ADDRESS>>",
    };
  }

  return exportRequiredAddress(db, prefix, address!, mainPrefix, suffix);
}

export function fullAddress(address: AddressDef, db: TrackedDatabase): string {
  if (isEmptyAddress(db, address)) {
    return "";
  }

  return (
    joinPiecesWithDelimeter(
      ", ",
      address.address,
      db.optional(address).address2,
      address.city,
      address.state
    ) +
    " " +
    address.zipcode
  );
}

export function fullMultilineAddress(
  address: AddressDef,
  db: TrackedDatabase
): string {
  if (isEmptyAddress(db, address)) {
    return "";
  }

  let addressString = address.address + "\n";
  if (db.optional(address).address2) {
    addressString += db.optional(address).address2 + "\n";
  }

  addressString += joinPiecesWithDelimeter(", ", address.city, address.state);
  return addressString + " " + address.zipcode;
}

export function append(first: string, second: string | undefined): string {
  if (!second) {
    return first;
  }

  return first + " " + second;
}

export function date(value: string | undefined): string {
  if (!value) {
    return "";
  }

  const day = dayjs(value);
  return day.format("MMMM D, YYYY");
}

export function age(value: string | undefined): string {
  if (!value) {
    return "";
  }

  const day = dayjs(value);
  return dayjs().diff(day, "year").toString();
}

export function documentDate(value: string | undefined): string {
  if (!value) {
    return "";
  }

  const deserialized = deserializeFromString(value);
  return summarizeDocDate(deserialized.m, deserialized.d, deserialized.y);
}

export function documentShortDate(value: string | undefined): string {
  if (!value) {
    return "";
  }

  const deserialized = deserializeFromString(value);
  const day = deserialized.d;
  const month = deserialized.m;
  const year = deserialized.y;

  return `${month ? month : "__"}/${day ? day : "__"}/${year || "____"}`;
}

export function compareDocumentDates(left: string, right: string): number {
  const deserializedLeft = deserializeFromString(left);
  const deserializedRight = deserializeFromString(right);
  return (
    (deserializedLeft.y - deserializedRight.y) * 365 +
    (deserializedLeft.m - deserializedRight.m) * 31 +
    (deserializedLeft.d - deserializedRight.d)
  );
}

export function exportDocumentDate(
  dateValue: string | undefined,
  prefix: string
): Record<string, DocumentDataBuilder> {
  const deserialized = deserializeFromString(dateValue ?? "");
  return {
    [`${prefix}_Month`]: async () =>
      deserialized.m ? moment.months()[deserialized.m - 1] : "________",
    [`${prefix}_Day`]: async () =>
      deserialized.d ? ordinal(deserialized.d) : "__",
    [`${prefix}_Year`]: async () => (deserialized.y ? deserialized.y : "____"),
  };
}

export function niceNumber(value: string | number | undefined | null): string {
  if (typeof value === "string") {
    value = parseFloat(value);
  }

  value = value ?? 0;

  // From: https://stackoverflow.com/a/16233919
  var formatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(value);
}

export function currency(value: string | number | undefined | null): string {
  if (typeof value === "string") {
    if (value.startsWith("$")) {
      return value;
    }

    value = parseFloat(value);
  }

  value = value ?? 0;

  // From: https://stackoverflow.com/a/16233919
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(value);
}

export function combinedCurrency(
  values: (number | undefined | null)[]
): string {
  const combined = values.reduce((previous, current) => {
    return (previous ?? 0) + (current ?? 0);
  });
  return currency(combined);
}

export function percentage(
  top: string | number | undefined | null,
  bottom: number | undefined | null
): string {
  if (typeof top === "string") {
    top = parseFloat(top);
  }

  top = top ?? 0;
  bottom = bottom ?? 1;
  return `${((top / bottom) * 100).toFixed(2)}%`;
}

export function joinMultiple<T>(
  items: T[],
  prefix: string,
  suffix: string,
  mapper: (item: T) => string,
  sorter?: (left: T, right: T) => number
): string {
  if (items.length === 0) {
    return "";
  }

  const copy = [...items];
  copy.sort(sorter);

  const mapped = copy.map(mapper);
  const joined =
    mapped.length >= 2
      ? mapped.slice(0, -1).join(", ") + " and " + mapped.slice(-1)
      : mapped;
  return `${prefix}${joined}${suffix}`;
}

const SCALES = [
  (index: number) => String.fromCharCode(97 + index).toUpperCase(), // A
  (index: number) => romanize(index + 1).toLowerCase(), // i
  (index: number) => (index + 1).toString(), // 1
  (index: number) => String.fromCharCode(97 + index), // a
  (index: number) => romanize(index + 1), // I
];

export function scale(index: number, level: number): string {
  return SCALES[level % SCALES.length](index);
}

// From: https://stackoverflow.com/a/9083076/15918573
function romanize(num: number): string {
  if (isNaN(num)) {
    return "";
  }

  var digits = String(+num).split(""),
    key = [
      "",
      "C",
      "CC",
      "CCC",
      "CD",
      "D",
      "DC",
      "DCC",
      "DCCC",
      "CM",
      "",
      "X",
      "XX",
      "XXX",
      "XL",
      "L",
      "LX",
      "LXX",
      "LXXX",
      "XC",
      "",
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
    ],
    roman = "",
    i = 3;
  while (i--) {
    roman = (key[+digits.pop()! + i * 10] || "") + roman;
  }
  return Array(+digits.join("") + 1).join("M") + roman;
}
