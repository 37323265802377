import { DatabaseMigration } from "../../database/schema";
import { CaseTemplate } from "../interfaces";
import * as dataAccess from "./dataaccess.json";
import { CommercialLeaseDataFields } from "./datafields";
import { initialData } from "./initialdata";
import * as migrations from "./migrations.json";
import * as schema from "./schema.json";
import { CommercialLeasePanels } from "./ui";

/**
 * Defines a Commercial Lease flow.
 */
export const CommercialLeaseTemplateDefinition: CaseTemplate = {
  id: "commerciallease",
  title: "Commercial Lease",
  schema: {
    $schema: schema.$schema,
    databaseSchemaRevision: schema.databaseSchemaRevision,
    definitions: schema.definitions,
    initialData: initialData,
    migrations: migrations["migrations"] as DatabaseMigration[],
  },
  description: "Lease transaction",
  panels: CommercialLeasePanels,
  dataAccess: dataAccess["dai"],
  documentFields: CommercialLeaseDataFields,
};
