import { faPenFancy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import { useTheme } from "@material-ui/core/styles";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable, { Icons } from "material-table";
import React, { forwardRef, useContext, useState } from "react";
import { DatabaseContext } from "../database/diffable/context";
import { MutableRow } from "../database/diffable/interfaces";
import { useQuery } from "../database/diffable/query";
import { sql } from "../database/sql";
import { AUTOCOMPLETE_FIRM_ATTORNEYS } from "../queries/firm";
import { MissingValueKey } from "../services/documentservice";
import { PleadingAttorney } from "../sharedschema/pleadingattorney";
import { FirmMember, User } from "../types/firmmember";
import BoundAutocomplete from "./BoundAutocomplete";
import { CurrentUserContext } from "./CurrentContext";
import { CustomMissingBorder } from "./fields/CustomMissingBorder";
import UserView, { UserIcon } from "./UserView";

export const MISSING_SIGNER_KEY = "signer_attorney";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
    addPanel: {
      padding: theme.spacing(2),
      display: "grid",
      gridTemplateColumns: "1fr auto",
      columnGap: theme.spacing(1),
    },
    attorneyResult: {
      display: "grid",
      gridTemplateColumns: "1fr auto",
      width: "100%",
    },
    signerChip: {
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
    pleadingAttorney: {
      display: "flex",
      alignItems: "center",
    },
  })
);

function PleadingAttorneyTable(props: {
  attorneys: MutableRow<PleadingAttorney>[];
  indicateChange: () => void;
}) {
  const database = useContext(DatabaseContext)!;
  const theme = useTheme();

  const tableIcons: Icons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const removeAttorney = (attorney: PleadingAttorney) => {
    database.transaction.deleteRow(
      "pleadingattorney",
      attorney.id,
      `Removed ${attorney.fullName} as a pleading attorney`
    );
  };

  const markSigner = (attorney: PleadingAttorney) => {
    props.attorneys.forEach((current: MutableRow<PleadingAttorney>) => {
      if (current.getField("isSigner") === 1) {
        database.mutableForRow("pleadingattorney", current.row).setField(
          "isSigner",
          { value: 0, isValid: true, niceValue: "no" },
          {
            title: `${current.row.fullName} Is Signer`,
          }
        );
      }
    });

    database.mutableForRow("pleadingattorney", attorney).setField(
      "isSigner",
      { value: 1, isValid: true, niceValue: "yes" },
      {
        title: `${attorney.fullName} Is Signer`,
      }
    );
    props.indicateChange();
  };

  const classes = useStyles();

  return (
    <MaterialTable
      icons={tableIcons}
      columns={[
        {
          title: "",
          field: "",
          render: (mutable: MutableRow<PleadingAttorney>) => {
            return (
              <span
                style={{
                  borderWidth: "2px",
                  borderStyle: "solid",
                  borderColor: mutable.isInsertedRow()
                    ? theme.palette.warning.main
                    : "transparent",
                }}
              ></span>
            );
          },
          cellStyle: { width: "2px" },
        },
        {
          title: "",
          field: "signer",
          render: (mutable: MutableRow<PleadingAttorney>) => {
            return mutable.getField("isSigner") === 1 ? (
              <Chip
                color="primary"
                label={
                  <span className={classes.signerChip}>
                    <FontAwesomeIcon icon={faPenFancy} />
                    Signer
                  </span>
                }
              />
            ) : undefined;
          },
        },
        {
          title: "Attorney",
          field: "fullName",
          render: (mutable: MutableRow<PleadingAttorney>) => {
            const attorney = mutable.row;
            return (
              <span className={classes.pleadingAttorney}>
                <UserIcon
                  id={attorney.userId}
                  name={attorney.fullName}
                  avatarUrl={attorney.avatarUrl}
                />
                {attorney.fullName}
              </span>
            );
          },
          cellStyle: { width: "100%" },
        },
        {
          title: "",
          field: "actions",
          render: (mutable: MutableRow<PleadingAttorney>) => {
            const attorney = mutable.row;
            return (
              <Button
                disabled={mutable.getField("isSigner") === 1}
                onClick={() => markSigner(attorney)}
                style={{ whiteSpace: "nowrap" }}
              >
                Make Signer
              </Button>
            );
          },
        },
        {
          title: "",
          field: "actions",
          render: (mutable: MutableRow<PleadingAttorney>) => {
            const attorney = mutable.row;
            return (
              <Button onClick={() => removeAttorney(attorney)}>Remove</Button>
            );
          },
        },
      ]}
      title="Pleading Attorneys"
      data={props.attorneys}
      options={{ pageSize: 3, pageSizeOptions: [3] }}
    />
  );
}

/**
 * PleadingAttorneyManager defines a control for configuring the pleading attorneys
 * on a case and choosing one as the signer.
 */
export function PleadingAttorneyManager(props: {
  signerKey?: MissingValueKey;
}) {
  const classes = useStyles();
  const authenticatedUser = useContext(CurrentUserContext);
  const database = useContext(DatabaseContext)!;

  const { results } = useQuery<PleadingAttorney>(
    sql`select * from ${"pleadingattorney"}`
  );

  const [localIndex, setLocalIndex] = useState(0);

  const handleAddMe = () => {
    handleAttorneySelected(authenticatedUser!);
  };

  const handleAttorneySelected = (attorney: User) => {
    // Check for the attorney existing in the table to ensure they are not added twice.
    const found = results?.filter((existing: MutableRow<PleadingAttorney>) => {
      return existing.row.userId === attorney.id;
    });
    if (found && found.length) {
      return;
    }

    // Add the attorney to the list.
    const toAdd = {
      userId: attorney.id,
      fullName: attorney.name!,
      isSigner: 0,
      avatarUrl: attorney.avatarUrl || "",
    };

    database.transaction.insertRow(
      "pleadingattorney",
      toAdd,
      `Added ${attorney.name} as a pleading attorney`
    );
    setLocalIndex(localIndex + 1);
  };

  return (
    <Box className={classes.root}>
      <CustomMissingBorder bindKey={props.signerKey ?? MISSING_SIGNER_KEY}>
        {results && (
          <PleadingAttorneyTable
            attorneys={results}
            indicateChange={() => setLocalIndex(localIndex + 1)}
          />
        )}
        <Paper className={classes.addPanel}>
          <div>
            <BoundAutocomplete<FirmMember>
              label="Type to add a pleading attorney"
              placeholder="Attorney name"
              enterText="Enter the name of an attorney to add as a pleading attorney"
              query={{
                gql: AUTOCOMPLETE_FIRM_ATTORNEYS,
                variables: {
                  firmId: authenticatedUser?.firm?.id!,
                },
                recordsKey: ["firmById", "attorneys"],
              }}
              resultLabel={(member: FirmMember) => member.name || ""}
              renderResult={(member: FirmMember) => {
                return (
                  <div className={classes.attorneyResult}>
                    <UserView user={member} />
                  </div>
                );
              }}
              resultSelected={handleAttorneySelected}
              fullWidth
            />
          </div>
          <Button variant="contained" color="primary" onClick={handleAddMe}>
            Add myself as Pleading Attorney
          </Button>
        </Paper>
      </CustomMissingBorder>
    </Box>
  );
}
