import { IDed } from "../database/sql";
import {
  currency,
  date,
  documentDate,
  niceNumber,
  percentage,
} from "../shareddata/shareddata";
import { Case } from "../types/case";
import {
  FieldReference,
  FirmMemberLookup,
  TrackedDatabase,
} from "./documentservice";

// extractGeneric extracts the first generic from a Processor (`V`) and returns it.
export type extractGeneric<T> = T extends Processor<infer X, any, any>
  ? X
  : T extends Context<infer X>
  ? X
  : never;

// GeneratorContext is the type of the `ctx` passed to generator and processor functions.
export type GeneratorContext = {
  db: TrackedDatabase;
  case: Case;
  memberLookup: FirmMemberLookup;
};

// error indicates an error was found during processing that should be bubbled up to the user.
export const error = (message: string, field: FieldReference): Issue => {
  return { issueKind: "error", issue: message, field: field };
};

const missingDep = (
  processor: Processor<any, any, any>,
  rerun: () => Promise<any | Issue>
): Issue => {
  return { issueKind: "missingdep", processor: processor, rerun: rerun };
};

export type ErrorIssue = {
  issueKind: "error";
  issue: string;
  field: FieldReference;
};

export type MissingValueIssue = {
  issueKind: "missingdep";
  processor: Processor<any, any, any>;
  rerun: () => Promise<any | Issue>;
};

/**
 * issue indicates an issue that was generated by a processor.
 */
export type Issue = ErrorIssue | MissingValueIssue;

const isIssue = (value: any): value is Issue => {
  return typeof value === "object" && !!value && "issueKind" in value;
};

// GeneratorFunc is the type of the generator/processor functions.
// V - The type of the returned value
// A - A record type of additional arguments added to the single argument context.
type GeneratorFunc<V, A> = ({
  ctx,
}: { ctx: GeneratorContext } & A) => Promise<V | Issue>;

// ProcessorDefinition defines a processor, which executes before fields are generated.
export type ProcessorDefinition<D extends Record<string, any>> = {
  // id is the *unique* ID for this processor.
  id: string;

  // description is the *human readable* description of the processor. This should be highly descriptive
  // so that LLM tooling can also understand the field's meaning.
  description: string;

  // dependencies are the dependencies necessary to run this processor, if any. Recursive dependencies
  // are *not* supported and will result in failure at runtime.
  dependencies?: D;

  /**
   * itemDescription is a description for the individual items produced by this processor,
   * if a mapping processor.
   */
  itemDescription?: string;

  /**
   * missingMessage, if specified, will mark the processor as missing with the given message.
   */
  missingMessage?: string;
};

// Processor is a fully defined processor (see `processor` below) that will execute the given
// `run` function with the given dependencies (if any) and return a value. If the value returned
// is undefined or an `issue`, then no dependent processors or fields will be return.
//
// V - the type of the value being returned.
// A - inferred set of arguments from the dependencies, based on their return types.
// D - inferred set of dependencies based on the `dependencies` definition, if any.
export type Processor<
  V,
  A extends { [key in keyof D]: NonNullable<extractGeneric<D[key]>> },
  D extends { [key: string]: Processor<any, any, any> | Context<any> }
> = ProcessorDefinition<D> & {
  run: GeneratorFunc<V, A>;
};

// processor type checks and returns a new processor. A processor is a dependency-preserving
// function which takes value(s) from other processors (if applicable) and produces a new value
// or undefined or an issue. processors are used by fields to generate their values.
// Unlike a field, a processor should consist of more complex data generation.
export const processor = <
  A extends { [key in keyof D]: NonNullable<extractGeneric<D[key]>> },
  D extends { [key: string]: Processor<any, any, any> | Context<any> },
  V
>(
  args: ProcessorDefinition<D>,
  run: GeneratorFunc<V, A>
): Processor<V, A, D> => {
  return { ...args, run: run };
};

// FieldValue indicates the kind of values that can be returned by a field. If an undefined value
// is returned, the field's default value is used (if any). Otherwise, an empty string is used.
type FieldValue = string | number | undefined | null;

// field type checks and returns a new field. A field is a value that will be mail merged into the
// resulting document. Like a processor, fields can rely on the results of processors. Unlike
// processors, fields cannot rely upon other fields and will the information shown to the end
// user in the UI and in documentation via mail merge.
export const field = <
  A extends { [key in keyof P]: NonNullable<extractGeneric<P[key]>> },
  P extends { [key: string]: Processor<any, any, any> | Context<any> }
>(
  args: DataFieldDefinition<P>,
  generator: GeneratorFunc<FieldValue, A>
): DataField<P, A> => {
  return { ...args, generator: generator };
};

/**
 * FieldKind are the custom kinds of fields produced.
 */
export enum FieldKind {
  /**
   * Date will format the field as a date.
   */
  Date,

  /**
   * DocumentDate will format the field as a document date.
   */
  DocumentDate,

  /**
   * Currency will format the field as currency.
   */
  Currency,

  /**
   * Percentage will format as a percentage, from 0% to 100%
   */
  Percentage,

  /**
   * NiceNumber will format as a number with proper delimeters.
   */
  NiceNumber,
}

// DataFieldDefinition represents a field.
type DataFieldDefinition<P extends Record<string, any>> = {
  // description is the *human readable* description of the field. This should be highly descriptive
  // so that LLM tooling can also understand the field's meaning.
  description: string | ((subdescription: string) => string);

  // parameters are the optional processors that this field requires to generate its value.
  parameters?: P;

  // isHelperField indicates that this field is being generated solely as a "helper" for making
  // mail merge easier, and thus does not contain "new" information.
  isHelperField?: boolean;
} & ExampleOrKind;

type ExampleOrKind =
  | {
      // example is an example of the field's value.
      example: string;
    }
  | {
      // kind, if specified, indicates how the field should be automatically rendered.
      kind: FieldKind;
    };

// DataField represents a single defined field.
export type DataField<
  P extends Record<string, any>,
  A
> = DataFieldDefinition<P> & {
  generator: GeneratorFunc<FieldValue, A>;
};

// DataFieldDefinitions represents a set of defined fiends.
export type DataFieldDefinitions = Record<string, DataFieldDefinitionValue>;

// DataFieldDefinitionValue is the type of values for a data field definition.
type DataFieldDefinitionValue = DataField<any, any> | string | Expander;

// fieldsPerKind defines the fields returned in the `defs` over each kind of value
// found in the given enumeration description mapping. The processor is a processor which must
// return a mapping from the enum kind to an associated value, which is in turn passed to the
// `defs` via the `context`. As an example, a enum of PartyKind might taken in an processor which returns
// all the parties of that kind. All fields returned by `defs` will be prefixed with the description
// returned for the kind.
export const fieldsPerKind = <T extends number, E extends C, C>(
  description: Record<T, string>,
  processor: Processor<Record<T, E>, any, any>,
  context: Context<C> | Context<C>[],
  defs: DataFieldDefinitions
) => {
  return {
    _: async (ectx: ExpandContext) => {
      if (ectx.forSummary) {
        const updatedCtx = withDescription(
          ectx,
          `party of {kind} (options: ${Object.values(description)
            .filter((d) => !!d)
            .join(", ")})`
        );
        await expandFields(updatedCtx, defs, "{kind}");
        return;
      }

      for (const kind of Object.keys(description)) {
        const kindValue = parseInt(kind) as T;
        const kindDescription = description[kindValue];
        if (!kindDescription) {
          continue;
        }

        const processorValue = await getProcessorValue(ectx, processor);
        if (isIssue(processorValue)) {
          ectx.issues.push({
            issue: processorValue,
            source: processor,
          });
          continue;
        }

        let updatedCtx = withDescription(ectx, kindDescription);
        if (processorValue) {
          const contexts = Array.isArray(context) ? context : [context];
          for (const current of contexts) {
            updatedCtx = withContext(
              updatedCtx,
              current,
              processorValue[kindValue]
            );
          }

          await expandFields(updatedCtx, defs, kindDescription);
        }
      }
    },
  };
};

// fieldsPerEntry defines the fields returned for *each* entry found in the entries context or
// processor. All fields returned will be prefixed with the 1-based index of the entry, unless a
// suffix is requested. The `context` specifies the Context slot in which the current entry will
// be placed for all fields to be generated.
export const fieldsPerEntry = <T>(
  entriesContextOrProcessor: Context<T[]> | Processor<T[], any, any>,
  context: Context<T> | Context<T>[],
  fields: DataFieldDefinitions,
  minimum: number = 20,
  applyIndexAsSuffix: boolean = false
) => {
  return {
    _: async (ectx: ExpandContext) => {
      const inject = (value: T | null) => {
        const contexts = Array.isArray(context) ? context : [context];
        let updatedCtx = ectx;
        for (const current of contexts) {
          updatedCtx = withContext(updatedCtx, current, value);
        }
        return updatedCtx;
      };

      if (ectx.forSummary) {
        const nullCtx = inject(null);
        const updatedCtx = withStartDescription(nullCtx, `{index}-th`);
        await expandFields(updatedCtx, fields, "{index}");
        return;
      }

      let entries: T[] | undefined = undefined;
      if ("context_name" in entriesContextOrProcessor) {
        entries = ectx.parameters[entriesContextOrProcessor.context_name];
      } else {
        const result = await getProcessorValue(ectx, entriesContextOrProcessor);
        if (isIssue(result)) {
          ectx.issues.push({
            issue: result,
            source: entriesContextOrProcessor,
          });
          return;
        }

        entries = result;
      }

      if (!entries) {
        return undefined;
      }

      for (let index = 0; index < entries.length; index++) {
        const injectedCtx = inject(entries[index]);
        const updatedCtx = withDescription(injectedCtx, `#${index + 1}`);
        await expandFields(
          updatedCtx,
          fields,
          applyIndexAsSuffix ? undefined : index + 1,
          applyIndexAsSuffix ? index + 1 : undefined
        );
      }

      const nullCtx = inject(null);
      for (let i = entries.length; i < minimum; i++) {
        const updatedCtx = withDescription(nullCtx, `#${i + 1}`);
        await expandFields(
          updatedCtx,
          fields,
          applyIndexAsSuffix ? undefined : i + 1,
          applyIndexAsSuffix ? i + 1 : undefined
        );
      }
    },
  };
};

/**
 * MappingResult is a result produced by a processor which maps from a single record
 * to a list of items to be further expanded.
 */
type MappingResult<T> = Record<number, T[]>;

/**
 * fieldsViaMappingProcessor runs the given processor, extracting the current context
 * value from the map returned, places it into target context, and expands the given fields.
 */
export const fieldsViaMappingProcessor = <T extends IDed, Q>(
  currentContext: Context<T>,
  processor: Processor<MappingResult<Q>, any, any>,
  targetContext: Context<Q>,
  fields: DataFieldDefinitions
) => {
  return async (ectx: ExpandContext) => {
    const processorValue = await getProcessorValue(ectx, processor);
    if (processorValue === undefined) {
      return;
    }
    if (isIssue(processorValue)) {
      ectx.issues.push({
        issue: processorValue,
        source: processor,
      });
      return;
    }

    const currentContextValue = ectx.parameters[
      currentContext.context_name
    ] as T;
    if (!currentContextValue) {
      return;
    }

    const values = processorValue[currentContextValue.id];
    if (values === undefined) {
      return;
    }

    for (let index = 0; index < values.length; index++) {
      const value = values[index];
      await expandFields(
        withStartDescription(
          withContext(ectx, targetContext, value),
          `${processor.itemDescription ?? "item"} #${index + 1} for`
        ),
        fields,
        index + 1
      );
    }
  };
};

/**
 * expandWithInjectedContextValue performs field expansion with the given context set to the
 * value of the given processor (if any).
 */
export const expandWithInjectedContextValue = <T>(
  ctx: Context<T>,
  processor: Processor<T, any, any>,
  fields: DataFieldDefinitions
) => {
  return async (ectx: ExpandContext) => {
    const processorValue = await getProcessorValue(ectx, processor);
    if (processorValue === undefined || processorValue === null) {
      return;
    }
    if (isIssue(processorValue)) {
      ectx.issues.push({
        issue: processorValue,
        source: processor,
      });
      return;
    }

    const dctx = withContext(ectx, ctx, processorValue);
    await expandFields(dctx, fields);
  };
};

// Context defines a slot in the field expansion process for a value injected by fieldsPerEntry.
export type Context<T> = {
  context_name: string;
  __type?: T;
};

// context defines and returns a new slot of the given type, with the given name.
export const context = <T>(name: string): Context<T> => {
  return { context_name: name };
};

/**
 * expand acts as a layer of indirection for definitions to include themselves.
 */
export const expand = (fields: DataFieldDefinitions) => {
  return async (ectx: ExpandContext) => {
    await expandFields(ectx, fields);
  };
};

/**
 * getProcessorValue returns the generated value for the given processor. Can
 * return an Issue, or undefined if one of its dependencies returns undefined.
 * Any returned concrete value will be cached on the expand content.
 */
const getProcessorValue = async <T>(
  ectx: ExpandContext,
  processor: Processor<T, any, any>
): Promise<T | Issue | undefined> => {
  // If the processor is already cached, simply return its value.
  if (processor.id in ectx.parameters) {
    return ectx.parameters[processor.id] as T;
  }

  // Otherwise, build the set of dependencies and, if all valid, run the processor.
  const deps: Record<string, any> = {};
  for (const dependencyName of Object.keys(processor.dependencies ?? {})) {
    const dependency = processor.dependencies[dependencyName];

    // If a dependency is context, retrieve it directly.
    if ("context_name" in dependency) {
      if (!(dependency.context_name in ectx.parameters)) {
        throw new Error(
          `missing context '${dependency.context_name}' for '${processor.id}'`
        );
      }

      const value = ectx.parameters[dependency.context_name];
      if (value === undefined || value === null) {
        return undefined;
      }
      deps[dependencyName] = value;
      continue;
    }

    // Otherwise, run the dependency processor.
    const value = await getProcessorValue<any>(ectx, dependency);
    if (value === undefined || value === null) {
      return undefined;
    }
    if (isIssue(value)) {
      return value;
    }
    deps[dependencyName] = value;
  }

  // Run the processor and cache its result.
  const result = await processor.run({
    ctx: ectx.ctx,
    ...deps,
  });
  ectx.parameters[processor.id] = result;
  if (result === undefined && processor.missingMessage !== undefined) {
    return missingDep(processor, async () => {
      return await processor.run({
        ctx: ectx.ctx,
        ...deps,
      });
    });
  }

  return result;
};

/**
 * ExpansionIssue is an issue raised by an expansion or value call.
 */
export type ExpansionIssue = {
  /** issue is the issue that occurred. */
  issue: Issue;

  /** source is the processor or field that returned the issue. */
  source: Processor<any, any, any> | DataField<any, any>;
};

/**
 * ExpandContext is the context to be used for expansion.
 */
type ExpandContext = {
  ctx: GeneratorContext;
  parameters: Record<string, any>;
  expandedFields: Record<string, ExpandedField>;
  prefix: string[];
  descriptionSuffixes: string[];
  issues: ExpansionIssue[];
  forSummary?: boolean;
};

/**
 * NewExpansionContext creates a new context for expansion of fields.
 */
const NewExpansionContext = (
  db: TrackedDatabase,
  cse: Case,
  memberLookup: FirmMemberLookup
): ExpandContext => {
  return {
    ctx: {
      db: db,
      case: cse,
      memberLookup: memberLookup,
    },
    parameters: {},
    expandedFields: {},
    descriptionSuffixes: [],
    prefix: [],
    issues: [],
  };
};

/**
 * ExpandedField is the result of a field expansion.
 */
type ExpandedField = {
  /** name is the *fully resolved* name for the field. */
  name: string;

  /** field is the underlying defined field which produced this expansion. */
  field: DataField<any, any>;

  /** description is a function that, when invoked, returns the description for the field. */
  description: () => string;

  /** value is a function that, when invoked, returns the value of the field. */
  value: () => Promise<Value>;
};

/**
 * Value is a value that can be returned by the value() function on an expanded field.
 */
export type Value = string | ExpansionIssue | undefined;

/**
 * Expander is a function that when invoked with an ExpandContext, expands the fields under
 * its definition.
 */
type Expander = (ectx: ExpandContext) => Promise<void>;

/**
 * expandFields performs field expansion for the given context, fields and starting prefix.
 */
const expandFields = async (
  ectx: ExpandContext,
  fields: DataFieldDefinitions,
  prefix?: string | number | undefined,
  suffix?: string | number | undefined
) => {
  for (const fieldKey of Object.keys(fields)) {
    const newPrefix = [...ectx.prefix];
    if (prefix) {
      newPrefix.push(prefix.toString());
    }

    let fieldName =
      fieldKey && fieldKey !== "_"
        ? [...newPrefix, fieldKey].join("_")
        : newPrefix.join("_");
    if (suffix) {
      fieldName += suffix.toString();
    }

    const fieldValue = fields[fieldKey];

    // string => alias
    if (typeof fieldValue === "string") {
      const aliasedFieldName = [...newPrefix, fieldValue].join("_");
      if (ectx.forSummary) {
        continue;
      }
      if (!(aliasedFieldName in ectx.expandedFields)) {
        throw new Error(`missing expected field ${aliasedFieldName}`);
      }
      ectx.expandedFields[fieldName] = ectx.expandedFields[aliasedFieldName];
      continue;
    }

    // DataFieldDefinition => process the field
    if ("description" in fieldValue) {
      if (ectx.forSummary && fieldValue.isHelperField) {
        continue;
      }

      const descriptionSuffixes = ectx.descriptionSuffixes;
      ectx.expandedFields[fieldName] = {
        name: fieldName,
        field: fieldValue,
        description: () => {
          if (typeof fieldValue.description === "string") {
            return fieldValue.description;
          }

          return fieldValue.description(descriptionSuffixes.join(" "));
        },
        value: async (): Promise<Value> => {
          if (ectx.forSummary) {
            throw new Error("cannot retrieve value for summarized fields");
          }

          const params: Record<string, any> = {};
          for (const parameterName of Object.keys(
            fieldValue.parameters ?? {}
          )) {
            const parameter: Processor<any, any, any> | Context<any> =
              fieldValue.parameters[parameterName];
            if ("context_name" in parameter) {
              if (!(parameter.context_name in ectx.parameters)) {
                throw new Error(
                  `missing expected context '${parameter.context_name}' for field '${fieldName}'`
                );
              }

              const contextValue = ectx.parameters[parameter.context_name];
              if (contextValue === null || contextValue === undefined) {
                return "";
              }
              params[parameterName] = contextValue;
              continue;
            }

            const value = await getProcessorValue<string | number | null>(
              ectx,
              parameter
            );

            // If an issue or missing was returned, return it directly.
            if (isIssue(value)) {
              return {
                issue: value,
                source: fieldValue,
              };
            }

            if (value === undefined) {
              return undefined;
            }
            params[parameterName] = value;
          }

          const generated = await fieldValue.generator({
            ctx: ectx.ctx,
            ...params,
          });
          if (generated === undefined || generated === null) {
            return "";
          }
          if (isIssue(generated)) {
            return {
              issue: generated,
              source: fieldValue,
            };
          }

          if ("kind" in fieldValue) {
            switch (fieldValue.kind) {
              case FieldKind.Date:
                return date(generated.toString());

              case FieldKind.DocumentDate:
                return documentDate(generated.toString());

              case FieldKind.Currency:
                return currency(generated);

              case FieldKind.Percentage:
                return percentage(generated, 100);

              case FieldKind.NiceNumber:
                return niceNumber(generated);

              default:
                throw new Error("missing handler for field kind");
            }
          }

          return generated.toString();
        },
      };
      continue;
    }

    if (fieldKey && fieldKey !== "_") {
      newPrefix.push(fieldKey);
    }

    // Otherwise, invoke the expansion function.
    await fieldValue({ ...ectx, prefix: newPrefix });
  }
};

/**
 * withContext adds a Context to the parameters within the ExpandContext,
 * returning a new ExpandContext.
 */
export const withContext = <T>(
  ectx: ExpandContext,
  context: Context<T>,
  value: T
): ExpandContext => {
  const parameters = { ...ectx.parameters, [context.context_name]: value };
  return { ...ectx, parameters: parameters };
};

/**
 * withDescription adds a description to the description within the ExpandContext,
 * returning a new ExpandContext.
 */
const withDescription = (
  ectx: ExpandContext,
  description: string
): ExpandContext => {
  const descriptionSuffixes = [...ectx.descriptionSuffixes, description];
  return { ...ectx, descriptionSuffixes: descriptionSuffixes };
};

/**
 * withStartDescription adds a description as a prefix to the description within the ExpandContext,
 * returning a new ExpandContext.
 */
const withStartDescription = (
  ectx: ExpandContext,
  description: string
): ExpandContext => {
  const descriptionSuffixes = [description, ...ectx.descriptionSuffixes];
  return { ...ectx, descriptionSuffixes: descriptionSuffixes };
};

/**
 * ExpansionResult is the result of a full expansion of fields.
 */
export type ExpansionResult = {
  /**
   * expandedFields are the expanded fields, indexed by fully resolved field name.
   */
  expandedFields: Record<string, ExpandedField>;

  /**
   * issues are the issues encountered while expanding the fields, if any.
   */
  issues: ExpansionIssue[];
};

/**
 * FullyExpandedFields expands all document data fields, returning the expanded
 * fields.
 */
export const FullyExpandedFields = async (
  fields: DataFieldDefinitions,
  db: TrackedDatabase,
  cse: Case,
  memberLookup: FirmMemberLookup
): Promise<ExpansionResult> => {
  const ectx = NewExpansionContext(db, cse, memberLookup);
  await expandFields(ectx, fields);
  return {
    expandedFields: ectx.expandedFields,
    issues: ectx.issues,
  };
};

/**
 * SummarizedFields expands document data fields, returning the expanded
 * fields, but with expansion replaced with a synthesized single
 * entry.
 */
export const SummarizedFields = async (
  fields: DataFieldDefinitions,
  db: TrackedDatabase,
  cse: Case
): Promise<ExpansionResult> => {
  const ectx = NewExpansionContext(db, cse, async () => undefined);
  ectx.forSummary = true;
  await expandFields(ectx, fields);
  return {
    expandedFields: ectx.expandedFields,
    issues: ectx.issues,
  };
};
