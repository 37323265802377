import {
  faArrowCircleDown,
  faArrowCircleRight,
  faPlusSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import React, { useContext, useMemo, useState } from "react";
import {
  DatabaseContext,
  ResultContext,
} from "../../database/diffable/context";
import { MutableRow, RowType } from "../../database/diffable/interfaces";
import { useQuery } from "../../database/diffable/query";
import { PreparedQuery, Unparametrized, sql } from "../../database/sql";
import { Expando } from "../Expando";
import { useDocumentTracker } from "../documents/DocumentTracker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
      borderColor: (props: styleProps) =>
        props.missingColor ? props.missingColor : "",
      borderWidth: (props: styleProps) => (props.missingColor ? 2 : 1),
      borderStyle: (props: styleProps) =>
        props.missingColor ? "solid" : "none",
    },
    content: {
      padding: theme.spacing(1),
      display: "grid",
      gridTemplateColumns: "1fr auto",
      columnGap: "10px",
      alignItems: "flex-start",
    },
    header: {
      display: "grid",
      gridTemplateColumns: "auto 1fr auto",
      alignItems: "center",
    },
    empty: {
      color: theme.palette.grey[500],
    },
    toolbar: {
      marginTop: theme.spacing(1),
    },
  })
);

interface styleProps {
  missingColor: string | undefined;
}

type BaseListFieldProps<T extends RowType> = {
  tableName: string;
  rowTitle: string;

  maximumCount?: number | undefined;
  query?: PreparedQuery;
  describeRow?: (
    row: MutableRow<T>,
    index: number
  ) => React.ReactNode | undefined;
  rowDescription?: (row: MutableRow<T>, index: number) => string | undefined;

  itemFilter?: string;
  collapsableItems?: boolean;
  filterFunction?: (
    row: MutableRow<T>,
    index: number,
    filter: string
  ) => boolean;

  empty?: Record<string, any> | ((count: number) => Record<string, any>);

  addDescription?: string;
  removeDescription?: string;

  boundSubTables?: Record<string, string>;

  children: (row: MutableRow<T>, index: number) => React.ReactNode;
};

export type ReadOnlyListFieldProps<T extends RowType> = Omit<
  BaseListFieldProps<T>,
  keyof readWriteProps
>;

type readWriteProps = {
  empty: Record<string, any> | ((count: number) => Record<string, any>);

  addDescription: string;
  removeDescription: string;

  boundSubTables?: Record<string, string>;
};

export type ListFieldProps<T extends RowType> = BaseListFieldProps<T> &
  readWriteProps;

/**
 * ListField defines a field over an entire table's worth of rows.
 */
export function ListField<T extends RowType>(props: ListFieldProps<T>) {
  return <BaseListField {...props} isReadOnly={false} />;
}

/**
 * ReadOnlyListField defines a readonly field over an entire table's worth of rows.
 */
export function ReadOnlyListField<T extends RowType>(
  props: ReadOnlyListFieldProps<T>
) {
  return <BaseListField {...props} isReadOnly={true} />;
}

export interface ParentListField {
  getParentRowTitle: () => string | undefined;
}

/**
 * ParentListFieldContext defines context which holds a reference to the current list field's props.
 */
export const ParentListFieldContext = React.createContext<
  ParentListField | undefined
>(undefined);
ParentListFieldContext.displayName = "ParentListFieldContext";

function BaseListField<T extends RowType>(
  props: BaseListFieldProps<T> & { isReadOnly: boolean }
) {
  const database = useContext(DatabaseContext)!;
  const { tracker } = useDocumentTracker();

  const [localIndex, setLocalIndex] = useState(0);
  const { results } = useQuery<T>(
    props.query ?? sql`select * from ${props.tableName}`
  );

  const handleAddRow = () => {
    const index = results?.length ?? 0;

    let empty = props.empty!;
    if (typeof empty === "function") {
      empty = empty(index);
    }

    const addedId = database.transaction.insertRow(
      props.tableName,
      empty,
      props.addDescription!
    );

    if (props.collapsableItems) {
      if (results?.length) {
        setClosedItems({
          ...closedItems,
          [results[index - 1].id()]: true,
          [addedId]: false,
        });
      } else {
        setClosedItems({
          ...closedItems,
          [addedId]: false,
        });
      }
    }

    setLocalIndex(localIndex + 1);
  };

  const handleRemoveRow = (row: MutableRow<T>) => {
    if (props.boundSubTables) {
      for (const tableName of Object.keys(props.boundSubTables)) {
        const results = database.selectAllResults(
          sql`select * from ${tableName} where ${new Unparametrized(
            props.boundSubTables[tableName]
          )}=${row.id()}`
        );
        for (const subRow of results) {
          database.transaction.deleteRow(
            tableName,
            subRow.id(),
            props.removeDescription!
          );
        }
      }
    }

    database.transaction.deleteRow(
      props.tableName,
      row.id(),
      props.removeDescription!
    );
    setLocalIndex(localIndex + 1);
  };

  const describeRow = (row: MutableRow<T>, index: number) => {
    const described =
      props.describeRow !== undefined
        ? props.describeRow(row, index)
        : undefined;
    return (
      described ?? (
        <span key={index}>
          {props.rowTitle} #{index + 1}
        </span>
      )
    );
  };

  const [closedItems, setClosedItems] = useState<Record<number, boolean>>({});

  const isResultClosed = (resultId: number) => {
    if (!props.collapsableItems) {
      return false;
    }

    if (closedItems[resultId] === false) {
      return false;
    }

    return !tracker.rowHasMissingField(props.tableName, resultId);
  };

  const toggleOpen = (resultId: number) => {
    const updated = {
      ...closedItems,
      [resultId]: !isResultClosed(resultId),
    };

    setClosedItems(updated);
  };

  const filteredResults = useMemo(() => {
    return (
      results
        ?.map((result: MutableRow<T>, index: number) => {
          return {
            result: result,
            index: index,
          };
        })
        ?.filter((r) => {
          if (!props.filterFunction || !props.itemFilter) {
            return true;
          }
          return props.filterFunction(r.result, r.index, props.itemFilter);
        }) ?? []
    );
  }, [results, props]);

  // Check for highlighting based on a missing child.
  const missingColors = filteredResults
    .map((r) => {
      if (tracker.rowHasMissingField(props.tableName, r.result.id())) {
        const found = tracker.documentForRow(props.tableName, r.result.id());
        if (found) {
          return found.color;
        }
      }

      return undefined;
    })
    .filter((c) => !!c);

  const missingColor = missingColors.length ? missingColors[0] : undefined;
  const classes = useStyles({ missingColor: missingColor });

  return (
    <div className={classes.root}>
      {results?.length === 0 && (
        <div className={classes.empty}>No {props.rowTitle} found</div>
      )}
      {results !== undefined &&
        results?.length > 0 &&
        filteredResults.length === 0 && (
          <div className={classes.empty}>
            No {props.rowTitle} matches the entered search
          </div>
        )}
      {filteredResults.map((r: { result: MutableRow<T>; index: number }) => {
        const result = r.result;
        const index = r.index;
        const isClosed = isResultClosed(result.id());
        return (
          <div key={result.id()}>
            <Paper className={classes.paper}>
              <Typography className={classes.header} variant="subtitle2">
                {props.collapsableItems ? (
                  <IconButton onClick={() => toggleOpen(result.id())}>
                    <FontAwesomeIcon
                      icon={!isClosed ? faArrowCircleDown : faArrowCircleRight}
                      size="sm"
                    />
                  </IconButton>
                ) : (
                  <span />
                )}
                <span>{describeRow(result, index)}</span>
                {!props.isReadOnly && (
                  <IconButton onClick={() => handleRemoveRow(result)}>
                    <FontAwesomeIcon icon={faTrash} size="sm" />
                  </IconButton>
                )}
              </Typography>
              <ParentListFieldContext.Provider
                value={{
                  getParentRowTitle: () => {
                    return props.rowDescription
                      ? props.rowDescription(result, index)
                      : undefined;
                  },
                }}
              >
                <ResultContext.Provider value={result}>
                  {props.collapsableItems ? (
                    <Expando open={!isClosed}>
                      <div className={classes.content}>
                        {props.children(result, index)}
                      </div>
                    </Expando>
                  ) : (
                    <div className={classes.content}>
                      {props.children(result, index)}
                    </div>
                  )}
                </ResultContext.Provider>
              </ParentListFieldContext.Provider>
            </Paper>
          </div>
        );
      })}
      {!props.isReadOnly &&
        (results === undefined ||
          results.length < (props.maximumCount ?? 1000)) && (
          <div className={classes.toolbar}>
            <Button
              startIcon={<FontAwesomeIcon icon={faPlusSquare} />}
              onClick={handleAddRow}
            >
              Add {props.rowTitle}
            </Button>
          </div>
        )}
    </div>
  );
}
