import { ApolloClient } from "@apollo/client/core";
import { ApolloConsumer } from "@apollo/client/react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useMutationWatcher } from "../../database/diffable/hooks";
import { DiffableDatabase } from "../../database/diffable/interfaces";
import { WithApplicationConfig } from "../../services/confighook";
import { ApplicationConfig } from "../../services/configservice";
import { useDocumentCreator } from "../../services/documentservice";
import { Case, CaseDatabaseRevision } from "../../types/case";
import { DocumentToGenerate, useDocumentTracker } from "./DocumentTracker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tracker: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(13),
      left: "200px",
      textAlign: "right",
    },
    documentChip: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    avatar: {
      marginLeft: "2px !important",
    },
  })
);

/**
 * TrackedDocumentView displays the tracked documents and the number of missing fields
 * for them, on the bottom of the screen.
 */
export function TrackedDocumentView(props: {
  database: DiffableDatabase;
  case: Case;
  currentRevision: CaseDatabaseRevision;
}) {
  return (
    <ApolloConsumer>
      {(client) => (
        <WithApplicationConfig>
          {(appConfig: ApplicationConfig) => (
            <TrackedDocumentViewInternal
              {...props}
              appConfig={appConfig}
              client={client}
            />
          )}
        </WithApplicationConfig>
      )}
    </ApolloConsumer>
  );
}

type TDVProps = {
  database: DiffableDatabase;
  case: Case;
  currentRevision: CaseDatabaseRevision;
  client: ApolloClient<any>;
  appConfig: ApplicationConfig;
};

function TrackedDocumentViewInternal(props: TDVProps) {
  const classes = useStyles();
  const { documents } = useDocumentTracker();

  return (
    <div className={classes.tracker}>
      {documents.length !== 0 &&
        documents.map((togen: DocumentToGenerate) => {
          return (
            <DocumentChip key={togen.docTemplate.id} toGen={togen} {...props} />
          );
        })}
    </div>
  );
}

function DocumentChip(props: { toGen: DocumentToGenerate } & TDVProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { tracker, documents } = useDocumentTracker();

  // NOTE: The hook ensures we get re-rendered on changes.
  const mutationState = useMutationWatcher(props.database, 100);

  const { createDocument } = useDocumentCreator(
    props.case,
    props.currentRevision,
    props.database,
    props.appConfig,
    props.client,
    false
  );

  const handleDelete = () => {
    tracker.removeDocument(props.toGen.docTemplate);
  };

  const handleGenerate = async () => {
    await createDocument(props.toGen.docTemplate);
  };

  const StyleChip = withStyles({
    root: {
      backgroundColor: props.toGen.color,
      color: theme.palette.getContrastText(props.toGen.color),
    },
  })(Chip);

  const [missingFieldCount, setMissingFieldCount] = useState(0);

  const toGen = props.toGen;
  useEffect(() => {
    const recalculate = async () => {
      setMissingFieldCount(await tracker.missingFieldsAndValuesCount(toGen));
    };
    recalculate();
  }, [tracker, toGen, mutationState.currentTransaction]);

  const avatar =
    missingFieldCount > 0 ? (
      <Avatar
        className={classes.avatar}
        style={{
          fontWeight: "bold",
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.getContrastText(theme.palette.warning.main),
        }}
      >
        {missingFieldCount}
      </Avatar>
    ) : (
      <Avatar
        className={classes.avatar}
        style={{ backgroundColor: theme.palette.success.main }}
      >
        <FontAwesomeIcon icon={faCheck} />
      </Avatar>
    );

  return (
    <span key={props.toGen.docTemplate.id}>
      <StyleChip
        className={classes.documentChip}
        style={{
          border: "1px solid transparent",
          borderColor:
            missingFieldCount === 0
              ? theme.palette.success.main
              : theme.palette.warning.main,
        }}
        color="default"
        size="small"
        avatar={avatar}
        label={
          documents.length > 5
            ? props.toGen.docTemplate.title.split(" ").slice(0, 2).join(" ")
            : props.toGen.docTemplate.title
        }
        onClick={() => handleGenerate()}
        onDelete={() => handleDelete()}
      />
    </span>
  );
}
