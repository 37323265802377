import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import _uniqueId from "lodash/uniqueId";
import React, { useContext, useRef, useState } from "react";
import {
  DatabaseContext,
  ResultContext,
} from "../../database/diffable/context";
import { DiffableDatabase } from "../../database/diffable/interfaces";
import { useDocumentTracker } from "../documents/DocumentTracker";
import { CurrentAutoFocusManager, useScrollIntoView } from "./autofocus";
import { ParentListField, ParentListFieldContext } from "./ListField";

export type ValueFieldProps = WithTitleOrPlaceholder<{
  column: string;
  description?: string;
  fullWidth?: boolean;
  multiline?: boolean;
  fallbackColumn?: string;
  isValidValue?: (value: string) => boolean;
  startAdornment?: React.ReactNode;
  labelWidth?: number;
  inputComponent?: any;
  maxLength?: number;
  buildErrorMessage?: (value: string) => string;
  clickToFill?: ClickToFill;
}> &
  CopyableFieldProps;

export type WithTitleOrPlaceholder<T> = T &
  (
    | {
        title: string;
        placeholder?: string;
      }
    | {
        title?: string;
        placeholder: string;
      }
  );

export type CopyableFieldProps = {
  copyFrom?: (database: DiffableDatabase) => string;
  copyFromTitle?: string;
};

export interface ClickToFill {
  title: string;
  value: string;
}

interface styleProps {
  missingColor: string | undefined;
}

export const useFieldStyles = makeStyles((theme: Theme) =>
  createStyles({
    edited: {
      "& > div > fieldset": {
        borderColor: theme.palette.warning.main,
      },
    },
    normal: {
      "& > div > fieldset": {
        borderColor: (props: styleProps) =>
          props.missingColor ? props.missingColor : "",
        borderWidth: (props: styleProps) =>
          props.missingColor ? 2 : undefined,
      },
    },
    fallback: {
      color: theme.palette.grey[500],
    },
    invalidValue: {
      display: "flex",
      whiteSpace: "nowrap",
      padding: theme.spacing(1),
      borderRadius: "4px",
      alignItems: "center",
      backgroundColor: theme.palette.error.dark,
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
    adornmentButton: {
      height: "2em",
      marginRight: theme.spacing(2),
      whiteSpace: "nowrap",
      minWidth: "250px",
    },
  })
);

export const useSharedStyles = makeStyles((theme: Theme) =>
  createStyles({
    adornmentButton: {
      height: "2em",
      marginRight: theme.spacing(2),
      whiteSpace: "nowrap",
      minWidth: "250px",
    },
  })
);

export function fieldTitle<T>(
  props: WithTitleOrPlaceholder<T>,
  parentListField: ParentListField | undefined
) {
  const parentTitle = parentListField?.getParentRowTitle();
  const title = props.title || props.placeholder || "";
  if (!parentTitle) {
    return title;
  }

  return `${parentTitle}: ${title}`;
}

export function ValueField(props: ValueFieldProps) {
  const database = useContext(DatabaseContext)!;
  const row = useContext(ResultContext)!;
  const parentListField = useContext(ParentListFieldContext);

  const { tracker } = useDocumentTracker();
  const [uniqueId] = useState(_uniqueId("field-"));

  const handleChanged = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(event.target.value);
  };

  const setValue = (value: string) => {
    if (props.fallbackColumn && value === fallbackValue) {
      value = "";
    }

    if (props.maxLength !== undefined && value.length > props.maxLength) {
      return;
    }

    const isValidValue = (props.isValidValue || (() => true))(value);
    row.setField(
      props.column,
      { value: value, isValid: isValidValue },
      {
        title: fieldTitle(props, parentListField),
      }
    );

    setLocalIndex(localIndex + 1);
  };

  const isSaving = database.applying;

  const fallbackValue = props.fallbackColumn
    ? row.getField(props.fallbackColumn)
    : "";
  const value = row.getField(props.column) || fallbackValue;

  const [localIndex, setLocalIndex] = useState(0);

  const { missingColor, missingForDocument, hasMissingValue } =
    tracker.checkValueIsMissingForField(value, row, props.column);
  const classes = useFieldStyles({
    missingColor: missingColor,
  });

  const handleButtonClicked = () => {
    setValue(props.clickToFill?.value!);
  };

  const InputProps = {
    readOnly: isSaving,
    endAdornment: (
      <React.Fragment>
        {props.clickToFill !== undefined && (
          <Button
            className={classes.adornmentButton}
            variant="contained"
            color="primary"
            onClick={handleButtonClicked}
          >
            {props.clickToFill.title}
          </Button>
        )}
        {isSaving ? <CircularProgress color="inherit" size={20} /> : null}
        {row.hasInvalidValue(props.column) ? (
          <div className={classes.invalidValue}>
            <FontAwesomeIcon icon={faExclamationTriangle} size="xs" />
            Invalid Value
          </div>
        ) : null}
        <CopyButtonForProps {...props} setValue={setValue} />
      </React.Fragment>
    ),
    startAdornment: props.startAdornment,
    labelWidth: props.labelWidth,
    classes: {
      input: fallbackValue === value ? classes.fallback : "",
    },
    inputComponent: props.inputComponent,
  };

  const hasInvalidValue = row.hasInvalidValue(props.column);
  const autoFocusManager = useContext(CurrentAutoFocusManager);
  const shouldAutoFocus = autoFocusManager.shouldAutoFocus(
    uniqueId,
    hasInvalidValue,
    hasMissingValue
  );

  const inputRef = useRef<HTMLDivElement>(null);
  useScrollIntoView(inputRef, shouldAutoFocus);

  return (
    <TextField
      InputProps={InputProps}
      ref={inputRef}
      inputProps={{
        maxLength: props.maxLength,
        "data-missing": hasMissingValue
          ? missingForDocument?.docTemplate?.id
          : undefined,
      }}
      helperText={
        hasInvalidValue && props.buildErrorMessage !== undefined
          ? props.buildErrorMessage(value)
          : undefined
      }
      multiline={props.multiline}
      error={hasInvalidValue}
      autoFocus={shouldAutoFocus}
      variant="outlined"
      fullWidth={props.fullWidth}
      id={uniqueId}
      classes={{
        root: row.hasMutatedValue(props.column)
          ? classes.edited
          : classes.normal,
      }}
      label={props.placeholder || props.title}
      value={value}
      onChange={handleChanged}
    />
  );
}

export const CopyButtonForProps = (
  props: CopyableFieldProps & {
    setValue: (value: string) => void;
  }
) => {
  const database = useContext(DatabaseContext)!;
  const classes = useSharedStyles();

  if (!props.copyFrom || !props.copyFromTitle) {
    return null;
  }

  const handleCopy = () => {
    if (props.copyFrom !== undefined) {
      const value = props.copyFrom(database);
      if (value) {
        props.setValue(value.toString());
      }
    }
  };

  return (
    <>
      {props.copyFromTitle !== undefined && props.copyFrom !== undefined && (
        <Button
          className={classes.adornmentButton}
          variant="contained"
          color="primary"
          onClick={handleCopy}
        >
          {props.copyFromTitle}
        </Button>
      )}
    </>
  );
};
