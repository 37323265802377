import { rowsForBindings } from "../components/CaseNavLink";
import { DocumentTracker } from "../components/documents/DocumentTracker";
import { DiffableDatabase } from "../database/diffable/interfaces";
import { TEMPLATES } from "../templates";
import { DefinedPanel } from "../templates/uibuilder";
import { Case } from "../types/case";
import { DocumentTemplate } from "../types/documentemplate";
import { MissingValueKey } from "./documentservice";

/**
 * firstPanelForMissing returns the first panel found for the given case
 * that has a field or value currently marked as missing in the tracker.
 * @returns The panel found or undefined if none.
 */
export function firstPanelForMissing(
  docTemplate: DocumentTemplate,
  cse: Case,
  tracker: DocumentTracker,
  database: DiffableDatabase
): { path: string } | undefined {
  if (!tracker.hasDocuments()) {
    return undefined;
  }

  const template = TEMPLATES[cse.templateId!];
  const panels: DefinedPanel[] = template.panels.filter(
    (p) => "path" in p
  ) as DefinedPanel[];
  return panels.find((panel) => {
    const found = panel.definedValueKeys?.find((key: MissingValueKey) => {
      return tracker.getDocument(docTemplate)?.missing.hasMissingValue(key);
    });
    if (found !== undefined) {
      return true;
    }

    const daiTables = template.dataAccess[panel.path];
    if (daiTables === undefined) {
      return false;
    }

    for (const dai of Object.values(daiTables)) {
      if (dai.isFullTableAccess) {
        // Check general access.
        for (const fieldName of dai.fieldsAccessed) {
          const dtg = tracker.documentForColumn(dai.tableName, fieldName);
          if (dtg !== undefined) {
            return true;
          }
        }
      } else {
        // Check specific access.
        const rows = rowsForBindings(panel.tableBindings, database)[
          dai.tableName
        ];
        if (rows?.length > 0) {
          for (const fieldName of dai.fieldsAccessed) {
            for (const row of rows) {
              const dtg = tracker.documentForField(
                dai.tableName,
                row.id(),
                fieldName
              );
              if (dtg !== undefined) {
                return true;
              }
            }
          }
        }
      }
    }

    return false;
  });
}
