/*
 * To regenerate the schema:
 * typescript-json-schema model.ts "*" -o schema.json --required --strictNullChecks
 *
 * https://github.com/YousefED/typescript-json-schema
 */

import { Address as AddressDef } from "../../sharedschema/address";
import { TermOrExtension } from "./shared";

export enum AddressKind {
  TENANT_NOTICE = 1,
  LANDLORD_NOTICE = 2,
  GUARANTOR_NOTICE = 3,
  PREMISES = 4,
  BUILDING = 5,
}

export interface Address extends AddressDef {
  kind: AddressKind;
}

export enum EntityType {
  UNKNOWN = 0,
  INDIVIDUAL = 1,
  GENERAL_PARTNERSHIP = 2,
  LIMITED_PARTNERSHIP = 3,
  LLC = 4,
  CORP = 5,
  TRUST = 6,
}

export const EntityTypeDescription = {
  [EntityType.UNKNOWN]: "",
  [EntityType.INDIVIDUAL]: "Individual",
  [EntityType.GENERAL_PARTNERSHIP]: "General Partnership",
  [EntityType.LIMITED_PARTNERSHIP]: "Limited Partnership",
  [EntityType.LLC]: "Limited Liability Company",
  [EntityType.CORP]: "Corporation",
  [EntityType.TRUST]: "Trust",
};

export enum EntityKind {
  UNKNOWN = -1,
  TENANT = 0,
  LANDLORD = 1,
  GUARANTOR = 2,
}

export const EntityKindDescription = {
  [EntityKind.UNKNOWN]: "",
  [EntityKind.TENANT]: "Tenant",
  [EntityKind.LANDLORD]: "Landlord",
  [EntityKind.GUARANTOR]: "Guarantor",
};

export interface Entity {
  id: number;
  type: EntityType;
  kind: EntityKind;

  formationState: string;
  name: string;

  addressId: number;
  signer: string;
  signerTitle: string;
  attorneyForNotice: string;
}

export interface CommercialLease {
  id: number;
  broker: string;

  documentTitle: string;
  documentDate: string;
  documentShortName: string;
  initialShortName: string;

  premisesAddressId: Address;
  premisesSquareFeet: number | null;
  premisesUnitNumber: string;

  buildingAddressId: Address;
  buildingSquareFeet: number | null;

  permittedUse: string;
  commencementDate: string;
  rentCommencementDate: string;
  possessionDate: string;

  tripleNet: number;
  leaseTerm: string;
  leaseEndDate: string;
  leaseTerminationDate: string;

  initialRentPSF: number | null;
  securityDeposit: number | null;
  firstMonthsRent: number | null;

  hasAddendum: number;
  addendumText: string;

  amendmentsText: string;

  guarantyTitle: string;
  guarantyDate: string;

  baseRentTerm: string;

  leaseExtensionStartDate: string;
  leaseExtensionEndTermDate: string;
}

export interface Modification {
  id: number;
  title: string;
  shortTitle: string;
  date: string;
  isActive: number;
}

export interface LeaseTerm extends TermOrExtension {}
export interface LeaseExtension extends TermOrExtension {}
