import moment from "moment";
import ordinal from "ordinal";
import { deserializeFromString } from "../../components/fields/DocumentDateField";
import {
  Processor,
  context,
  expandWithInjectedContextValue,
  field,
} from "../../services/datafielddefinitions";

export type SerializedDocumentDateData = string;

const SerializedDocumentDate = context<SerializedDocumentDateData>(
  "serializedDocumentDate"
);
const emptyValue = "_______";

const DocumentDateMonth = field(
  {
    description: (sd) =>
      `The month of the date of the document, or ${emptyValue} if not specified`,
    example: "January",
    parameters: {
      serializedDocumentDate: SerializedDocumentDate,
    },
  },
  async ({ serializedDocumentDate }) => {
    const deserialized = deserializeFromString(serializedDocumentDate);
    return deserialized.m ? moment.months()[deserialized.m - 1] : emptyValue;
  }
);

const DocumentDateDay = field(
  {
    description: (sd) =>
      `The day of the date of the document, or ${emptyValue} if not specified`,
    example: "22nd",
    parameters: {
      serializedDocumentDate: SerializedDocumentDate,
    },
  },
  async ({ serializedDocumentDate }) => {
    const deserialized = deserializeFromString(serializedDocumentDate);
    return deserialized.d ? ordinal(deserialized.d) : emptyValue;
  }
);

const DocumentDateYear = field(
  {
    description: (sd) =>
      `The year of the date of the document, or ${emptyValue} if not specified`,
    example: "1970",
    parameters: {
      serializedDocumentDate: SerializedDocumentDate,
    },
  },
  async ({ serializedDocumentDate }) => {
    const deserialized = deserializeFromString(serializedDocumentDate);
    return deserialized.y ? deserialized.y : emptyValue;
  }
);

export const documentDateFields = (
  processor: Processor<SerializedDocumentDateData, any, any>
) => {
  return expandWithInjectedContextValue(SerializedDocumentDate, processor, {
    Month: DocumentDateMonth,
    Day: DocumentDateDay,
    Year: DocumentDateYear,
  });
};
