import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import _uniqueId from "lodash/uniqueId";
import React, { useContext, useRef, useState } from "react";
import { useDeepCompareMemo } from "use-deep-compare";
import {
  DatabaseContext,
  ResultContext,
} from "../../database/diffable/context";
import { BitwiseSet } from "../../util/bitwiseset";
import { useDocumentTracker } from "../documents/DocumentTracker";
import { ParentListFieldContext } from "./ListField";
import { CurrentAutoFocusManager, useScrollIntoView } from "./autofocus";
import {
  WithTitleOrPlaceholder,
  fieldTitle,
  useFieldStyles,
} from "./valuefield";

export type MultiEnumFieldProps = WithTitleOrPlaceholder<{
  column: string;
  values: Record<string, string>;
  description?: string;
  fullWidth?: boolean;
  sorted?: boolean;
}>;

// From: https://github.com/microsoft/TypeScript/issues/30611#issuecomment-565384924
export function MultiEnumField<E extends number>(props: MultiEnumFieldProps) {
  const database = useContext(DatabaseContext)!;
  const row = useContext(ResultContext)!;
  const parentListField = useContext(ParentListFieldContext);

  const { tracker } = useDocumentTracker();

  const [uniqueId] = useState(_uniqueId("field-"));
  const [localIndex, setLocalIndex] = useState(0);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let encoded = 0;
    const selected = event.target.value as string[];
    selected.forEach((selectedValueStr: string) => {
      encoded = encoded | parseInt(selectedValueStr);
    });

    const niceValue =
      encoded === 0
        ? "(None Selected)"
        : Object.keys(props.values)
            .filter((valueStr: string) => {
              return selected.indexOf(valueStr) >= 0;
            })
            .map((valueStr: string) => {
              return props.values[valueStr];
            })
            .join(", ");

    row.setField(
      props.column,
      { value: encoded.toString(), isValid: true, niceValue: niceValue },
      {
        title: fieldTitle(props, parentListField),
      }
    );
    setLocalIndex(localIndex + 1);
  };

  const rowValue = row.getField(props.column);
  const bitwiseSet = BitwiseSet.fromString(rowValue);
  const selected = Object.keys(props.values).filter((valueStr: string) => {
    return bitwiseSet.has(parseInt(valueStr));
  });

  const { missingColor, missingForDocument, hasMissingValue } =
    tracker.checkValueIsMissingForField(
      rowValue,
      row,
      props.column,
      (v) => bitwiseSet.encoded === 0
    );
  const classes = useFieldStyles({
    missingColor: missingColor,
  });

  const autoFocusManager = useContext(CurrentAutoFocusManager);
  const shouldAutoFocus = autoFocusManager.shouldAutoFocus(
    uniqueId,
    hasMissingValue
  );

  const inputRef = useRef<HTMLDivElement>(null);
  useScrollIntoView(inputRef, shouldAutoFocus);

  const valueStrings = useDeepCompareMemo(() => {
    if (props.sorted) {
      return Object.keys(props.values).sort((a, b) => {
        const av = props.values[a];
        const bv = props.values[b];

        if (av < bv) {
          return -1;
        } else if (av > bv) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return Object.keys(props.values);
  }, [props.sorted, props.values]);

  // Based on multi-select from https://material-ui.com/components/selects/
  return (
    <FormControl
      variant="outlined"
      fullWidth={props.fullWidth}
      classes={{
        root: row.hasMutatedValue(props.column)
          ? classes.edited
          : classes.normal,
      }}
    >
      <InputLabel id={`${uniqueId}-label`}>
        {props.placeholder || props.title}
      </InputLabel>
      <Select
        autoFocus={shouldAutoFocus}
        labelId={`${uniqueId}-label`}
        label={props.placeholder || props.title}
        variant="outlined"
        id={uniqueId}
        value={selected}
        onChange={handleChange}
        renderValue={(selected) => {
          return (
            <span>
              {(selected as string[])
                .map((valueStr: string) => {
                  return props.values[valueStr];
                })
                .join(", ")}
            </span>
          );
        }}
        multiple
        inputRef={inputRef}
        inputProps={{
          readOnly: database.applying,
          "data-missing": hasMissingValue
            ? missingForDocument?.docTemplate.id
            : undefined,
        }}
      >
        {valueStrings.map((valueStr: string) => {
          const numericValue = parseInt(valueStr);
          if (numericValue === 0) {
            return <span key="empty"></span>;
          }

          return (
            <MenuItem key={valueStr} value={valueStr}>
              <Checkbox checked={bitwiseSet.has(numericValue)} />
              <ListItemText primary={props.values[valueStr]} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
