import { ApolloClient } from "@apollo/client";
import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import MaterialTable from "material-table";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import replaceAll from "string.prototype.replaceall";
import { DatabaseContext } from "../../database/diffable/context";
import { useMutationWatcher } from "../../database/diffable/hooks";
import { ApplicationConfig } from "../../services/configservice";
import {
  GeneratedDocumentData,
  generateDocumentParameters,
} from "../../services/documentservice";
import { Case } from "../../types/case";
import { DocumentTemplate } from "../../types/documentemplate";
import EmptyView from "../EmptyView";
import { materialTableIcons } from "./CreateDocumentPane";

interface Field {
  field: string;
  value: any;
}

interface LocationState {
  forTemplate?: DocumentTemplate | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.text.primary,
    },
    forTemplate: {
      marginBottom: "10px",
      alignItems: "center",
    },
  })
);

export function DocumentFieldsViewer(props: {
  appConfig: ApplicationConfig;
  client: ApolloClient<object>;
  case: Case;
}) {
  const database = useContext(DatabaseContext)!;
  const location = useLocation();
  const state = location.state as LocationState;
  const classes = useStyles();
  const history = useHistory();

  const forTemplate = state?.forTemplate;

  // Watch for changes.
  useMutationWatcher(database, 250);

  const [documentDebugParameters, setDocumentDebugParameters] = useState<
    GeneratedDocumentData | undefined
  >(undefined);
  const hasMutations = database.transaction.currentMutationCount() > 0;

  useEffect(() => {
    if (hasMutations) {
      return;
    }

    (async () => {
      // Generate the parameters.
      const generated = await generateDocumentParameters(
        props.appConfig,
        props.client,
        props.case,
        forTemplate,
        database!
      );
      setDocumentDebugParameters(generated);
    })();
  }, [
    database.transaction,
    hasMutations,
    forTemplate,
    database,
    props.appConfig,
    props.case,
    props.client,
  ]);

  const handleRemoveFilter = () => {
    history.push(`/c/${props.case.id}/documents?tab=fields`);
  };

  return (
    <div>
      {hasMutations && (
        <EmptyView message="Please save changes to view mail merge fields" />
      )}
      {!hasMutations &&
        documentDebugParameters !== undefined &&
        documentDebugParameters.error !== undefined && (
          <Alert severity="error">{documentDebugParameters.error}</Alert>
        )}
      {!hasMutations &&
        documentDebugParameters !== undefined &&
        documentDebugParameters.error === undefined && (
          <div>
            {forTemplate !== undefined && (
              <Alert
                className={classes.forTemplate}
                severity="info"
                variant="outlined"
              >
                You are currently viewing the mail merge fields needed for{" "}
                {forTemplate.title}. To see all the fields available:{" "}
                <Button className={classes.link} onClick={handleRemoveFilter}>
                  remove the filter
                </Button>
              </Alert>
            )}
            <MaterialTable
              icons={materialTableIcons()}
              columns={[
                {
                  title: "Field",
                  field: "field",
                  render: (data: Field) => {
                    return <code>{data.field}</code>;
                  },
                },
                {
                  title: "Current Value",
                  field: "value",
                  render: (data: Field) => {
                    return (
                      <div style={{ width: 300 }}>
                        {replaceAll(
                          replaceAll(data.value, "\t", "↹"),
                          "\n",
                          "¶"
                        )}
                      </div>
                    );
                  },
                },
                {
                  title: "",
                  field: "searchField",
                  width: "0%",
                  render: (data: Field) => {
                    return <></>;
                  },
                },
              ]}
              data={Object.entries(documentDebugParameters.data!).map(
                (data: [string, any]) => {
                  return {
                    field: data[0],
                    value: data[1],
                    searchField: data[0].replaceAll("_", ""),
                  };
                }
              )}
              options={{ pageSize: 10 }}
              title={`Mail Merge Fields${
                forTemplate !== undefined ? ` for ${forTemplate.title}` : ""
              }`}
            />
          </div>
        )}
    </div>
  );
}
