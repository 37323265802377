import { MutableRow } from "../../database/diffable/interfaces";
import { sql } from "../../database/sql";
import {
  DataFieldDefinitions,
  FieldKind,
  context,
  error,
  expand,
  expandWithInjectedContextValue,
  field,
  fieldsPerEntry,
  fieldsPerKind,
  processor,
} from "../../services/datafielddefinitions";
import { IsKnownState, StateTitle } from "../../services/states";
import {
  CurrentAddress,
  OptionalAddressFields,
} from "../../shareddata/fields/address";
import {
  SerializedDocumentDateData,
  documentDateFields,
} from "../../shareddata/fields/documentdate";
import { FirmFields } from "../../shareddata/fields/firm";
import {
  compareDocumentDates,
  documentDate,
  joinMultiple,
  percentage,
} from "../../shareddata/shareddata";
import {
  Address,
  CommercialLease,
  Entity,
  EntityKind,
  EntityKindDescription,
  EntityTypeDescription,
  LeaseExtension,
  LeaseTerm,
  Modification,
} from "./model";
import { TermOrExtension } from "./shared";

const LeaseInformation = processor(
  {
    id: "lease_information",
    description: "Information about the commercial lease",
  },
  async ({ ctx }) => {
    return ctx.db.getOrUndefined<CommercialLease>(
      sql`select * from ${"commerciallease"}`
    );
  }
);

const LeaseDocumentDate = processor(
  {
    id: "lease_document_date",
    description: "The document date for the lease",
    dependencies: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.documentDate as SerializedDocumentDateData;
  }
);

const Premises_Address = processor(
  {
    id: "premises_address",
    description: "Address of the premises",
    dependencies: {
      lease: LeaseInformation,
    },
  },
  async ({ ctx, lease }) => {
    return ctx.db.get<Address>(
      sql`select * from ${"address"} where id=${lease.premisesAddressId}`
    );
  }
);

const Building_Address = processor(
  {
    id: "building_address",
    description: "Address of the building",
    dependencies: {
      lease: LeaseInformation,
    },
  },
  async ({ ctx, lease }) => {
    return ctx.db.get<Address>(
      sql`select * from ${"address"} where id=${lease.buildingAddressId}`
    );
  }
);

const Document_Title = field(
  {
    description: "The title of the commercial lease document",
    example: "A lease",
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.documentTitle;
  }
);

const Document_ShortName = field(
  {
    description:
      "The short version of the title of the commercial lease document",
    example: "Lease",
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.documentShortName;
  }
);

const CurrentEntityAndAddress = context<EntityAndAddress>("entity_and_address");

type EntityAndAddress = Address & { entity: Entity };

const EntityByKind = processor(
  {
    id: "entity_by_kind",
    description: "Map from each entity type to the entity",
  },
  async ({ ctx }) => {
    const entities = ctx.db.list<Entity>(sql`select * from ${"entity"}`);
    return Object.fromEntries(
      entities.map((entity) => {
        const address = ctx.db.get<Address>(
          sql`select * from ${"address"} where id=${entity.addressId}`
        );
        const value: EntityAndAddress = { entity: entity, ...address };
        return [entity.kind, value];
      })
    ) as Record<EntityKind, EntityAndAddress>;
  }
);

const EntityName = field(
  {
    description: (sd) => `The name of the ${sd}`,
    example: "Tom Jones",
    parameters: {
      eaa: CurrentEntityAndAddress,
    },
  },
  async ({ eaa }) => {
    return eaa.entity.name;
  }
);

const EntitySigner = field(
  {
    description: (sd) => `The signer for ${sd}`,
    example: "Tom Jones",
    parameters: {
      eaa: CurrentEntityAndAddress,
    },
  },
  async ({ eaa }) => {
    return eaa.entity.signer;
  }
);

const EntitySignerTitle = field(
  {
    description: (sd) => `The title of the signer for ${sd}`,
    example: "The important signer",
    parameters: {
      eaa: CurrentEntityAndAddress,
    },
  },
  async ({ eaa }) => {
    return eaa.entity.signerTitle;
  }
);

const EntityType = field(
  {
    description: (sd) => `The corporation kind of the ${sd}`,
    example: "Individual",
    parameters: {
      eaa: CurrentEntityAndAddress,
    },
  },
  async ({ eaa }) => {
    return EntityTypeDescription[eaa.entity.type];
  }
);

const EntityState = field(
  {
    description: (sd) => `The state in the USA of the ${sd}`,
    example: "NY",
    parameters: {
      eaa: CurrentEntityAndAddress,
    },
  },
  async ({ eaa }) => {
    const stateId = eaa.entity.formationState;
    if (!stateId) {
      return "";
    }

    if (!IsKnownState(stateId)) {
      return error(`unknown state ${stateId}`, {
        tableName: "entity",
        columnName: "formationState",
        rowId: eaa.entity.id,
      });
    }

    return StateTitle(stateId);
  }
);

const EntityAttorney_for_Notice = field(
  {
    description: (sd) => `The name of the attorney for notice of the ${sd}`,
    example: "Tom Jones",
    parameters: {
      eaa: CurrentEntityAndAddress,
    },
  },
  async ({ eaa }) => {
    return eaa.entity.attorneyForNotice;
  }
);

const Broker = field(
  {
    description: "The name of the broker for the lease",
    example: "Tom Jones",
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.broker;
  }
);

const Premises_Unit_No = field(
  {
    description: "The unit number of the premises",
    example: "1234",
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.premisesUnitNumber;
  }
);

const Premises_Square_Feet = field(
  {
    description: "The square feet size of the premises",
    kind: FieldKind.NiceNumber,
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.premisesSquareFeet;
  }
);

const Building_Square_Feet = field(
  {
    description: "The square feet size of the building",
    kind: FieldKind.NiceNumber,
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.buildingSquareFeet;
  }
);

const Tenants_Proportionate_Share = field(
  {
    description:
      "The proportion share the tenant has of the overall building square footage",
    example: "15%",
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return percentage(lease.premisesSquareFeet, lease.buildingSquareFeet);
  }
);

const Permitted_Use = field(
  {
    description: "The permitted use of the space by the tenant",
    example: "For an office",
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.permittedUse;
  }
);

const Commencement_Date = field(
  {
    description: "The commencement date of the lease",
    kind: FieldKind.Date,
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.commencementDate;
  }
);

const Rent_Commencement_Date = field(
  {
    description: "The commencement date of when rent is due for the space",
    kind: FieldKind.Date,
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.rentCommencementDate;
  }
);

const Possession_Date = field(
  {
    description: "The date when the tenant will take possesion",
    kind: FieldKind.Date,
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.possessionDate;
  }
);

const Triple_Net = field(
  {
    description: "NNN if TripleNet is set to true",
    example: "NNN",
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.tripleNet === 1 ? "NNN" : "";
  }
);

const Security_Deposit = field(
  {
    description: "The security deposit",
    kind: FieldKind.Currency,
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.securityDeposit;
  }
);

const First_Months_Rent = field(
  {
    description: "The first month's rent",
    kind: FieldKind.Currency,
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.firstMonthsRent;
  }
);

const Total_Due_on_Signing = field(
  {
    description: "The total amount of money due on signing",
    kind: FieldKind.Currency,
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return (lease.securityDeposit ?? 0) + (lease.firstMonthsRent ?? 0);
  }
);

const Lease_Term = field(
  {
    description: "The term of the lease",
    example: "6 months",
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.leaseTerm;
  }
);

const Lease_End_Date = field(
  {
    description: "The end date of the lease",
    kind: FieldKind.Date,
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.leaseEndDate;
  }
);

const Initial_Rent_PSF = field(
  {
    description: "The initial rent per square foot",
    kind: FieldKind.Currency,
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.initialRentPSF;
  }
);

const Addendum_Text = field(
  {
    description: "The text of the addendum on the lease, if any",
    example: "as amended",
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.hasAddendum === 1 ? lease.addendumText : "";
  }
);

const Lease_Extension_Term_End_Date = field(
  {
    description: "The end date of the lease extension",
    kind: FieldKind.Date,
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.leaseExtensionEndTermDate;
  }
);

const Guaranty_Title = field(
  {
    description: "The guaranty title",
    example: "",
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.guarantyTitle;
  }
);

const Guaranty_Date = field(
  {
    description: "The guaranty date",
    kind: FieldKind.Date,
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.guarantyDate;
  }
);

const Base_Rent_Term = field(
  {
    description: "The base rent terms",
    example: "",
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.baseRentTerm;
  }
);

const Lease_Termination_Date = field(
  {
    description: "The date of termination of the lease",
    kind: FieldKind.Date,
    parameters: {
      lease: LeaseInformation,
    },
  },
  async ({ lease }) => {
    return lease.leaseTerminationDate;
  }
);

const LeaseModifications = processor(
  {
    id: "lease_modifications",
    description: "Modifications to the lease",
  },
  async ({ ctx }) => {
    return ctx.db.list<Modification>(sql`select * from ${"modification"}`);
  }
);

const ActiveModification = processor(
  {
    id: "active_lease_modificatios",
    description: "The active modification on the lease, if any.",
    missingMessage: "A modification should be marked as active",
  },
  async ({ ctx }) => {
    const modifications = ctx.db.listMutable<Modification>(
      sql`select * from ${"modification"}`
    );
    return modifications.find(
      (m: MutableRow<Modification>) => m.getField("isActive") === 1
    )?.row;
  }
);

const ModificationDocumentDate = processor(
  {
    id: "active_lease_modification_document_date",
    description:
      "The document date of the active modification on the lease, if any.",
    dependencies: {
      active: ActiveModification,
    },
  },
  async ({ active }) => {
    return active.date;
  }
);

const Lease_And_Amendments_Summary = field(
  {
    description: "A summary of the lease and its amendments",
    example: "Some lease dated 1/1/1980",
    parameters: {
      lease: LeaseInformation,
      modifications: LeaseModifications,
    },
  },
  async ({ lease, modifications }) => {
    const modifiedText = joinMultiple(
      modifications,
      ", as modified ",
      ` (collectively, the "${lease.documentShortName}")`,
      (m: Modification) => {
        return `by the ${m.title} dated ${documentDate(m.date)} ("${
          m.shortTitle
        }")`;
      },
      (left: Modification, right: Modification) => {
        return compareDocumentDates(left.date, right.date);
      }
    );
    return `${lease.documentTitle} dated ${documentDate(
      lease.documentDate
    )} ("${lease.initialShortName}")${modifiedText}`;
  }
);

const Modification_Title = field(
  {
    description: "The title of the currently active modification",
    example: "Some modification",
    parameters: {
      active: ActiveModification,
    },
  },
  async ({ active }) => {
    return active.title;
  }
);

const Modification_Short_Title = field(
  {
    description: "The short title of the currently active modification",
    example: "Some modification",
    parameters: {
      active: ActiveModification,
    },
  },
  async ({ active }) => {
    return active.shortTitle;
  }
);

const LeaseTerms = processor(
  {
    id: "lease_terms",
    description: "The terms of the lease",
  },
  async ({ ctx }) => {
    return ctx.db.list<LeaseTerm>(sql`select * from ${"leaseterm"}`);
  }
);

const LeaseExtensions = processor(
  {
    id: "lease_extensions",
    description: "The extensions of the lease",
  },
  async ({ ctx }) => {
    return ctx.db.list<LeaseExtension>(sql`select * from ${"leaseextension"}`);
  }
);

const CurrentTerm = context<TermOrExtension>("current_term");

const Term_Term = field(
  {
    description: "The duration of the term",
    example: "5 months",
    parameters: {
      term: CurrentTerm,
    },
  },
  async ({ term }) => {
    return term.term;
  }
);

const Term_Rent_PSF = field(
  {
    description: "The rent per square foot of the term",
    kind: FieldKind.Currency,
    parameters: {
      term: CurrentTerm,
    },
  },
  async ({ term }) => {
    return term.rentPSF;
  }
);

const Term_Monthly_Base_Rent = field(
  {
    description: "The monthly base rent of the term",
    kind: FieldKind.Currency,
    parameters: {
      term: CurrentTerm,
      lease: LeaseInformation,
    },
  },
  async ({ term, lease }) => {
    return ((term.rentPSF ?? 0) * (lease.premisesSquareFeet ?? 0)) / 12;
  }
);

const Term_Annual_Base_Rent = field(
  {
    description: "The annual base rent of the term",
    kind: FieldKind.Currency,
    parameters: {
      term: CurrentTerm,
      lease: LeaseInformation,
    },
  },
  async ({ term, lease }) => {
    return (term.rentPSF ?? 0) * (lease.premisesSquareFeet ?? 0);
  }
);

export const CommercialLeaseDataFields: DataFieldDefinitions = {
  // Firm.
  ...FirmFields,

  // Document
  Document: expand({
    Title: Document_Title,
    Short_Name: Document_ShortName,
    _: documentDateFields(LeaseDocumentDate),
  }),

  // Parties
  ...fieldsPerKind(
    EntityKindDescription,
    EntityByKind,
    [CurrentEntityAndAddress, CurrentAddress],
    {
      "": EntityName,
      State: EntityState,
      Signer: EntitySigner,
      Signer_Title: EntitySignerTitle,
      Type: EntityType,
      Attorney_for_Notice: EntityAttorney_for_Notice,

      ...OptionalAddressFields,
    }
  ),

  // Broker
  Broker: Broker,

  // Addresses.
  Premises: expand({
    _: expandWithInjectedContextValue(
      CurrentAddress,
      Premises_Address,
      OptionalAddressFields
    ),
  }),

  Building: expand({
    _: expandWithInjectedContextValue(
      CurrentAddress,
      Building_Address,
      OptionalAddressFields
    ),
  }),

  Premises_Unit_No: Premises_Unit_No,
  Premises_Square_Feet: Premises_Square_Feet,
  Building_Square_Feet: Building_Square_Feet,
  Tenants_Proportionate_Share: Tenants_Proportionate_Share,
  Permitted_Use: Permitted_Use,
  Commencement_Date: Commencement_Date,
  Rent_Commencement_Date: Rent_Commencement_Date,
  Possession_Date: Possession_Date,
  Triple_Net: Triple_Net,
  Security_Deposit: Security_Deposit,
  First_Months_Rent: First_Months_Rent,
  Total_Due_on_Signing: Total_Due_on_Signing,
  Lease_Term: Lease_Term,
  Lease_End_Date: Lease_End_Date,
  Initial_Rent_PSF: Initial_Rent_PSF,

  Addendum_Text: Addendum_Text,
  Lease_Extension_Term_End_Date: Lease_Extension_Term_End_Date,
  Guaranty_Title: Guaranty_Title,
  Guaranty_Date: Guaranty_Date,
  Base_Rent_Term: Base_Rent_Term,
  Lease_Termination_Date: Lease_Termination_Date,

  Lease_And_Amendments_Summary: Lease_And_Amendments_Summary,

  Modification: expand({
    Title: Modification_Title,
    Short_Title: Modification_Short_Title,
    _: documentDateFields(ModificationDocumentDate),
  }),

  Rental_Terms: expand({
    ...fieldsPerEntry(
      LeaseTerms,
      CurrentTerm,
      {
        Term_Y: Term_Term,
        Rent_PSF_Y: Term_Rent_PSF,
        Monthly_Base_Rent_Y: Term_Monthly_Base_Rent,
        Annual_Base_Rent_Y: Term_Annual_Base_Rent,
      },
      10,
      true
    ),
  }),
  Ext: expand({
    ...fieldsPerEntry(
      LeaseExtensions,
      CurrentTerm,
      {
        Term_Y: Term_Term,
        Rent_PSF_Y: Term_Rent_PSF,
        Monthly_Base_Rent_Y: Term_Monthly_Base_Rent,
        Annual_Base_Rent_Y: Term_Annual_Base_Rent,
      },
      5,
      true
    ),
  }),
};
