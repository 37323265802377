import { ApolloClient } from "@apollo/client";
import { Chip, withStyles } from "@material-ui/core";
import axios from "axios";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAlert } from "../components/AlertProvider";
import { useConfirmDialog } from "../components/ConfirmDialogProvider";
import { useDocumentTracker } from "../components/documents/DocumentTracker";
import { DiffableDatabase, MutableRow } from "../database/diffable/interfaces";
import { IDed, PreparedQuery } from "../database/sql";
import { parseDocx } from "../docx/docx";
import {
  CREATE_CASE_DOCUMENT,
  CreateCaseDocumentData,
  CreatedDocumentData,
} from "../queries/documents";
import { LOOKUP_FIRM_MEMBER } from "../queries/firm";
import { useManagedMutation } from "../queries/lib/hooks";
import { TEMPLATES } from "../templates";
import { Case, CaseDatabaseRevision } from "../types/case";
import { DocumentTemplate } from "../types/documentemplate";
import { FirmMember } from "../types/firmmember";
import { useAuthenticationService } from "./authentication";
import { ApplicationConfig, RunEnvironment } from "./configservice";
import {
  ErrorIssue,
  FullyExpandedFields,
  MissingValueIssue,
} from "./datafielddefinitions";
import { firstPanelForMissing } from "./uiservice";

/**
 * isEmptyStringValue returns true if the given value can be considered an empty (and therefore, missing)
 * field value.
 */
export function isEmptyStringValue(value: any): boolean {
  return value === "" || value === null;
}

/**
 * FieldReference represents a reference to a field in a database.
 */
export interface FieldReference {
  /**
   * tableName is the name of the table in which the missing value was found.
   */
  tableName: string;

  /**
   * rowId is the ID of the row containing the missing value.
   */
  rowId: number;

  /**
   * columnName is the name of the column containing the missing value.
   */
  columnName: string;
}

/**
 * MissingValueKey is a key for a missing value, created by either the legacy document generator
 * model or a processor in the new data field model.
 */
export type MissingValueKey = string;

/**
 * MissingValue defines a missing piece of information necessary to generate a document.
 */
export type MissingValue = ProcessorMissingValue | LegacyCustomMissing;

type ProcessorMissingValue = Exclude<MissingValueIssue, "issueKind">;

type LegacyCustomMissing = {
  key: string;
  checker: (cse: Case, db: DiffableDatabase) => [string, boolean];
};

const missingFieldKey = (mf: FieldReference) => {
  return `${mf.tableName}:${mf.columnName}:${mf.rowId}`;
};

type MissingFieldKey = string;

export type MissingValueDescription = {
  missingMessage: string | undefined;
  isMissing: boolean;
};

/**
 * DocumentMissingTracker is the missing information for creating a document.
 */
export class DocumentMissingTracker {
  /**
   * missingFields are those database fields whose values are missing but necessary to generate
   * a document.
   */
  private missingFields: Record<MissingFieldKey, FieldReference> = {};

  /**
   * missingValues are any missing generated pieces of information outside of specific database
   * fields.
   */
  private missingValues: Record<MissingValueKey, MissingValue> = {};

  /**
   * addMissingField adds a field with a missing value to the tracker.
   */
  public addMissingField(field: FieldReference) {
    this.missingFields[missingFieldKey(field)] = field;
  }

  /**
   * listFieldsWithMissingValues returns those fields with missing values.
   */
  public listFieldsWithMissingValues(): readonly FieldReference[] {
    return Object.values(this.missingFields);
  }

  /**
   * hasMissingValue returns whether the given missing value is present.
   */
  public hasMissingValue(key: MissingValueKey) {
    return key in this.missingValues;
  }

  /**
   * addMissingValue adds a missing value to the tracker.
   */
  public addMissingValue(value: MissingValue) {
    if ("key" in value) {
      this.missingValues[value.key] = value;
      return;
    }

    this.missingValues[value.processor.id] = value;
  }

  /**
   * hasMissingFieldsOrValues returns true if there is at least one missing field or value.
   * @returns
   */
  public hasMissingFieldsOrValues(): boolean {
    return (
      !!Object.keys(this.missingValues).length ||
      !!Object.keys(this.missingFields).length
    );
  }

  /**
   * getMissingValue returns the missing value with the given key, if any.
   */
  public getMissingValue(key: MissingValueKey): MissingValue | undefined {
    return this.missingValues[key];
  }

  /**
   * listMissingValues returns the missing values in the tracker.
   */
  public listMissingValues(): MissingValue[] {
    return Object.values(this.missingValues);
  }

  /**
   * missingValueKeys returns the keys for all missing values in the tracker, if any.
   */
  public missingValueKeys(): MissingValueKey[] {
    return Object.keys(this.missingValues);
  }

  /**
   * currentMissingFieldsAndValuesCount returns the current count of missing fields for the document,
   * if any.
   */
  public async currentMissingFieldsAndValuesCount(
    cse: Case,
    database: DiffableDatabase
  ): Promise<number> {
    let counter = 0;
    for (const missingValueKey of Object.keys(this.missingValues)) {
      if (
        (
          await this.checkIfValueIsCurrentlyMissing(
            missingValueKey,
            cse,
            database
          )
        ).isMissing
      ) {
        counter += 1;
      }
    }

    for (const missingField of Object.values(this.missingFields)) {
      if (
        database.transaction.hasMissingValue(
          missingField.tableName,
          missingField.rowId,
          missingField.columnName
        )
      ) {
        counter += 1;
      }
    }

    return counter;
  }

  public async checkIfValueIsCurrentlyMissing(
    key: MissingFieldKey,
    cse: Case,
    database: DiffableDatabase
  ): Promise<MissingValueDescription> {
    const missingValue = this.missingValues[key]!;

    // LEGACY
    if ("checker" in missingValue) {
      let missingMessage = undefined;
      let isValid = false;

      const missingResult = missingValue.checker(cse, database);
      if (missingResult) {
        [missingMessage, isValid] = missingResult;
      }
      return {
        missingMessage: missingMessage,
        isMissing: !isValid,
      };
    }

    const result = await missingValue.rerun();
    return {
      missingMessage: missingValue.processor.missingMessage ?? "",
      isMissing: result === undefined,
    };
  }

  /**
   * clone makes a clone of the missing value tracker.
   */
  public clone(): DocumentMissingTracker {
    const clone = new DocumentMissingTracker();
    clone.missingFields = { ...this.missingFields };
    clone.missingValues = { ...this.missingValues };
    return clone;
  }
}

/**
 * TrackedDatabaseState represents the state of the underlying tracked database.
 */
export enum TrackedDatabaseState {
  SAVED = 0,
  HAS_UNSAVED_CHANGED = 1,
}

/**
 * TrackedDatabase is a database wrapper which tracks all column accesses and records
 * missing fields and values.
 */
export class TrackedDatabase {
  constructor(
    private database: DiffableDatabase,
    public missing: DocumentMissingTracker = new DocumentMissingTracker()
  ) {}

  /**
   * state returns the save state for the underlying database.
   * @returns
   */
  public state(): TrackedDatabaseState {
    if (this.database.transaction.currentMutationCount() > 0) {
      return TrackedDatabaseState.HAS_UNSAVED_CHANGED;
    }

    return TrackedDatabaseState.SAVED;
  }

  /**
   * addMissingValue indicates that a value is missing.
   */
  public addMissingValue(missing: MissingValue) {
    this.missing.addMissingValue(missing);
  }

  /**
   * optional marks the given `row` so that any missing values are not tracked,
   * as they are considered optional.
   * @example db.optional(someRow).someColumn // <- Will not require the someColumn as missing if empty.
   */
  public optional<T extends IDed>(row: T): T {
    const wrappingProxy = new Proxy(row, {
      get: (target, prop, receiver) => {
        const existing = this.missing.clone();
        const value = Reflect.get(target, prop, receiver);
        if (isEmptyStringValue(value)) {
          // Remove the entry for this field.
          this.missing = existing;
        }
        return value;
      },
    });

    return wrappingProxy;
  }

  /**
   * List returns the row(s) found at the given query.
   * @param query The select query.
   */
  public listMutable<T extends IDed>(query: PreparedQuery): MutableRow<T>[] {
    const rows = this.database.selectAllResults<T>(query);
    return rows;
  }

  /**
   * List returns the row(s) found at the given query.
   * @param query The select query.
   */
  public list<T extends IDed>(query: PreparedQuery): T[] {
    const rows = this.database.selectAllResults<T>(query);
    return rows.map((row: MutableRow<T>) =>
      this.trackRow(query.tableName, row)
    );
  }

  /**
   * Get returns the single row found at the given query, or raises an error if none.
   * @param query The select query.
   */
  public get<T extends IDed>(query: PreparedQuery): T {
    const found = this.getOrUndefined<T>(query);
    if (found === undefined) {
      throw Error("Missing expected row");
    }
    return found;
  }

  /**
   * getOrUndefined returns the first row found for the given query or undefined if none.
   * @param query The select query.
   */
  public getOrUndefined<T extends IDed>(query: PreparedQuery): T | undefined {
    const rows = this.database.selectAllResults<T>(query);
    if (!rows.length) {
      return undefined;
    }

    return this.trackRow(query.tableName, rows[0]);
  }

  private trackRow<T extends IDed>(tableName: string, row: MutableRow<T>) {
    // Register a proxy for the row which tracks all column (field) accesses and,
    // if the value is an empty string, records the column as missing a value.
    const trackingProxy = new Proxy(row.row, {
      get: (target, prop, receiver) => {
        const value = Reflect.get(target, prop, receiver);
        if (isEmptyStringValue(value)) {
          this.missing.addMissingField({
            tableName: tableName,
            rowId: target.id,
            columnName: prop.toString(),
          });
        }
        return value;
      },
    });

    return trackingProxy;
  }
}

/**
 * FirmMemberLookup defines a callback for performing a lookup of a member under a firm.
 */
export type FirmMemberLookup = (
  memberId: string
) => Promise<FirmMember | undefined>;

/**
 * DocumentDataConstructor is a constructor which returns a set of DocumentDataBuilder's
 * for each field expected by document templates in a case template.
 */
export type DocumentDataConstructor = (
  cse: Case,
  database: TrackedDatabase,
  memberLookup: FirmMemberLookup
) => Promise<
  [Record<string, DocumentDataBuilder> | undefined, string | undefined]
>;

/**
 * DocError is an error raised during document data building.
 */
export interface DocError {
  message: string;
}

/**
 * DocDataResult is the result of a call to a DocumentDataBuilder.
 */
export type DocDataResult = string | number | DocError;

/**
 * DocumentDataBuilder is the value found for each record in the data constructor. When invoked,
 * the builder should create the string value for the document field.
 */
export type DocumentDataBuilder = () => Promise<DocDataResult>;

/**
 * GeneratedDocumentData is the result of a call to `generateDocumentParameters`.
 */
export interface GeneratedDocumentData {
  /**
   * data is the constructed data for the document template.
   */
  data: Record<string, any> | undefined;

  /**
   * error is the error that occurred, if any.
   */
  error: string | undefined;

  /**
   * missing contain any fields or values determined as missing during data construction.
   */
  missing: DocumentMissingTracker;
}

/**
 * firmMemberLookupFn returns a function for looking up members of a firm.
 */
export const firmMemberLookupFn = (apolloClient: ApolloClient<any>) => {
  return async (memberId: string) => {
    const result = await apolloClient.query<{ firmMemberById: FirmMember }>({
      query: LOOKUP_FIRM_MEMBER,
      variables: {
        memberId: memberId,
      },
    });

    return result.data?.firmMemberById;
  };
};

/**
 * generateDocumentParameters generates the document data/parameters for filling in a document
 * template under a case.
 *
 * @param appConfig The application config.
 * @param apolloClient The Apollo client to use for lookups.
 * @param cse The current case.
 * @param docTemplate The document template for which the data is being generated. Provide undefined to generate all fields.
 * @param database The database containing the data for the document.
 */
export async function generateDocumentParameters(
  appConfig: ApplicationConfig,
  apolloClient: ApolloClient<object>,
  cse: Case,
  docTemplate: DocumentTemplate | undefined,
  database: DiffableDatabase
): Promise<GeneratedDocumentData> {
  const caseTemplate = TEMPLATES[cse.templateId!];
  const trackedDatabase = new TrackedDatabase(database);

  if (caseTemplate.documentFields) {
    const expanded = await FullyExpandedFields(
      caseTemplate.documentFields!,
      trackedDatabase,
      cse,
      firmMemberLookupFn(apolloClient)
    );

    if (expanded.issues) {
      const errors: ErrorIssue[] = expanded.issues
        .filter((i) => i.issue.issueKind === "error")
        .map((i) => i.issue) as ErrorIssue[];

      if (errors.length) {
        return {
          data: undefined,
          error: errors[0].issue,
          missing: trackedDatabase.missing,
        };
      }

      const missing: MissingValueIssue[] = expanded.issues
        .filter((i) => i.issue.issueKind === "missingdep")
        .map((i) => i.issue) as MissingValueIssue[];

      missing.forEach((m) => {
        trackedDatabase.addMissingValue(m);
      });
    }

    const expectedFields = docTemplate
      ? docTemplate.fields
      : Object.keys(expanded.expandedFields);
    const generated: Record<string, any> = {};
    for (const key of Object.keys(expanded.expandedFields)) {
      if (!expectedFields.includes(key)) {
        continue;
      }

      const value = await expanded.expandedFields[key].value();
      if (typeof value === "object" && !!value && "issue" in value) {
        switch (value.issue.issueKind) {
          case "error":
            return {
              data: undefined,
              error: value.issue.issue,
              missing: trackedDatabase.missing,
            };

          case "missingdep":
            trackedDatabase.addMissingValue(value.issue);
            generated[key] = "";
            break;

          default:
            throw new Error("unknown kind of value");
        }
        continue;
      }

      generated[key] = value ?? "";
    }

    return {
      data: generated,
      error: undefined,
      missing: trackedDatabase.missing,
    };
  }

  // LEGACY FROM HERE DOWNWARD
  if (!caseTemplate.documentDataConstructor) {
    throw new Error("missing document data constructor");
  }

  const [fieldBuilders, err] = await caseTemplate.documentDataConstructor(
    cse,
    trackedDatabase,
    firmMemberLookupFn(apolloClient)
  );
  if (err !== undefined) {
    return {
      data: undefined,
      error: err,
      missing: trackedDatabase.missing,
    };
  }

  const fields = docTemplate ? docTemplate.fields : Object.keys(fieldBuilders!);
  let parameters: Record<string, any> = {};
  for (const fieldName of fields) {
    if (!(fieldName in fieldBuilders!)) {
      if (appConfig.runEnvironment === RunEnvironment.PRODUCTION) {
        throw Error("Missing builder for field " + fieldName);
      }

      console.log(fieldBuilders);
      return {
        data: undefined,
        error: "Missing builder for field " + fieldName,
        missing: trackedDatabase.missing,
      };
    }

    const result = await fieldBuilders![fieldName]();
    if (typeof result === "string" || typeof result === "number") {
      parameters[fieldName] = result.toString();
    } else {
      if (result === undefined) {
        if (appConfig.runEnvironment === RunEnvironment.PRODUCTION) {
          throw Error(`Got undefined for field ${fieldName}`);
        }

        return {
          data: undefined,
          error: `Got undefined for field ${fieldName}`,
          missing: trackedDatabase.missing,
        };
      }

      return {
        data: undefined,
        error: result.message,
        missing: trackedDatabase.missing,
      };
    }
  }

  return {
    data: parameters,
    error: undefined,
    missing: trackedDatabase.missing,
  };
}

/**
 * estr is a template string producer that consumes any DocDataResult values and bubbles them up.
 */
export function estr(
  literals: TemplateStringsArray,
  ...placeholders: DocDataResult[]
): DocDataResult {
  let overall = "";
  for (var i = 0; i < literals.length; ++i) {
    overall += literals[i];

    if (i < placeholders.length) {
      const result = placeholders[i];
      if (typeof result === "string") {
        overall += result;
      } else {
        return result;
      }
    }
  }
  return overall;
}

// From: https://www.javascripttutorial.net/dom/css/check-if-an-element-is-visible-in-the-viewport/
function isInViewport(element: Element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

/**
 * useDocumentCreator is a hook that provides a function for generating a document
 * for a case, including all the UI state modification.
 * @param cse The case.
 * @param currentRevision The current revision of the case's DB.
 * @param database The case DB.
 * @param client The Apollo client to use for creating the document.
 * @param shouldShowAlert Whether the alert should be shown when data is missing.
 * @returns A reference to an object including the generation function.
 */
export function useDocumentCreator(
  cse: Case,
  currentRevision: CaseDatabaseRevision,
  database: DiffableDatabase,
  appConfig: ApplicationConfig,
  client: ApolloClient<any>,
  shouldShowAlert: boolean
) {
  const [createCaseDocument] = useManagedMutation<
    CreatedDocumentData,
    CreateCaseDocumentData
  >(CREATE_CASE_DOCUMENT);

  const history = useHistory();
  const location = useLocation();
  const { getToken } = useAuthenticationService();

  const { tracker } = useDocumentTracker();
  const { showAlert } = useAlert();
  const { showConfirm } = useConfirmDialog();

  const createDocument = async (
    docTemplate: DocumentTemplate
  ): Promise<void> => {
    // Ensure the user has saved.
    if (database.transaction.currentMutationCount() > 0) {
      const [confirmResult] = await showConfirm({
        title: "Save Changes now?",
        content:
          "Changes to the case must be saved before creating a document. Save them now?",
        buttons: [
          { title: "Cancel", value: undefined },
          {
            title: "Save Changes and Create Document",
            value: "save",
            variant: "contained",
            color: "primary",
          },
        ],
      });

      if (confirmResult !== "save") {
        return;
      }

      const saveResult = await database.applyAndSave();
      if (!saveResult) {
        return;
      }
    }

    // Generate the parameters for the document.
    const generated = await generateDocumentParameters(
      appConfig,
      client,
      cse,
      docTemplate,
      database!
    );
    if (generated.error !== undefined) {
      showAlert({
        title: "Cannot generate document",
        content: generated.error,
        buttonTitle: "Okay",
      });
      return;
    }

    if (generated.missing.hasMissingFieldsOrValues()) {
      const tracked = tracker!.addDocument(docTemplate, generated.missing);
      const StyleChip = withStyles({
        root: {
          backgroundColor: tracked.color,
        },
      })(Chip);

      if (shouldShowAlert) {
        await showAlert({
          title: "Data is missing for the selected document",
          content: (
            <div>
              Some required data is missing for the selected document and has
              been{" "}
              <StyleChip style={{ marginRight: "1em" }} label="highlighted" />
              for you to fill in
            </div>
          ),
          buttonTitle: "Okay",
        });
      }

      // Show the pane with the first missing field.
      const panel = firstPanelForMissing(docTemplate, cse, tracker, database);
      if (panel !== undefined) {
        const newPath = `/c/${cse.id}/${panel.path}`;
        if (location.pathname !== newPath) {
          history.push(newPath);

          // Set a timeout to show the element if it is off screen after a render.
          setTimeout(() => {
            const activeElement = document.activeElement;
            if (
              activeElement &&
              activeElement.nodeName.toLowerCase() === "input" &&
              activeElement.getAttribute("data-missing")
            ) {
              if (!isInViewport(activeElement)) {
                activeElement.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                  inline: "nearest",
                });
              }
            }
          }, 50);
        } else {
          // Otherwise, scroll into view and focus the first missing field.
          const found = document.querySelector(
            `input[data-missing="${docTemplate.id}"]`
          );
          if (found) {
            (found as HTMLInputElement).scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
            (found as HTMLInputElement).focus();
          }
        }
      }
      return;
    } else {
      tracker!.removeDocument(docTemplate);
    }

    // Download the template for the document.
    const templateResult = await fetch(
      `${appConfig.endpoint}${docTemplate.previewUrl}`
    );
    const templateBody = await templateResult.arrayBuffer();

    // Apply the parameters to the template.
    const parsed = await parseDocx(templateBody);
    if (parsed === undefined) {
      throw new Error(
        `Could not parse document template for document ${docTemplate.id}`
      );
    }

    const filled = await parsed.fillTemplate(generated.data!);

    // Call the backend to generate the document.
    const result = await createCaseDocument({
      variables: {
        caseId: cse.id,
        documentTemplateId: docTemplate.id,
        title: docTemplate.title,
        revisionId: currentRevision.id,
        parameters: JSON.stringify(generated.data),
      },
    });
    if (result) {
      // Upload the filled document.
      const bearerToken = await getToken();

      const headers: Record<string, string> = {
        "content-type": "application/octet-stream",
        authorization: `Bearer ${bearerToken}`,
      };

      try {
        const uploadResult = await axios.request({
          method: "post",
          url: `${appConfig.endpoint}${result.createCaseDocument.createdDocument.wordUploadUrl}`,
          data: filled,
          headers: headers,
        });

        if (uploadResult.status / 100 !== 2) {
          await showAlert({
            title: "Document creation failed",
            content: (
              <div>
                The creation of the document failed. Please try again shortly.
              </div>
            ),
            buttonTitle: "Okay",
          });
          return;
        }
      } catch (e) {
        await showAlert({
          title: "Document creation failed",
          content: (
            <div>
              The creation of the document failed. Please try again shortly.
            </div>
          ),
          buttonTitle: "Okay",
        });
        return;
      }

      // Refetch case documents.
      client.reFetchObservableQueries();

      // Switch to the document view.
      history.push(
        `/c/${cse.id}/document/${result.createCaseDocument.createdDocument.id}`
      );
    }
  };

  return {
    createDocument: createDocument,
  };
}
