import { DocumentTemplate } from "./documentemplate";
import { Firm } from "./firm";

/**
 * CaseAccess is the access role for the case.
 */
export enum CaseAccess {
  FIRM_WIDE = "FIRM_WIDE",
  RESTRICTED = "RESTRICTED",
}

/**
 * Case defines a case being viewed or modified.
 */
export interface Case {
  /**
   * id is the unique ID for the case.
   */
  id: string;

  /**
   * title is the title of the case.
   */
  title: string;

  /**
   * firmCaseReference is the firm's case reference for the case, if any.
   */
  firmCaseReference: string;

  /**
   * firmClientName is the name of the client for the firm in the case, if any.
   */
  firmClientName: string;

  /**
   * templateId is the ID of the frontend template used for this case.
   */
  templateId: string;

  /**
   * color, if defined, is the custom color for the case.
   */
  color?: string | undefined;

  /**
   * currentDatabaseRevision is the current database revision for the case.
   */
  currentDatabaseRevision?: CaseDatabaseRevision | undefined;

  /**
   * caseAccess is the access role for the case.
   */
  caseAccess?: CaseAccess;

  /**
   * isCreator is true if the current user is the creator of the case.
   */
  isCreator?: boolean;

  /**
   * firm is the firm for the case.
   */
  firm?: Firm;

  /**
   * docTemplates are the doc templates available for the case.
   */
  docTemplates?: DocumentTemplate[];

  /**
   * hasGeneratedDocuments indicates whether the case has any generated documents.
   */
  hasGeneratedDocuments?: boolean;

  /**
   * isGptSupported indicates whether the GPT assistant is supported.
   */
  isGptSupported?: boolean;
}

/**
 * CaseDatabaseRevision represents a single revision to a case's database.
 */
export interface CaseDatabaseRevision {
  /**
   * id is the unique ID for the revision.
   */
  id: string;

  /**
   * downloadURL is the URL at which the case's database revision can be downloaded.
   */
  downloadUrl: string;

  /**
   * updateUrl is the URL at which the case's database can be updated.
   */
  updateUrl: string;

  /**
   * createdAt is when the revision was created.
   */
  createdAt: string;

  /**
   * revisionIndex is the 0-indexed revision index for the revision.
   */
  revisionIndex: number;
}
