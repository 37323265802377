import { ApolloClient } from "@apollo/client";
import dayjs from "dayjs";
import { GetColorName } from "hex-color-to-color-name";
import React, { useMemo, useState } from "react";
import { useDocumentTracker } from "../components/documents/DocumentTracker";
import { DiffableDatabase } from "../database/diffable/interfaces";
import {
  GPTMessage,
  INVOKE_GPT_QUERY,
  InvokeGPTQueryData,
  InvokeGPTQueryResponse,
} from "../queries/gpt";
import { useManagedMutation } from "../queries/lib/hooks";
import { TEMPLATES } from "../templates";
import { Case } from "../types/case";
import { DocumentTemplate } from "../types/documentemplate";
import { ApplicationConfig } from "./configservice";
import {
  FullyExpandedFields,
  SummarizedFields,
  Value,
} from "./datafielddefinitions";
import {
  TrackedDatabase,
  firmMemberLookupFn,
  generateDocumentParameters,
} from "./documentservice";

const MAX_ITERATIONS = 5;

const ACTION_REGEX = /Action: ([A-Za-z-_]+): (.+)/;
const THOUGHT_REGEX = /Thought: (.+)/;

export type GPTServiceState = {
  supported: boolean;
  running: boolean;
  run: (query: string) => Promise<void>;
  outputs: GPTOutput[];
};

export type OutputKind = "user" | "thought" | "answer" | "error";

export type GPTOutput = {
  kind: OutputKind;
  content: React.ReactChild;
};

type ActionResult = Value | { terminate: React.ReactChild };

/**
 * useGPT is a hook which provides access to the GPT-based agent.
 */
export function useGPT(
  database: DiffableDatabase,
  cse: Case,
  apolloClient: ApolloClient<object>,
  appConfig: ApplicationConfig
): GPTServiceState {
  const [invokeGPTQuery, { loading: running }] = useManagedMutation<
    InvokeGPTQueryResponse,
    InvokeGPTQueryData
  >(INVOKE_GPT_QUERY);

  const { tracker } = useDocumentTracker();

  const [outputs, setOutputs] = useState<GPTOutput[]>([]);

  const trackedDatabase = useMemo(() => {
    return new TrackedDatabase(database);
  }, [database]);
  const documentTemplates: DocumentTemplate[] = useMemo(() => {
    return cse.docTemplates ?? [];
  }, [cse]);

  const fieldDefs = TEMPLATES[cse.templateId].documentFields;

  const actions: Record<string, (arg: string) => Promise<ActionResult>> =
    useMemo(() => {
      return {
        get_field_value: async (fieldName) => {
          const expanded = await FullyExpandedFields(
            fieldDefs!,
            trackedDatabase,
            cse,
            firmMemberLookupFn(apolloClient)
          );

          if (!(fieldName in expanded.expandedFields)) {
            return undefined;
          }

          const field = expanded.expandedFields[fieldName];
          return field.value();
        },
        determine_missing_fields: async (docName) => {
          const docTemplate = documentTemplates.find(
            (dt) => dt.title === docName
          );
          if (docTemplate === undefined) {
            return undefined;
          }

          const generated = await generateDocumentParameters(
            appConfig,
            apolloClient,
            cse,
            docTemplate,
            database!
          );

          if (generated.missing.hasMissingFieldsOrValues()) {
            const added = tracker.addDocument(docTemplate, generated.missing);
            const message = (
              <div>
                Missing fields for <code>{docTemplate.title}</code> have been
                highlighted in{" "}
                <span style={{ color: added.color, fontWeight: "bold" }}>
                  {GetColorName(added.color)}
                </span>
              </div>
            );
            return {
              terminate: message,
            };
          }

          return "none";
        },
        generate_document: async (docName) => {
          return undefined;
        },
        calculate_days_between_dates: async (paramsString) => {
          const dates = paramsString.split("|");
          if (dates.length !== 2) {
            return undefined;
          }

          const [firstDate, secondDate] = dates;
          const difference = dayjs(secondDate).diff(dayjs(firstDate), "day");
          return `${difference} days`;
        },
      };
    }, [
      trackedDatabase,
      cse,
      apolloClient,
      fieldDefs,
      appConfig,
      database,
      documentTemplates,
      tracker,
    ]);

  const run = async (query: string) => {
    if (!fieldDefs) {
      return;
    }

    setOutputs([]);

    const fields = await SummarizedFields(fieldDefs, trackedDatabase, cse);
    let outputs: GPTOutput[] = [];

    const pushOutput = (output: React.ReactChild, kind: OutputKind) => {
      outputs = [...outputs, { content: output, kind: kind }];
      setOutputs(outputs);
      console.log(output);
    };

    const messages: GPTMessage[] = [];

    pushOutput(query, "user");
    pushOutput(`Thinking...`, "thought");

    for (let i = 0; i < MAX_ITERATIONS; i++) {
      const result = await invokeGPTQuery({
        variables: {
          caseId: cse.id,
          fields: Object.keys(fields.expandedFields).map((name) => {
            return {
              title: name,
              description: fields.expandedFields[name].description(),
            };
          }),
          documents: documentTemplates.map((dt) => {
            return {
              title: dt.title,
              description: dt.description,
            };
          }),
          litigations: [],
          existing: messages,
          message: query,
        },
      });
      if (!result?.invokeGptQuery.status) {
        pushOutput("I'm sorry, but an error occurred", "error");
        return;
      }

      const response = result.invokeGptQuery.response.trim();
      console.log(response);
      if (response.includes("Answer: ")) {
        const index = response.indexOf("Answer: ");
        pushOutput(response.substring(index + "Answer: ".length), "answer");
        return;
      }

      if (response.includes("Action: ")) {
        const parsedAction = ACTION_REGEX.exec(response);
        if (!parsedAction || !parsedAction[1] || !parsedAction[2]) {
          console.warn("Missing action information", parsedAction?.groups);
          pushOutput(
            "I'm sorry, but I did not know how to proceed. Please report this to support.",
            "error"
          );
          return;
        }

        const action = actions[parsedAction[1]];
        if (!action) {
          console.warn("Unknown action", parsedAction[1]);
          pushOutput(
            "I'm sorry, but I did not know how to proceed. Please report this to support.",
            "error"
          );
          return;
        }

        const value = await action(parsedAction[2]);
        if (value === undefined || value === null) {
          pushOutput(
            "I'm sorry, but that information is not available",
            "error"
          );
          return;
        }

        if (typeof value === "object" && "issue" in value) {
          if ("error" in value.issue) {
            pushOutput(
              `I'm sorry, but that information is not available because ${value.issue.error}`,
              "error"
            );
            return;
          }

          pushOutput(
            `I'm sorry, but that information is not available because required information has not been entered`,
            "error"
          );
          return;
        }

        if (typeof value === "object" && "terminate" in value) {
          pushOutput(value["terminate"], "answer");
          return;
        }

        messages.push({ content: response, usermsg: false });
        messages.push({
          content: `Observation: ${value || "(missing)"}`,
          usermsg: true,
        });

        const thought = THOUGHT_REGEX.exec(response);
        if (thought) {
          pushOutput(`${thought[1]}`, "thought");
        }
        continue;
      }

      pushOutput("I'm sorry, but an issue occurred", "error");
      return;
    }

    pushOutput("It appears an answer could not be determined", "error");
  };

  return {
    supported: (!!fieldDefs && cse.isGptSupported) || false,
    running: running,
    run: run,
    outputs: outputs,
  };
}
