import { useApolloClient } from "@apollo/client/react/hooks";
import {
  faFolder,
  faFolderPlus,
  faListAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import MaterialTable, { Icons } from "material-table";
import React, { forwardRef, useEffect } from "react";
import { Link as ReactLink, useHistory } from "react-router-dom";
import {
  CREATE_CASE,
  CreateCaseData,
  CreatedCaseData,
  LOOKUP_CASES,
} from "../queries/case";
import { useManagedMutation } from "../queries/lib/hooks";
import {
  AuthenticatedUser,
  SET_USER_NAME,
  SetUserNameParams,
} from "../queries/user";
import { useMixpanel } from "../services/mixpanel";
import { TEMPLATES } from "../templates";
import { CaseTemplate } from "../templates/interfaces";
import { Case } from "../types/case";
import BoundTable from "./BoundTable";
import { CaseIcon } from "./CaseReference";
import { CaseTemplateIcon } from "./CaseTemplateIcon";
import { useConfirmDialog } from "./ConfirmDialogProvider";
import TabLabel from "./TabLabel";
import TabPanel from "./TabPanel";
import useHistoricalTabs from "./historicaltabs";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "block",
      width: "100%",
    },
    caseGrid: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
      columnGap: "10px",
      rowGap: "10px",
      width: "100%",
    },
    tabPanel: {
      padding: theme.spacing(2),
    },
    creating: {
      display: "flex",
      alignItems: "center",
    },
    spinner: {
      marginRight: theme.spacing(2),
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);

export default function AppLanding(props: { currentUser: AuthenticatedUser }) {
  const classes = useStyles();
  const history = useHistory();

  const tableIcons: Icons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const { showConfirm } = useConfirmDialog();
  const mixPanel = useMixpanel();

  const [createCase, { loading: creating }] = useManagedMutation<
    CreatedCaseData,
    CreateCaseData
  >(CREATE_CASE);
  const client = useApolloClient();

  const askCreateCase = (template: CaseTemplate) => {
    (async () => {
      const [result, enteredTitle] = await showConfirm({
        title: "Create new case",
        content: (
          <div>
            Enter the title of your new case using the {template.title}{" "}
            template:
          </div>
        ),
        withPrompt: "Case title",
        buttons: [
          { title: "Cancel", value: undefined },
          {
            title: "Create Case",
            value: "create",
            color: "primary",
            variant: "contained",
          },
        ],
      });
      if (result === "create") {
        mixPanel.trackEvent("create_case", {
          flowId: template.id,
        });

        const result = await createCase({
          variables: {
            templateId: template.id,
            title: enteredTitle!,
            userOnly: false,
          },
        });

        if (result) {
          // Reset the cache so that we reload the cases list next time.
          client.resetStore();

          // Redirect to the case.
          history.push(`/c/${result.createCase.createdCase.id}`);
        }
      }
    })();
  };

  // Show dialog for asking the user's name if it is an email or empty.
  const [setUserName, { loading: updatingName }] = useManagedMutation<
    any,
    SetUserNameParams
  >(SET_USER_NAME);

  useEffect(() => {
    if (!props.currentUser.name || props.currentUser.name.includes("@")) {
      (async () => {
        const [, enteredName] = await showConfirm({
          title: "Enter Full Name",
          content:
            "Please enter your full name as it should appear in legal documents",
          buttons: [
            {
              title: "Save Full Name",
              value: "save",
              color: "primary",
              variant: "contained",
            },
          ],
          withPrompt: "Your Full Name",
        });
        if (enteredName) {
          await setUserName({
            variables: {
              name: enteredName,
            },
          });
        }
      })();
    }
  }, [props.currentUser, setUserName, showConfirm]);

  const allowedTemplates = Object.values(TEMPLATES).filter((ct: CaseTemplate) =>
    props.currentUser.firm?.allowedFlowIds.includes(ct.id)
  );
  const { currentTabName, handleChangeTab, setCurrentTab } = useHistoricalTabs([
    "cases",
    "createcase",
  ]);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleFabClicked = () => {
    setCurrentTab("createcase");
  };

  return (
    <div className={classes.root}>
      {updatingName && (
        <div className={classes.creating}>
          <CircularProgress className={classes.spinner} /> Please Wait...
          Updating name...
        </div>
      )}
      {creating && (
        <div className={classes.creating}>
          <CircularProgress className={classes.spinner} /> Please Wait...
          Creating Case...
        </div>
      )}
      {!creating && !updatingName && (
        <>
          <AppBar position="static" color="default">
            <Tabs
              value={currentTabName}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                label={
                  <TabLabel
                    icon={<FontAwesomeIcon icon={faListAlt} size="lg" />}
                    title="Cases"
                  />
                }
                value="cases"
              />
              <Tab
                label={
                  <TabLabel
                    icon={<FontAwesomeIcon icon={faFolderPlus} size="lg" />}
                    title="Create Case"
                  />
                }
                value="createcase"
              />
            </Tabs>
          </AppBar>
          <TabPanel
            className={classes.tabPanel}
            value={currentTabName}
            index="cases"
          >
            <BoundTable<Case>
              query={{
                gql: LOOKUP_CASES,
                variables: {},
                recordsKey: ["authenticatedUser", "cases"],
              }}
              searchQueryField="filter"
              emptyMessage={
                <div>
                  <FontAwesomeIcon icon={faFolder} size="6x" />
                  <h2>No cases have been created</h2>
                  <h3>
                    Click "Create Case" below to get started with creating a new
                    case
                  </h3>
                  <Button
                    component={ReactLink}
                    variant="contained"
                    color="primary"
                    size="large"
                    to={`?tab=createcase`}
                  >
                    Create Case
                  </Button>
                </div>
              }
              columns={[
                {
                  id: "icon",
                  title: "",
                  isSortable: false,
                  cellStyle: {
                    width: "1rem",
                  },
                  render: (data: Case) => {
                    return <CaseIcon case={data} />;
                  },
                },
                {
                  id: "title",
                  title: "Title",
                  isPrimary: true,
                  isSortable: true,
                  render: (data: Case) => {
                    return (
                      <Link
                        color="textPrimary"
                        component={ReactLink}
                        to={`/c/${data.id}`}
                      >
                        {data.title}
                      </Link>
                    );
                  },
                },
                {
                  id: "reference",
                  title: "Firm Case Number/Reference",
                  isSortable: true,
                  render: (data: Case) => {
                    if (data.firmCaseReference) {
                      return data.firmCaseReference;
                    }

                    return (
                      <Typography color="textSecondary">(None)</Typography>
                    );
                  },
                },
                {
                  id: "kind",
                  title: "Kind",
                  render: (data: Case) => {
                    return TEMPLATES[data.templateId!].title;
                  },
                },
              ]}
            />
            <div className={classes.fab}>
              <SpeedDial
                ariaLabel="Save changes"
                icon={<FontAwesomeIcon icon={faFolderPlus} size="2x" />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                direction="up"
                FabProps={{ onClick: handleFabClicked }}
              >
                {allowedTemplates.map((template: CaseTemplate) => {
                  return (
                    <SpeedDialAction
                      key={template.id}
                      style={{ whiteSpace: "nowrap" }}
                      icon={<CaseTemplateIcon template={template} />}
                      tooltipTitle={template.title}
                      tooltipOpen
                      onClick={() => askCreateCase(template)}
                    />
                  );
                })}
                ;
              </SpeedDial>
            </div>
          </TabPanel>
          <TabPanel
            className={classes.tabPanel}
            value={currentTabName}
            index="createcase"
          >
            <MaterialTable
              icons={tableIcons}
              columns={[
                {
                  title: "",
                  field: "icon",
                  render: (data: CaseTemplate) => {
                    return <CaseTemplateIcon template={data} />;
                  },
                  cellStyle: {
                    width: "0px",
                  },
                },
                {
                  title: "Title",
                  field: "title",
                  cellStyle: {
                    width: "20%",
                  },
                },
                {
                  title: "Description",
                  field: "description",
                  cellStyle: {
                    width: "80%",
                    whiteSpace: "nowrap",
                  },
                },
                {
                  title: "",
                  field: "actions",
                  render: (data: CaseTemplate) => {
                    return (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => askCreateCase(data)}
                      >
                        Create
                      </Button>
                    );
                  },
                },
              ]}
              data={allowedTemplates}
              title="Case Templates"
            />
          </TabPanel>
        </>
      )}
    </div>
  );
}
