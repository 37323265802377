import { gql } from "@apollo/client";
import { Case, CaseAccess } from "../types/case";
import { DOC_TEMPLATE_FIELDS_FRAGMENT } from "./documents";
import { WITH_ERRORS_FRAGMENT, WithErrors } from "./lib/hooks";

/**
 * LOOKUP_CASES returns all the cases that are visible to the current user.
 */
export const LOOKUP_CASES = gql`
  query LookupCases(
    $first: Int!
    $after: String
    $order: String
    $orderBy: String
    $filter: String
  ) {
    authenticatedUser {
      id
      cases(
        first: $first
        after: $after
        filter: $filter
        order: $order
        orderBy: $orderBy
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            templateId
            firmCaseReference
            firmClientName
            title
            color
          }
        }
      }
    }
  }
`;

/**
 * LOOKUP_CASE returns the full metadata for a case, by ID.
 * @returns LookupCaseData
 */
export const LOOKUP_CASE = {
  gql: gql`
    query LookupCaseByID($caseId: ID!) {
      caseById(caseId: $caseId) {
          id
          title
          templateId
          firmCaseReference
          firmClientName
          color
          caseAccess
          isCreator
          hasGeneratedDocuments
          isGptSupported
          currentDatabaseRevision {
            id
            downloadUrl
            updateUrl
            createdAt
            revisionIndex
          }
          firm {
            title
            phoneNumber
            faxNumber
            address
          }
          docTemplates {
            ${DOC_TEMPLATE_FIELDS_FRAGMENT}
          }
      }
    }
  `,
  workingMessage: "Looking up case",
  errorMessage: "Failed to find case",
};

/**
 * LookupCaseData is the data returned from a LOOKUP_CASE call.
 */
export interface LookupCaseData {
  /**
   * caseById contains the case found.
   */
  caseById: Case;
}

/**
 * Mutation: Deletes a case.
 *
 * @param caseId The ID of the case.
 */
export const DELETE_CASE = {
  gql: gql`
    mutation DeleteCase($caseId: ID!) {
      deleteCase(caseId: $caseId) {
        status
      }
    }
  `,
  workingMessage: "Deleting case",
  errorMessage: "Could not delete case",
};

export interface DeleteCaseData {
  caseId: string;
}

/**
 * Mutation: Updates a case.
 *
 * @param caseId The ID of the case.
 * @param title The new title for the case.
 * @param newAccess The new access permission for the case.
 */
export const UPDATE_CASE = {
  gql: gql`
      mutation UpdateCase($caseId: ID!, $title: String!, $firmCaseReference: String!, $firmClientName: String!, $newAccess: CaseAccessStatus) {
        updateCase(caseId: $caseId, title: $title, newAccess: $newAccess, firmCaseReference: $firmCaseReference, firmClientName: $firmClientName) {
            updatedCase {
              id
              firmCaseReference
              firmClientName
              title
              caseAccess
            }
            ${WITH_ERRORS_FRAGMENT}
        }
      }
    `,
  workingMessage: "Updating case title",
  errorMessage: "Failed to update case title",
  refetchQueries: ["LookupCaseByID"],
};

export interface UpdateCaseData {
  caseId: string;
  title: string;
  newAccess?: CaseAccess;
  firmCaseReference: string;
  firmClientName: string;
}

/**
 * Mutation: Creates a case.
 *
 * @param templateId The ID of the template for the case.
 * @param title The title for the case.
 *
 * @returns CreatedCaseData
 */
export const CREATE_CASE = {
  gql: gql`
        mutation CreateCase($templateId: String!, $title: String!, $userOnly: Boolean!) {
          createCase(templateId: $templateId, title: $title, userOnly: $userOnly) {
              createdCase {
                id
              }
              ${WITH_ERRORS_FRAGMENT}
          }
        }
    `,
  workingMessage: "Creating case",
  errorMessage: "Failed to create case",
  refetchQueries: ["LookupCases"],
};

/**
 * The parameters for CREATE_CASE.
 */
export interface CreateCaseData {
  templateId: string;
  title: string;
  userOnly: boolean;
}

export type CreatedCaseData = {
  createCase: { createdCase: Case } & WithErrors;
};
