import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";
import { DatabaseContext } from "../../database/diffable/context";
import { useMutationCallback } from "../../database/diffable/hooks";
import { MissingValueKey } from "../../services/documentservice";
import {
  MissingForValueKey,
  useDocumentTracker,
} from "../documents/DocumentTracker";

interface styleProps {
  missingColor: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    missingMessage: {
      borderRadius: "6px",
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
      backgroundColor: (props: styleProps) => props.missingColor,
      padding: theme.spacing(1),
      display: "inline-block",
      marginBottom: "-4px",
    },
    box: {
      borderStyle: "solid",
      borderColor: (props: styleProps) =>
        props.missingColor ? props.missingColor : "",
      borderWidth: (props: styleProps) => (props.missingColor ? 2 : 0),
      borderRadius: "6px",
    },
    withPadding: {
      padding: theme.spacing(2),
    },
  })
);

export function CustomMissingBorder(
  props: PropsWithChildren<{ bindKey: MissingValueKey; withPadding?: boolean }>
) {
  const { tracker } = useDocumentTracker();
  const database = useContext(DatabaseContext)!;

  const [missingData, setMissingData] = useState<
    MissingForValueKey | undefined
  >(undefined);

  const bindKey = props.bindKey;
  const docCount = tracker.documents().length;
  const recalculate = useCallback(async () => {
    setMissingData(await tracker.missingForValueKey(bindKey));
  }, [tracker, bindKey, docCount]);

  useMutationCallback(database, 100, recalculate);

  const classes = useStyles({ missingColor: missingData?.missingColor ?? "" });

  return (
    <Box className={classes.root}>
      {missingData?.missingMessage !== undefined && !!missingData.isMissing && (
        <span className={classes.missingMessage}>
          {missingData.missingMessage}
        </span>
      )}
      <Box
        className={clsx(classes.box, {
          [classes.withPadding]: props.withPadding,
        })}
      >
        {props.children}
      </Box>
    </Box>
  );
}
