import { MutableRow } from "../../database/diffable/interfaces";
import { sql } from "../../database/sql";
import {
  DataFieldDefinitions,
  FieldKind,
  context,
  error,
  expand,
  field,
  fieldsPerEntry,
  fieldsPerKind,
  fieldsViaMappingProcessor,
  processor,
} from "../../services/datafielddefinitions";
import { TrackedDatabase } from "../../services/documentservice";
import { IsKnownState, StateTitle } from "../../services/states";
import {
  CurrentAddress,
  OptionalAddressFields,
} from "../../shareddata/fields/address";
import { FirmFields } from "../../shareddata/fields/firm";
import { interestForRowOrUndefined } from "../../shareddata/interest";
import {
  combinedCurrency,
  date,
  fullMultilineAddress,
  joinMultiple,
} from "../../shareddata/shareddata";
import { AttorneyLicense } from "../../types/license";
import { BitwiseSet } from "../../util/bitwiseset";
import { enumValues } from "../../util/enum";
import {
  Affiant,
  ContractTerms,
  LitigationCase,
  LitigationCaseParty,
  Motion,
  MotionEntity,
  MotionEntityType,
  Party,
  PartyFormDescription,
  PartyKind,
  PartyKindDescription,
  PleadingAttorney,
  ServiceAddress,
} from "./model";

export const ActiveLitigationCase = processor(
  {
    id: "active_litigation_case",
    description: "The currently selected litigation case",
    missingMessage: "A litigation case should be marked as the active case",
  },
  async ({ ctx }) => {
    const found = ctx.db
      .listMutable<LitigationCase>(sql`select * from ${"litigationcase"}`)
      .find((m) => m.getField("isActive") === 1);
    return found?.row;
  }
);

export const SignerAttorney = processor(
  {
    id: "signer_attorney",
    description: "The attorney selected to be the signer for the case",
    missingMessage:
      "Please select an attorney to be the signer for the pleading",
  },
  async ({ ctx }) => {
    return ctx.db.getOrUndefined<PleadingAttorney>(
      sql`select * from ${"pleadingattorney"} where isSigner=1`
    );
  }
);

const SignerAttorneyDetails = processor(
  {
    id: "signer_attorney_details",
    description: "The member information for the signer attorney",
    dependencies: {
      activeLitigationCase: ActiveLitigationCase,
      signerAttorney: SignerAttorney,
    },
  },
  async ({ ctx, activeLitigationCase, signerAttorney }) => {
    const courtState = activeLitigationCase.state;
    if (!IsKnownState(courtState)) {
      return error(`entered court state ${courtState} is not valid`, {
        tableName: "LitigationCase",
        rowId: activeLitigationCase.id,
        columnName: "state",
      });
    }

    const signerData = await ctx.memberLookup(signerAttorney.userId);
    if (signerData === undefined) {
      return error(
        `signer ${signerAttorney.fullName} is no longer a valid member of the firm`,
        {
          tableName: "LitigationCase",
          rowId: activeLitigationCase.id,
          columnName: "state",
        }
      );
    }

    return signerData;
  }
);

const SignerAttorneyLicenses = processor(
  {
    id: "signer_attorney_licenses",
    description: "The state license(s) for the signer attorney",
    dependencies: {
      activeLitigationCase: ActiveLitigationCase,
      signerAttorneyDetails: SignerAttorneyDetails,
    },
  },
  async ({ signerAttorneyDetails, activeLitigationCase }) => {
    const courtState = activeLitigationCase.state;

    // Ensure the signer has a license in that state.
    const foundLicenses = signerAttorneyDetails.licenses.filter(
      (license: AttorneyLicense) =>
        license.stateId.toLowerCase() === courtState.toLowerCase()
    );

    if (!foundLicenses.length) {
      return error(
        `chosen signer ${
          signerAttorneyDetails.name
        } is not marked as licensed in ${StateTitle(courtState)}`,
        {
          tableName: "LitigationCase",
          rowId: activeLitigationCase.id,
          columnName: "state",
        }
      );
    }
    return foundLicenses;
  }
);

const Attorney_ID = field(
  {
    description: "The ID of the signing attorney for the case",
    example: "123456",
    parameters: {
      licenses: SignerAttorneyLicenses,
    },
  },
  async ({ licenses }) => {
    return licenses[0].attorneyId;
  }
);

const AttorneySignerName = field(
  {
    description: "The full name of the signing attorney for the case",
    example: "John Doe",
    parameters: {
      attorney: SignerAttorneyDetails,
    },
  },
  async ({ attorney }) => {
    return `${attorney.name}, Esquire`;
  }
);

const AttorneySigner = field(
  {
    description: "The full name of the signing attorney, and their ID",
    example: "John Doe (#123456)",
    isHelperField: true,
    parameters: {
      attorney: SignerAttorneyDetails,
      licenses: SignerAttorneyLicenses,
    },
  },
  async ({ attorney, licenses }) => {
    return `${attorney.name}, Esquire (#${licenses[0].attorneyId})`;
  }
);

export const ActiveMotion = processor(
  {
    id: "active_motion",
    description: "The active motion over which to generate documents",
  },
  async ({ ctx }) => {
    const motions = ctx.db.listMutable<Motion>(sql`select * from ${"motion"}`);
    const found = motions.filter(
      (m: MutableRow<Motion>) => m.getField("isActive") === 1
    );
    return found.length === 1 ? found[0].row : undefined;
  }
);

const Complaint_Date = field(
  {
    description: "The complaint date of the active motion",
    kind: FieldKind.Date,
    parameters: {
      motion: ActiveMotion,
    },
  },
  async ({ motion }) => motion.complaintDate
);

const Motion_Kind = field(
  {
    description: "The kind/type of the active motion",
    example: "Motion to Compel Discovery Responses",
    parameters: {
      motion: ActiveMotion,
    },
  },
  async ({ motion }) => motion.kind
);

const Answer_Date = field(
  {
    description: "The answer date of the active motion",
    kind: FieldKind.Date,
    parameters: {
      motion: ActiveMotion,
    },
  },
  async ({ motion }) => motion.answerDate
);

const Discovery_Date = field(
  {
    description: "The discovery date of the active motion",
    kind: FieldKind.Date,
    parameters: {
      motion: ActiveMotion,
    },
  },
  async ({ motion }) => motion.discoveryDate
);

const Discovery_End_Date = field(
  {
    description: "The discovery end date of the active motion",
    kind: FieldKind.Date,
    parameters: {
      motion: ActiveMotion,
    },
  },
  async ({ motion }) => motion.discoveryEndDate
);

const Order_Date = field(
  {
    description: "The order date of the active motion",
    kind: FieldKind.DocumentDate,
    parameters: {
      motion: ActiveMotion,
    },
  },
  async ({ motion }) => motion.orderDate
);

const Movants = processor(
  {
    id: "movants",
    description: "The movants of the active motion",
    dependencies: {
      motion: ActiveMotion,
    },
  },
  async ({ ctx, motion }) => {
    return ctx.db.list<MotionEntity>(
      sql`select * from ${"motionentity"} where type=${
        MotionEntityType.MOVANT
      } and motionId=${motion.id}`
    );
  }
);

const Movant_List = field(
  {
    description: "The movants of the active motion, in comma list form",
    example: "Mr. John Doe, Mr. Jeremy Smith and Ms. Rachel Daze",
    parameters: {
      movants: Movants,
    },
  },
  async ({ movants }) => {
    return joinMultiple(movants, "", "", (item) => {
      return item.name;
    });
  }
);

const Respondents = processor(
  {
    id: "respondents",
    description: "The respondents of the active motion",
    dependencies: {
      motion: ActiveMotion,
    },
  },
  async ({ ctx, motion }) => {
    return ctx.db.list<MotionEntity>(
      sql`select * from ${"motionentity"} where type=${
        MotionEntityType.RESPONDENT
      } and motionId=${motion.id}`
    );
  }
);

const Respondent_List = field(
  {
    description: "The respondents of the active motion, in comma list form",
    example: "Mr. John Doe, Mr. Jeremy Smith and Ms. Rachel Daze",
    parameters: {
      respondents: Respondents,
    },
  },
  async ({ respondents }) => {
    return joinMultiple(respondents, "", "", (item) => {
      return item.name;
    });
  }
);

const Parties = processor(
  {
    id: "parties",
    description: "The parties defined in the overall workspace",
  },
  async ({ ctx }) => {
    return ctx.db.list<Party>(sql`select * from ${"party"}`);
  }
);

const LitigationCaseParties = processor(
  {
    id: "litigation_case_parties",
    description: "The parties of the active litigation case",
    dependencies: {
      activeCase: ActiveLitigationCase,
    },
  },
  async ({ ctx, activeCase }) => {
    return ctx.db.list<LitigationCaseParty>(
      sql`select * from ${"litigationcaseparty"} where caseId=${activeCase.id}`
    );
  }
);

type PartyAndLitigationParty = Party & Exclude<LitigationCaseParty, "id">;

const LitigationCasePartiesByKind = processor(
  {
    id: "litigation_case_parties_kind",
    description: "The parties of the active litigation case, by kind",
    dependencies: {
      parties: Parties,
      caseParties: LitigationCaseParties,
    },
  },
  async ({ parties, caseParties }) => {
    return Object.fromEntries(
      enumValues(PartyKind).map((kind: PartyKind) => {
        if (kind === PartyKind.UNKNOWN) {
          return [];
        }

        const matchingCaseParties = caseParties.filter((lcp) =>
          BitwiseSet.encodedHas(lcp.partyKind, kind)
        );

        const matchingParties: PartyAndLitigationParty[] =
          matchingCaseParties.map((lcp) => {
            const party = parties.find((p) => p.id === lcp.partyId)!;
            return {
              ...party,
              ...lcp,
            };
          });

        return [kind, matchingParties];
      })
    ) as Record<PartyKind, PartyAndLitigationParty[]>;
  }
);

const CurrentParty = context<PartyAndLitigationParty>("party");

const Court_State = field(
  {
    description:
      "The two-letter abbreviation of the state or territory in the United States, of the court holding the active litigation case",
    example: "FL",
    parameters: {
      activeCase: ActiveLitigationCase,
    },
  },
  async ({ activeCase }) => {
    return activeCase.state;
  }
);

const Court_County = field(
  {
    description:
      "The county of the court holding the active litigation case, in the United States",
    example: "Brooklyn",
    parameters: {
      activeCase: ActiveLitigationCase,
    },
  },
  async ({ activeCase }) => {
    return activeCase.county;
  }
);

const Court_Name = field(
  {
    description:
      "The name of the court that is holding the active litigation case",
    example: "Brooklyn Superior Court",
    parameters: {
      activeCase: ActiveLitigationCase,
    },
  },
  async ({ activeCase }) => {
    return activeCase.court;
  }
);

const Docket_Number = field(
  {
    description: "The docket number of the active litigation case in the court",
    example: "Brooklyn Superior Court",
    parameters: {
      activeCase: ActiveLitigationCase,
    },
  },
  async ({ activeCase }) => {
    return activeCase.docketNumber;
  }
);

const Bar_Association = field(
  {
    description: "The bar association of the active litigation case",
    example: "New York State Bar",
    parameters: {
      activeCase: ActiveLitigationCase,
    },
  },
  async ({ activeCase }) => {
    return activeCase.barAssociation;
  }
);

const Party_Name = field(
  {
    description: (sd) => `The full name of ${sd} in the case`,
    example: "John Doe",
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => {
    return party.fullName;
  }
);

const Party_Form = field(
  {
    description: (sd) =>
      `The form of ${sd}, i.e. whether they are an individual or a corporation`,
    example: "Corporation",
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => {
    return PartyFormDescription[party.form];
  }
);

const Party_Short_Name = field(
  {
    description: (sd) => `The short name of ${sd}`,
    example: "John",
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => {
    return party.shortName;
  }
);

const Party_Phone = field(
  {
    description: (sd) => `The phone number of ${sd}`,
    example: "215-555-5555",
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => {
    return party.phoneNumber;
  }
);

const Party_Email = field(
  {
    description: (sd) => `The e-mail address of ${sd}`,
    example: "john@example.com",
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => {
    return party.emailAddress;
  }
);

const Party_DOB_Formation = field(
  {
    description: (sd) => `The formation or birth date of ${sd}`,
    kind: FieldKind.Date,
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => {
    return party.dateOfBirthOrFormation;
  }
);

const Party_Form_State = field(
  {
    description: (sd) =>
      `The two-letter abbreviation of the US State in which ${sd} was formed`,
    example: "CA",
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => {
    return party.formationState;
  }
);

const ServiceAddressesByParty = processor(
  {
    id: "service_addresses_by_party",
    description: "Map of service addresses, by party ID",
    itemDescription: "service address",
  },
  async ({ ctx }) => {
    const serviceAddresses = ctx.db.list<ServiceAddress>(
      sql`select * from ${"serviceaddress"}`
    );
    const serviceAddressByParty: Record<number, ServiceAddress[]> = {};
    serviceAddresses.forEach((sa) => {
      if (!(sa.partyId in serviceAddressByParty)) {
        serviceAddressByParty[sa.partyId] = [];
      }

      serviceAddressByParty[sa.partyId].push(sa);
    });
    return serviceAddressByParty;
  }
);

const PartiesForKind = context<Party[]>("parties_for_kind");

const CurrentAffiant = processor(
  {
    id: "affiant",
    description: "The affiant for the case, if any",
  },
  async ({ ctx }) => {
    return ctx.db.get<Affiant>(sql`select * from ${"affiant"}`);
  }
);

const Affiant_Name = field(
  {
    description: "The name of the affiant for the case",
    parameters: {
      affiant: CurrentAffiant,
    },
    example: "John Doe",
  },
  async ({ affiant }) => {
    return affiant.fullName;
  }
);

const Affiant_Title = field(
  {
    description: "The title of the affiant for the case",
    parameters: {
      affiant: CurrentAffiant,
    },
    example: "Mr.",
  },
  async ({ affiant }) => {
    return affiant.title;
  }
);

const Affiant_Pronoun = field(
  {
    description: "The pronoun of the affiant for the case",
    parameters: {
      affiant: CurrentAffiant,
    },
    example: "he",
  },
  async ({ affiant }) => {
    return affiant.pronoun;
  }
);

const PartiesPerKindList = field(
  {
    description: (sd) => `The names of the ${sd} on the case`,
    parameters: {
      parties: PartiesForKind,
    },
    example: "John Doe and Jane Smith",
  },
  async ({ parties }) => {
    return parties.map((p) => p.fullName).join(" and ");
  }
);

const PartiesPerKindCaption = field(
  {
    description: (sd) => `The caption of the ${sd} on the case`,
    parameters: {
      parties: PartiesForKind,
      activeLitigationCase: ActiveLitigationCase,
    },
    example: "JOHN DOE\n1234 Fake Street\nAND\nJANE SMITH\n4567 SOME PLACE",
  },
  async ({ ctx, parties, activeLitigationCase }) => {
    return listCaption(
      ctx.db,
      parties,
      activeLitigationCase.showAddressesInCaption === 1,
      activeLitigationCase.showOnlyFirstPartyInCaption === 1
    );
  }
);

export const Party_Initial_Service_Date = field(
  {
    description: (sd) =>
      `The initial service date for ${sd} in the litigation case`,
    kind: FieldKind.Date,
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => party.initialServiceDate
);

export const Party_Initial_Response_Due_Date = field(
  {
    description: (sd) =>
      `The initial response date for ${sd} in the litigation case`,
    kind: FieldKind.Date,
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => party.initialResponseDueDate
);

export const Party_Notice_of_Default_Date = field(
  {
    description: (sd) =>
      `The notice of default date for ${sd} in the litigation case`,
    kind: FieldKind.Date,
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => party.noticeOfDefaultDate
);

export const Party_Judgment_Date = field(
  {
    description: (sd) => `The judgment date for ${sd} in the litigation case`,
    kind: FieldKind.Date,
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => party.judgementDate
);

export const Party_Complaint_Amount = field(
  {
    description: (sd) =>
      `The complaint amount for ${sd} in the litigation case`,
    kind: FieldKind.Currency,
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => party.complaintAmount
);

export const Party_Ongoing_Amounts = field(
  {
    description: (sd) => `The ongoing amounts for ${sd} in the litigation case`,
    kind: FieldKind.Currency,
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => party.ongoingAmounts
);

export const Party_Additional_Attorneys_Fees = field(
  {
    description: (sd) =>
      `The additional attorneys fees for ${sd} in the litigation case`,
    kind: FieldKind.Currency,
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => party.additionalAttorneysFees
);

export const Party_Judgment_Total = field(
  {
    description: (sd) =>
      `The total judgment amount for ${sd} in the litigation case`,
    kind: FieldKind.Currency,
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) =>
    combinedCurrency([
      party.complaintAmount,
      party.ongoingAmounts,
      party.additionalAttorneysFees,
    ])
);

export const Party_Writ_Sheriff = field(
  {
    description: (sd) =>
      `The county of the sheriff for which the writ is being issued, for ${sd} in the litigation case`,
    example: "Montgomery County",
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => party.writSheriff
);

export const Party_Writ_Principal = field(
  {
    description: (sd) =>
      `The principal amount for the writ for ${sd} in the litigation case`,
    kind: FieldKind.Currency,
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => party.writPrincipal
);

export const Party_Writ_Interest_Rate = field(
  {
    description: (sd) =>
      `The interest rate for the writ for ${sd} in the litigation case`,
    kind: FieldKind.Percentage,
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => party.writInterestRate
);

export const Party_Writ_Interest_End = field(
  {
    description: (sd) =>
      `The end date for interest on the writ for ${sd} in the litigation case`,
    kind: FieldKind.Date,
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => party.writInterestEndDate
);

export const Party_Writ_Interest = field(
  {
    description: (sd) =>
      `The interest on the writ for ${sd} in the litigation case`,
    kind: FieldKind.Currency,
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => party.writInterest
);

export const Party_Writ_Costs = field(
  {
    description: (sd) =>
      `The costs for the writ for ${sd} in the litigation case`,
    kind: FieldKind.Currency,
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => party.writCosts
);

export const Party_Writ_Fees = field(
  {
    description: (sd) =>
      `The fees for the writ for ${sd} in the litigation case`,
    kind: FieldKind.Currency,
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) => party.writFees
);

export const Party_Writ_Total = field(
  {
    description: (sd) =>
      `The total dollar amount for the writ for ${sd} in the litigation case`,
    kind: FieldKind.Currency,
    parameters: {
      party: CurrentParty,
    },
  },
  async ({ party }) =>
    combinedCurrency([
      party.writPrincipal,
      party.writInterest,
      party.writCosts,
      party.writFees,
    ])
);

function listCaption(
  db: TrackedDatabase,
  matchingParties: Party[],
  showAddressesInCaption: boolean,
  showOnlyFirstPartyInCaption: boolean
): string {
  const filteredParties = matchingParties.filter(
    (p, index) => !showOnlyFirstPartyInCaption || index === 0
  );

  if (filteredParties.length === 0) {
    return "";
  }

  return `${filteredParties
    .map((mp) => {
      if (!showAddressesInCaption) {
        return mp.fullName.toUpperCase();
      }

      return `${mp.fullName.toUpperCase()}\n${fullMultilineAddress(mp, db)}`;
    })
    .join("\nAND\n")}${
    showOnlyFirstPartyInCaption && matchingParties.length > 0 ? " ET AL." : ""
  }`;
}

const ContractForCurrentParty = processor(
  {
    id: "contract_for_current_party",
    description: "The contract for the current party, if any",
    dependencies: {
      party: CurrentParty,
    },
  },
  async ({ ctx, party }) => {
    return party.contractId > 0
      ? ctx.db.getOrUndefined<ContractTerms>(
          sql`select * from ${"contractterms"} where id=${party.contractId}`
        )
      : undefined;
  }
);

const Contract_Title = field(
  {
    description: (sd) => `The title of the contract for ${sd}`,
    example: "A contract",
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => contract.title
);

const Contract_Short_Title = field(
  {
    description: (sd) => `The short title of the contract for ${sd}`,
    example: "Contract",
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => contract.shortTitle
);

const Contract_Date = field(
  {
    description: (sd) => `The date of the contract ${sd}`,
    kind: FieldKind.Date,
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => contract.date
);

const Contract_Amendments = field(
  {
    description:
      "Text containing the contract's title, its date, and whether it has been amended",
    example: "The contract dated 1/1/2023, as amended",
    isHelperField: true,
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => {
    if (contract.amendments) {
      return `${contract.title} dated ${date(contract.date)}, as amended`;
    }

    return `${contract.title} dated ${date(contract.date)}`;
  }
);

const Contract_And_Amendments = field(
  {
    description:
      "The amendments of the contract for the current party, if any.",
    example: "amended as such: this is the amendment",
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => contract.amendments
);

const Contract_Principal_Amount_Due = field(
  {
    description: (sd) => `The principal amount due for the contract for ${sd}`,
    kind: FieldKind.Currency,
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => contract.principalAmountDue
);

const Contract_Principal_Amount_Due_Description = field(
  {
    description: (sd) =>
      `The description of the principal amount due for the contract for ${sd}`,
    example: "Paid for services rendered",
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => contract.principalAmountDueDescription
);

const Contract_Interest_Rate = field(
  {
    description: (sd) => `The interest rate for the contract for ${sd}`,
    kind: FieldKind.Percentage,
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => contract.interestRate
);

const Contract_Interest_Rate_Basis = field(
  {
    description: (sd) =>
      `The basis of the interest rate for the contract for ${sd}`,
    example: "Some basis",
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => contract.interestRateBasis
);

const Contract_Interest_Start_Date = field(
  {
    description: (sd) =>
      `The start date of the interest accumulated on the contract for ${sd}`,
    kind: FieldKind.Date,
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => contract.interestStartDate
);

const Contract_Interest_End_Date = field(
  {
    description: (sd) =>
      `The end date of the interest accumulated on the contract for ${sd}`,
    kind: FieldKind.Date,
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => contract.interestEndDate
);

const Contract_Interest_Total = field(
  {
    description: (sd) =>
      `The total of the interest accumulated on the contract for ${sd}`,
    kind: FieldKind.Currency,
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => interestForRowOrUndefined(contract)?.interestTotal
);

const Contract_Late_Fee = field(
  {
    description: (sd) => `The late fee on the contract for ${sd}`,
    kind: FieldKind.Currency,
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => contract.lateFee
);

const Contract_Late_Fee_Basis = field(
  {
    description: (sd) => `The basis of the late fee for the contract for ${sd}`,
    example: "Some basis",
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => contract.lateFeeBasis
);

const Contract_Late_Fee_Provision = field(
  {
    description:
      "The provisions/terms of the late fee for the contract for the current party",
    example: "Some term",
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => contract.lateFeeTerm
);

const Contract_Attorneys_Fee = field(
  {
    description: "The attorney's fee for the contract for the current party",
    kind: FieldKind.Currency,
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => contract.attorneysFee
);

const Contract_Attorneys_Fee_Basis = field(
  {
    description:
      "The basis of the attorney's fee for the contract for the current party",
    example: "Some basis",
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => contract.attorneysFeeBasis
);

const Contract_Other_Damages = field(
  {
    description: (sd) => `The other damages added to the contract for ${sd}`,
    kind: FieldKind.Currency,
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => contract.otherDamages
);

const Contract_Other_Damages_Basis = field(
  {
    description: (sd) =>
      `The basis of the added damages added to  the contract for ${sd}`,
    example: "Some basis",
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => contract.otherDamagesBasis
);

const Contract_Other_Damages_Description = field(
  {
    description: (sd) =>
      `The description of the added damages added to  the contract for ${sd}`,
    example: "Added because of this reason",
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => contract.otherDamagesDescription
);

const Contract_Other_Relief = field(
  {
    description: (sd) => `The other relief added to  the contract for ${sd}`,
    example: "The other relief added",
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ contract }) => contract.otherRelief
);

const Contract_Total_Amount_Due = field(
  {
    description: (sd) => `The total amount due for the contract for ${sd}`,
    kind: FieldKind.Currency,
    parameters: {
      contract: ContractForCurrentParty,
    },
  },
  async ({ ctx, contract }) => {
    const interestTotal = interestForRowOrUndefined(contract)?.interestTotal;
    return combinedCurrency([
      interestTotal,
      contract.principalAmountDue,
      ctx.db.optional(contract).lateFee,
      contract.attorneysFee,
      ctx.db.optional(contract).otherDamages,
    ]);
  }
);

const Jury_Trial = field(
  {
    description:
      "Whether a jury trial was requested for the active litigation case",
    example: "Jury Trial Demanded",
    parameters: {
      activeLitigationCase: ActiveLitigationCase,
    },
  },
  async ({ activeLitigationCase }) =>
    activeLitigationCase.isJuryTrial ? "Jury Trial Demanded" : ""
);

export const LitigationDataFields: DataFieldDefinitions = {
  // Attorney
  Attorney_ID: Attorney_ID,
  AttorneySignerName: AttorneySignerName,
  AttorneySigner: AttorneySigner,
  AttorneyFilers: "AttorneySigner",

  // Firm.
  ...FirmFields,

  // Motion.
  Motion_Kind: Motion_Kind,
  Complaint_Date: Complaint_Date,
  Answer_Date: Answer_Date,
  Discovery_Date: Discovery_Date,
  Discovery_End_Date: Discovery_End_Date,
  Order_Date: Order_Date,
  Movant_List: Movant_List,
  Respondent_List: Respondent_List,

  // Active case.
  Court_State: Court_State,
  Court_County: Court_County,
  Court_Name: Court_Name,
  Docket_Number: Docket_Number,
  Bar_Association: Bar_Association,
  Jury_Trial: Jury_Trial,

  // Active case parties.
  ...fieldsPerKind(
    PartyKindDescription,
    LitigationCasePartiesByKind,
    PartiesForKind,
    {
      List: PartiesPerKindList,
      List_Caption: PartiesPerKindCaption,

      ...fieldsPerEntry(PartiesForKind, [CurrentParty, CurrentAddress], {
        "": Party_Name,

        Name: Party_Name,
        Form: Party_Form,
        Short_Name: Party_Short_Name,
        Phone: Party_Phone,
        Email: Party_Email,
        DOB_Formation: Party_DOB_Formation,
        Form_State: Party_Form_State,

        // Address for the party.
        ...OptionalAddressFields,

        Initial_Service_Date: Party_Initial_Service_Date,
        Initial_Response_Due_Date: Party_Initial_Response_Due_Date,
        Notice_of_Default_Date: Party_Notice_of_Default_Date,
        Judgment_Date: Party_Judgment_Date,

        Complaint_Amount: Party_Complaint_Amount,
        Ongoing_Amounts: Party_Ongoing_Amounts,
        Additional_Attorneys_Fees: Party_Additional_Attorneys_Fees,
        Judgment_Total: Party_Judgment_Total,

        Writ_Sheriff: Party_Writ_Sheriff,
        Writ_Principal: Party_Writ_Principal,
        Writ_Interest_Rate: Party_Writ_Interest_Rate,
        Writ_Interest_End: Party_Writ_Interest_End,
        Writ_Interest: Party_Writ_Interest,
        Writ_Costs: Party_Writ_Costs,
        Writ_Fees: Party_Writ_Fees,
        Writ_Total: Party_Writ_Total,

        // Contract for party.
        Contract: expand({
          Title: Contract_Title,
          Short_Title: Contract_Short_Title,
          Date: Contract_Date,
          Amendments: Contract_Amendments,
          And_Amendments: Contract_And_Amendments,
          Principal_Amount_Due: Contract_Principal_Amount_Due,
          Principal_Amount_Due_Description:
            Contract_Principal_Amount_Due_Description,
          Interest_Rate: Contract_Interest_Rate,
          Interest_Rate_Basis: Contract_Interest_Rate_Basis,
          Interest_Start_Date: Contract_Interest_Start_Date,
          Interest_End_Date: Contract_Interest_End_Date,
          Interest_Total: Contract_Interest_Total,
          Late_Fee: Contract_Late_Fee,
          Late_Fee_Basis: Contract_Late_Fee_Basis,
          Late_Fee_Provision: Contract_Late_Fee_Provision,
          Attorneys_Fee: Contract_Attorneys_Fee,
          Attorneys_Fee_Basis: Contract_Attorneys_Fee_Basis,
          Other_Damages: Contract_Other_Damages,
          Other_Damages_Basis: Contract_Other_Damages_Basis,
          Other_Relief: Contract_Other_Relief,
          Other_Damages_Description: Contract_Other_Damages_Description,
          Total_Amount_Due: Contract_Total_Amount_Due,
        }),

        Principal_Amount_Due: "Contract_Principal_Amount_Due",
        Principal_Amount_Due_Description:
          "Contract_Principal_Amount_Due_Description",
        Interest_Rate: "Contract_Interest_Rate",
        Interest_Rate_Basis: "Contract_Interest_Rate_Basis",
        Interest_Start_Date: "Contract_Interest_Start_Date",
        Interest_End_Date: "Contract_Interest_End_Date",
        Interest_Total: "Contract_Interest_Total",
        Late_Fee: "Contract_Late_Fee",
        Late_Fee_Basis: "Contract_Late_Fee_Basis",
        Late_Fee_Provision: "Contract_Late_Fee_Provision",
        Attorneys_Fee: "Contract_Attorneys_Fee",
        Attorneys_Fee_Basis: "Contract_Attorneys_Fee_Basis",
        Other_Damages: "Contract_Other_Damages",
        Other_Damages_Basis: "Contract_Other_Damages_Basis",
        Other_Relief: "Contract_Other_Relief",
        Other_Damages_Description: "Contract_Other_Damages_Description",
        Total_Amount_Due: "Contract_Total_Amount_Due",

        // Service Addresses
        Service_Address: fieldsViaMappingProcessor(
          CurrentParty,
          ServiceAddressesByParty,
          CurrentAddress,
          {
            ...OptionalAddressFields,
          }
        ),
      }),
    }
  ),

  // Affiant.
  Affiant_Name: Affiant_Name,
  Affiant_Title: Affiant_Title,
  Affiant_Pronoun: Affiant_Pronoun,
};
