import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import NotchedOutline from "@material-ui/core/OutlinedInput/NotchedOutline";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import React, { ChangeEvent, useContext, useState } from "react";
import InputMask from "react-input-mask";
import "react-phone-input-2/lib/plain.css";
import { ResultContext } from "../../database/diffable/context";
import { useDocumentTracker } from "../documents/DocumentTracker";
import { ValueFieldProps } from "./valuefield";

interface styleProps {
  missingColor: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    normal: {
      position: "relative",
      "& > fieldset": {
        borderColor: (props: styleProps) =>
          props.missingColor ? props.missingColor : "",
        borderWidth: (props: styleProps) => (props.missingColor ? 2 : 1),
      },
      "&:hover > fieldset": {
        borderColor: theme.palette.text.primary,
      },
    },
    editing: {
      "& > fieldset": {
        borderColor: theme.palette.primary.main + " !important",
        borderWidth: "2px !important",
      },
    },
    edited: {
      "& > fieldset": {
        borderColor: theme.palette.warning.main,
        borderWidth: 2,
      },
    },
    outline: {
      borderColor:
        theme.palette.type === "light"
          ? "rgba(0, 0, 0, 0.23)"
          : "rgba(255, 255, 255, 0.23)",
      borderRadius: 4,
    },
    control: {
      padding: theme.spacing(1.5),
    },
    field: {
      borderColor: "transparent",
    },
  })
);

export function SSNField(props: ValueFieldProps) {
  const row = useContext(ResultContext)!;
  const { tracker } = useDocumentTracker();

  const [localIndex, setLocalIndex] = useState(0);

  const fallbackValue = props.fallbackColumn
    ? row.getField(props.fallbackColumn)
    : "";
  const value = row.getField(props.column) || fallbackValue;

  const setValue = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    row.setField(
      props.column,
      { value: value, isValid: value.indexOf("_") < 0 },
      {
        title: props.title ?? "",
      }
    );

    setLocalIndex(localIndex + 1);
  };

  const { missingColor } = tracker.checkValueIsMissingForField(
    value,
    row,
    props.column
  );
  const classes = useStyles({
    missingColor: missingColor,
  });

  return (
    <FormControl fullWidth={props.fullWidth}>
      <div
        className={clsx(classes.normal, {
          [classes.edited]: row.hasMutatedValue(props.column),
        })}
      >
        <InputLabel
          style={{ position: "absolute" }}
          shrink={true}
          variant="outlined"
        >
          {props.placeholder ?? props.title}
        </InputLabel>
        <NotchedOutline
          className={classes.outline}
          labelWidth={(props.title ?? "").length * 14}
          /*label={props.title}*/ notched={true}
        />
        <div className={classes.control}>
          <InputMask
            value={value}
            onChange={setValue}
            mask="999-99-9999"
            disabled={false}
          >
            {() => <TextField className={classes.field} />}
          </InputMask>
        </div>
      </div>
    </FormControl>
  );
}
