import { faCog, faUserLock, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Theme, createStyles, makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  DELETE_CASE,
  DeleteCaseData,
  UPDATE_CASE,
  UpdateCaseData,
} from "../queries/case";
import { useManagedMutation } from "../queries/lib/hooks";
import { Case, CaseAccess } from "../types/case";
import { useConfirmDialog } from "./ConfirmDialogProvider";
import { CustomMissingBorder } from "./fields/CustomMissingBorder";

export const MISSING_FIRM_CASE_REFERENCE_KEY = "firm-case-reference";
export const MISSING_FIRM_CLIENT_NAME_KEY = "firm-client-name";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
    },
    header: {
      marginBottom: theme.spacing(1.5),
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
    buttonBar: {
      display: "flex",
      marginBottom: theme.spacing(1),
      "& button": {
        marginRight: theme.spacing(1),
      },
    },
    formControl: {
      marginTop: theme.spacing(2),
    },
    selectItem: {
      display: "flex",
      alignItems: "center",
    },
    itemAvatar: {
      display: "flex",
      marginRight: theme.spacing(1),
      alignItems: "center",
      justifyContent: "center",
    },
    protected: {
      backgroundColor: theme.palette.warning.main,
    },
    private: {
      backgroundColor: theme.palette.error.main,
    },
  })
);

export function SettingsPane(props: { case: Case }) {
  const history = useHistory();
  const [newTitle, setNewTitle] = useState(props.case.title);
  const [newCaseAccess, setNewCaseAccess] = useState(props.case.caseAccess);
  const [newReference, setNewReference] = useState(
    props.case.firmCaseReference
  );
  const [newClientName, setNewClientName] = useState(props.case.firmClientName);

  const { showConfirm } = useConfirmDialog();

  const [updateCase, { loading: saving }] = useManagedMutation<
    any,
    UpdateCaseData
  >(UPDATE_CASE);

  const [deleteCase, { loading: deleting }] = useManagedMutation<
    any,
    DeleteCaseData
  >(DELETE_CASE);

  const handleReferenceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value.length <= 128) {
      setNewReference(event.target.value);
    }
  };

  const handleClientNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value.length <= 128) {
      setNewClientName(event.target.value);
    }
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 128) {
      setNewTitle(event.target.value);
    }
  };

  const handleSaveChanges = () => {
    (async () => {
      await updateCase({
        variables: {
          caseId: props.case.id,
          title: newTitle,
          newAccess: newCaseAccess,
          firmCaseReference: newReference,
          firmClientName: newClientName,
        },
      });
    })();
  };

  const askDeletCase = () => {
    (async () => {
      const [result] = await showConfirm({
        title: "Delete this case?",
        content: (
          <div>
            Are you <strong>absolutely sure</strong> you want to delete case{" "}
            {props.case.title}?{" "}
            <div>
              <strong>This action cannot be undone!</strong>
            </div>
          </div>
        ),
        buttons: [
          {
            color: "default",
            variant: "text",
            title: "Cancel",
            value: undefined,
          },
          {
            color: "secondary",
            variant: "contained",
            title: `Delete Case ${props.case.title}`,
            value: "delete",
          },
        ],
      });

      if (result !== "delete") {
        return;
      }

      await deleteCase({
        variables: {
          caseId: props.case.id,
        },
      });

      history.push("/");
    })();
  };

  const classes = useStyles();
  const hasChanges =
    (newTitle &&
      (newTitle !== props.case.title ||
        newCaseAccess !== props.case.caseAccess ||
        newReference !== props.case.firmCaseReference)) ||
    newClientName !== props.case.firmClientName;
  return (
    <div>
      <Typography className={classes.header} variant="h5">
        <FontAwesomeIcon icon={faCog} size="lg" />
        Case Settings
      </Typography>
      <Paper className={classes.paper}>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            label="Case Title"
            fullWidth
            value={newTitle}
            onChange={handleTitleChange}
          />
        </FormControl>

        <CustomMissingBorder bindKey={MISSING_FIRM_CASE_REFERENCE_KEY}>
          <TextField
            className={classes.formControl}
            variant="outlined"
            label="Firm Case Reference/Number"
            fullWidth
            value={newReference}
            onChange={handleReferenceChange}
          />
        </CustomMissingBorder>

        <CustomMissingBorder bindKey={MISSING_FIRM_CLIENT_NAME_KEY}>
          <TextField
            className={classes.formControl}
            variant="outlined"
            label="Client Name"
            fullWidth
            value={newClientName}
            onChange={handleClientNameChange}
          />
        </CustomMissingBorder>

        <FormControl fullWidth className={classes.formControl}>
          <InputLabel>Case Visible To:</InputLabel>
          <Select
            value={newCaseAccess}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
              setNewCaseAccess(event.target.value as CaseAccess)
            }
            disabled={!props.case.isCreator}
          >
            <MenuItem value={CaseAccess.FIRM_WIDE}>
              <div className={classes.selectItem}>
                <Avatar className={clsx(classes.itemAvatar, classes.protected)}>
                  <FontAwesomeIcon icon={faUsers} />
                </Avatar>
                All members of the firm
              </div>
            </MenuItem>
            <MenuItem value={CaseAccess.RESTRICTED}>
              <div className={classes.selectItem}>
                <Avatar className={clsx(classes.itemAvatar, classes.private)}>
                  <FontAwesomeIcon icon={faUserLock} />
                </Avatar>{" "}
                Just You
              </div>
            </MenuItem>
          </Select>
        </FormControl>
      </Paper>
      <div className={classes.buttonBar}>
        <Button
          disabled={!hasChanges || saving || deleting}
          variant="contained"
          color="primary"
          onClick={handleSaveChanges}
        >
          Save Changes
        </Button>
        <Button
          disabled={saving || deleting}
          variant="outlined"
          color="secondary"
          onClick={askDeletCase}
        >
          Delete Case
        </Button>
      </div>
    </div>
  );
}
