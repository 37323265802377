import {
  faAddressBook,
  faExclamationCircle,
  faFileContract,
  faFileInvoice,
  faFileInvoiceDollar,
  faGavel,
  faHouseUser,
  faMailBulk,
  faSignature,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import {
  MISSING_SIGNER_KEY,
  PleadingAttorneyManager,
} from "../../components/PleadingAttorneyManager";
import {
  AddressEditorField,
  AddressField,
} from "../../components/fields/AddressField";
import { AutocompleteField } from "../../components/fields/AutocompleteField";
import { BooleanField } from "../../components/fields/BooleanField";
import { BooleanFieldDisplay } from "../../components/fields/BooleanFieldDisplay";
import { CollapseField } from "../../components/fields/CollapseField";
import { CurrencyField } from "../../components/fields/CurrencyField";
import { CustomMissingBorder } from "../../components/fields/CustomMissingBorder";
import { DateField } from "../../components/fields/DateField";
import { DocumentDateField } from "../../components/fields/DocumentDateField";
import { EnumField } from "../../components/fields/EnumField";
import { ListField } from "../../components/fields/ListField";
import { PercentageField } from "../../components/fields/PercentageField";
import { RadioBooleanField } from "../../components/fields/RadioBooleanField";
import { StateField } from "../../components/fields/StateField";
import { ValueField } from "../../components/fields/valuefield";
import {
  MutableRowRenderer,
  RowRenderer,
} from "../../database/diffable/components";
import {
  DiffableDatabase,
  MutableRow,
} from "../../database/diffable/interfaces";
import { sql } from "../../database/sql";
import { currency } from "../../shareddata/shareddata";
import { Case } from "../../types/case";
import { Panel, useFlowStyles } from "../uibuilder";
import {
  Address,
  AddressKind,
  Affiant,
  Commission,
  CommissionKind,
  Court,
  Entity,
  EntityKind,
  EntityType,
  EntityTypeDescription,
  Guaranty,
  Interest,
  InterestBasis,
  InterestBasisDescription,
  InterestKind,
  Lease,
  LeaseChange,
  LeaseChangeDescription,
  Litigation,
  Motion,
  MotionEntity,
  MotionEntityType,
  MotionEntityTypeDescription,
  MotionKind,
  MotionKindDescription,
  OverdueNotice,
  ServiceAddress,
} from "./model";
import { loadAddressForEntity } from "./util";

export const MISSING_ACTIVE_MOTION_KEY = "realestate_motion";

export const RealEstatePanels: Panel[] = [
  {
    path: "landlord",
    title: "Landlord",
    icon: faUserCircle,
    renderer: LandlordEditor,
    tableBindings: [
      sql`select * from ${"entity"} where kind=${EntityKind.LANDLORD}`,
      sql`select * from ${"address"} where kind=${AddressKind.LANDLORD}`,
      sql`select * from ${"address"} where kind=${AddressKind.LANDLORD_NOTICE}`,
    ],
  },
  {
    path: "tenant",
    title: "Tenant",
    icon: faHouseUser,
    renderer: TenantEditor,
    tableBindings: [
      sql`select * from ${"entity"} where kind=${EntityKind.TENANT}`,
      sql`select * from ${"entity"} where kind=${EntityKind.GUARANTOR}`,
      sql`select * from ${"address"} where kind=${AddressKind.TENANT}`,
      sql`select * from ${"address"} where kind=${AddressKind.GUARANTOR}`,
    ],
  },
  {
    path: "lease",
    title: "Lease",
    icon: faFileContract,
    renderer: LeaseEditor,
    tableBindings: [
      sql`select * from ${"commission"} where kind=${CommissionKind.LEASE}`,
      sql`select * from ${"address"} where kind=${AddressKind.LEASE_BUILDING}`,
      sql`select * from ${"interest"} where kind=${InterestKind.LEASE}`,
    ],
  },
  {
    path: "damages",
    title: "Guaranty",
    icon: faFileInvoiceDollar,
    renderer: DamagesEditor,
    tableBindings: [
      sql`select * from ${"commission"} where kind=${CommissionKind.GUARANTY}`,
      sql`select * from ${"interest"} where kind=${InterestKind.GUARANTY}`,
    ],
  },
  {
    path: "caption",
    title: "Caption",
    icon: faFileInvoice,
    renderer: CaptionDisplay,
  },
  {
    path: "notice",
    title: "Notice",
    icon: faAddressBook,
    renderer: NoticeEditor,
    tableBindings: [
      sql`select * from ${"address"} where kind=${AddressKind.TENANT_NOTICE}`,
      sql`select * from ${"address"} where kind=${
        AddressKind.GUARANTOR_NOTICE
      }`,
    ],
  },
  {
    path: "serviceaddress",
    title: "Service",
    icon: faMailBulk,
    renderer: ServiceEditor,
  },
  {
    path: "motion",
    title: "Motions",
    icon: faExclamationCircle,
    renderer: MotionEditor,
    definedValueKeys: [MISSING_ACTIVE_MOTION_KEY],
  },
  {
    path: "litigation",
    title: "Litigation",
    icon: faGavel,
    renderer: LitigationEditor,
  },
  {
    path: "affiant",
    title: "Affiant",
    icon: faSignature,
    renderer: AffiantEditor,
    definedValueKeys: [MISSING_SIGNER_KEY],
  },
];

function TenantEditor(props: { case: Case }) {
  return (
    <MutableRowRenderer<Entity>
      query={sql`select * from ${"entity"} where kind=${EntityKind.TENANT}`}
    >
      {(mutableRow: MutableRow<Entity>) => (
        <TenantView mutableRow={mutableRow} case={props.case} />
      )}
    </MutableRowRenderer>
  );
}

function LandlordEditor(props: { case: Case }) {
  return (
    <RowRenderer<Entity>
      query={sql`select * from ${"entity"} where kind=${EntityKind.LANDLORD}`}
    >
      {(row: Entity) => <LandlordView case={props.case} />}
    </RowRenderer>
  );
}

function LandlordView(props: { case: Case }) {
  const classes = useFlowStyles();
  return (
    <div>
      <Typography className={classes.header} variant="h5">
        <FontAwesomeIcon icon={faUserCircle} size="lg" />
        Landlord Information
      </Typography>
      <Paper className={classes.paper}>
        <fieldset className={classes.fieldset}>
          <EntityView title="Landlord" />
          <AddressField fullWidth column="addressId" title="Landlord Address" />
        </fieldset>
      </Paper>
    </div>
  );
}

function TenantView(props: { mutableRow: MutableRow<Entity>; case: Case }) {
  const classes = useFlowStyles();
  const [hasGuarantor, setHasGuarantor] = useState(
    props.mutableRow.getField("hasGuarantor") === 1
  );
  return (
    <div>
      <Typography className={classes.header} variant="h5">
        <FontAwesomeIcon icon={faHouseUser} size="lg" />
        Tenant {hasGuarantor ? " and Guarantor" : ""} Information
      </Typography>
      <Paper className={classes.paper}>
        <fieldset className={classes.fieldset}>
          <EntityView title="Tenant" />
          <AddressField fullWidth column="addressId" title="Tenant Address" />
          <BooleanField
            fullWidth
            column="hasGuarantor"
            title="Has a Guarantor"
            onChange={setHasGuarantor}
          />
        </fieldset>
      </Paper>
      {hasGuarantor && (
        <RowRenderer<Entity>
          query={sql`select * from ${"entity"} where kind=${
            EntityKind.GUARANTOR
          }`}
        >
          {(row: Entity) => {
            return (
              <Paper className={classes.paper}>
                <Typography className={classes.header} variant="h6">
                  Guarantor Information
                </Typography>
                <fieldset className={classes.fieldset}>
                  <EntityView title="Guarantor" />
                  <AddressField
                    fullWidth
                    column="addressId"
                    title="Guarantor Address"
                  />
                </fieldset>
              </Paper>
            );
          }}
        </RowRenderer>
      )}
    </div>
  );
}

function DamagesEditor(props: { case: Case }) {
  const classes = useFlowStyles();
  return (
    <div>
      <Typography className={classes.header} variant="h5">
        <FontAwesomeIcon icon={faFileInvoiceDollar} size="lg" />
        Guaranty Information
      </Typography>

      <RowRenderer<Guaranty> query={sql`select * from ${"guaranty"} limit 1`}>
        {(row: Guaranty) => {
          return <GuarantyEditor case={props.case} row={row} />;
        }}
      </RowRenderer>
    </div>
  );
}

function GuarantyEditor(props: { case: Case; row: Guaranty }) {
  const classes = useFlowStyles();
  const [isDifferentFromLease, setIsDifferentFromLease] = useState(
    props.row.isDifferentFromLease === 1
  );
  const copyLeaseDate = (database: DiffableDatabase) => {
    return database.selectAllResults<Lease>(sql`select * from ${"lease"}`)[0]
      .row.date;
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <fieldset className={classes.fieldset}>
          <ValueField
            fullWidth
            column="shortName"
            title="Guaranty Short Name"
          />
          <ValueField fullWidth column="document" title="Guaranty Title" />
          <DateField
            fullWidth
            column="date"
            title="Guaranty Date"
            copyFromTitle="Copy From Lease"
            copyFrom={copyLeaseDate}
          />
          <BooleanField
            fullWidth
            column="isDifferentFromLease"
            title="Damages Different from Lease"
            onChange={setIsDifferentFromLease}
          />
          {isDifferentFromLease && (
            <>
              <CollapseField<Guaranty>
                fullWidth={true}
                title={"Rent Due"}
                summarizeIf={["rentDue", "rentDueThrough"]}
                summarize={(r: MutableRow<Guaranty>) =>
                  `${
                    r.getField("rentDue")
                      ? currency(r.getField("rentDue"))
                      : "(Unspecified)"
                  } due through ${
                    r.getField("rentDueThrough") || "(Unspecified)"
                  }`
                }
              >
                <CurrencyField
                  fullWidth
                  column="rentDue"
                  title="Rent Amount Due"
                />
                <DateField
                  fullWidth
                  column="rentDueThrough"
                  title="Rent Due Through"
                />
              </CollapseField>
              <InterestField
                kind={InterestKind.GUARANTY}
                title="Guaranty Interest"
              />
              <CommissionField
                kind={CommissionKind.GUARANTY}
                title="Attorney Guaranty Commission"
              />
            </>
          )}
        </fieldset>
      </Paper>
    </div>
  );
}

function LeaseEditor(props: { case: Case }) {
  const classes = useFlowStyles();
  return (
    <div>
      <Typography className={classes.header} variant="h5">
        <FontAwesomeIcon icon={faFileContract} size="lg" />
        Lease Information
      </Typography>

      <RowRenderer<Lease> query={sql`select * from ${"lease"} limit 1`}>
        {(row: Lease) => {
          return (
            <div>
              <Paper className={classes.paper}>
                <fieldset className={classes.fieldset}>
                  <ValueField
                    fullWidth
                    column="shortName"
                    title="Lease Short Name"
                  />
                  <ValueField fullWidth column="title" title="Lease Title" />
                  <DateField fullWidth column="date" title="Lease Date" />
                  <EnumField<LeaseChange>
                    values={LeaseChangeDescription}
                    fullWidth
                    column="changes"
                    title="Lease Changes"
                  />
                </fieldset>
              </Paper>

              <Paper className={classes.paper}>
                <Typography className={classes.header} variant="h6">
                  Premises Information
                </Typography>
                <fieldset className={classes.fieldset}>
                  <ValueField
                    fullWidth
                    column="buildingShortName"
                    title="Building Short Name"
                  />
                  <AddressField
                    fullWidth
                    column="buildingAddressId"
                    title="Building Address"
                    copyFromTitle="Copy From Tenant"
                    copyFrom={(database: DiffableDatabase) =>
                      loadAddressForEntity(database, EntityKind.TENANT)
                    }
                  />
                  <ValueField
                    fullWidth
                    column="premisesShortName"
                    title="Premises Short Name"
                  />
                  <ValueField
                    fullWidth
                    multiline
                    column="premisesDescription"
                    title="Premises Description"
                  />
                </fieldset>
              </Paper>

              <Paper className={classes.paper}>
                <Typography className={classes.header} variant="h6">
                  Lease Default and Cure Details
                </Typography>
                <fieldset className={classes.fieldset}>
                  <DateField
                    fullWidth
                    column="defaultNoticeDate"
                    title="Default Notice Date"
                  />
                  <CollapseField<Lease>
                    fullWidth={true}
                    title={"Rent Due"}
                    summarizeIf={["rentDue", "rentDueThrough", "ongoingRent"]}
                    summarize={(r: MutableRow<Lease>) =>
                      `${
                        r.getField("rentDue")
                          ? currency(r.getField("rentDue"))
                          : "(Unspecified)"
                      } due through ${
                        r.getField("rentDueThrough") || "(Unspecified)"
                      } with ongoing rent ${
                        r.getField("ongoingRent")
                          ? currency(r.getField("ongoingRent"))
                          : "(Unspecified)"
                      }`
                    }
                  >
                    <CurrencyField
                      fullWidth
                      column="rentDue"
                      title="Rent Amount Due"
                    />
                    <DateField
                      fullWidth
                      column="rentDueThrough"
                      title="Rent Due Through"
                    />
                    <CurrencyField
                      fullWidth
                      column="ongoingRent"
                      title="Ongoing Rent Amount Due"
                    />
                    <ValueField
                      fullWidth
                      column="rentDescription"
                      title="Rent Description"
                    />
                  </CollapseField>
                </fieldset>

                <fieldset className={classes.fieldset}>
                  <ValueField
                    fullWidth
                    column="tenantCurePeriod"
                    title="Tenant Cure Period"
                  />
                  <ValueField
                    fullWidth
                    column="defaultNoticeCurePeriod"
                    title="Default Notice Cure Period"
                  />
                </fieldset>
              </Paper>

              <Paper className={classes.paper}>
                <Typography className={classes.header} variant="h6">
                  Lease Interest
                </Typography>
                <fieldset className={classes.fieldset}>
                  <InterestField
                    kind={InterestKind.LEASE}
                    title="Lease Interest"
                  />
                  <ValueField
                    fullWidth
                    column="leaseInterestSection"
                    title="Lease Interest Authorizing Section"
                  />
                </fieldset>
              </Paper>

              <Paper className={classes.paper}>
                <Typography className={classes.header} variant="h6">
                  Attorneys Legal Fees
                </Typography>
                <fieldset className={classes.fieldset}>
                  <CommissionField
                    kind={CommissionKind.LEASE}
                    title="Attorney Commission"
                  />
                  <ValueField
                    fullWidth
                    column="attorneysFeesSection"
                    title="Attorney Fees Authorizing Section"
                  />
                </fieldset>
              </Paper>

              <Paper className={classes.paper}>
                <Typography className={classes.header} variant="h6">
                  Late Fees
                </Typography>

                <fieldset className={classes.fieldset}>
                  <CurrencyField
                    fullWidth
                    column="lateFeeAmount"
                    title="Late Fee Amount"
                  />
                  <ValueField
                    fullWidth
                    column="lateFeeBasis"
                    title="Late Fee Basis"
                  />
                  <ValueField
                    fullWidth
                    column="lateFeeSection"
                    title="Late Fee Authorizing Section"
                  />
                </fieldset>
              </Paper>
            </div>
          );
        }}
      </RowRenderer>
    </div>
  );
}

function CommissionField(props: { title: string; kind: CommissionKind }) {
  const summarize = (row: MutableRow<Commission>) => {
    const commissionAmount = row.getField("commissionAmount");
    const basis = row.getField("basis");
    return `$${commissionAmount || "(Unspecified)"} at ${
      basis || "(Unspecified)"
    }`;
  };

  return (
    <RowRenderer<Commission>
      query={sql`select * from ${"commission"} where kind=${props.kind}`}
    >
      {(row: Commission) => {
        return (
          <CollapseField<Commission>
            fullWidth={true}
            title={props.title}
            summarizeIf={["commissionAmount", "basis"]}
            summarize={summarize}
          >
            <CurrencyField
              fullWidth
              column="commissionAmount"
              placeholder="Amount"
              title={`${props.title}: Amount`}
            />
            <ValueField
              fullWidth
              column="basis"
              placeholder="Basis"
              title={`${props.title}: Basis`}
            />
          </CollapseField>
        );
      }}
    </RowRenderer>
  );
}

function InterestField(props: { title: string; kind: InterestKind }) {
  const summarize = (row: MutableRow<Interest>) => {
    const rate = row.getField("rate");
    const basis = row.getField("basis") as InterestBasis;
    return `${rate || "(Unspecified)"}% at ${
      InterestBasisDescription[basis] || "(Unspecified)"
    }`;
  };

  return (
    <RowRenderer<Interest>
      query={sql`select * from ${"interest"} where kind=${props.kind}`}
    >
      {(row: Interest) => {
        return (
          <CollapseField<Interest>
            fullWidth={true}
            title={props.title}
            summarizeIf={["rate", "basis"]}
            summarize={summarize}
          >
            <PercentageField
              fullWidth
              column="rate"
              placeholder="Rate"
              title={`${props.title}: Rate`}
            />
            <EnumField<InterestBasis>
              values={InterestBasisDescription}
              fullWidth
              column="basis"
              placeholder="Basis"
              title={`${props.title}: Basis`}
            />
          </CollapseField>
        );
      }}
    </RowRenderer>
  );
}

function NoticeEditor(props: { case: Case }) {
  const classes = useFlowStyles();
  return (
    <div>
      <Typography className={classes.header} variant="h5">
        <FontAwesomeIcon icon={faAddressBook} size="lg" />
        Notice Information
      </Typography>

      <RowRenderer<Entity>
        query={sql`select * from ${"entity"} where kind=${EntityKind.TENANT}`}
      >
        {(row: Entity) => {
          return (
            <div>
              <Paper className={classes.paper}>
                <fieldset className={classes.fieldset}>
                  <AddressField
                    fullWidth
                    column="noticeAddressId"
                    title="Tenant Notice Address"
                    copyFromTitle="Copy From Tenant"
                    copyFrom={(database: DiffableDatabase) =>
                      loadAddressForEntity(database, EntityKind.TENANT)
                    }
                  />
                  <BooleanFieldDisplay
                    title="Tenant has a guarantor"
                    column="hasGuarantor"
                  >
                    <RowRenderer<Entity>
                      query={sql`select * from ${"entity"} where kind=${
                        EntityKind.GUARANTOR
                      }`}
                    >
                      {(row: Entity) => (
                        <AddressField
                          fullWidth
                          column="noticeAddressId"
                          title="Guarantor Notice Address"
                          copyFromTitle="Copy From Guarantor"
                          copyFrom={(database: DiffableDatabase) =>
                            loadAddressForEntity(database, EntityKind.GUARANTOR)
                          }
                        />
                      )}
                    </RowRenderer>
                  </BooleanFieldDisplay>
                </fieldset>
              </Paper>
            </div>
          );
        }}
      </RowRenderer>
    </div>
  );
}

function ServiceEditor(props: { case: Case }) {
  const classes = useFlowStyles();
  const emptyAddress = {
    address: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
  };

  return (
    <div>
      <Typography className={classes.header} variant="h5">
        <FontAwesomeIcon icon={faMailBulk} size="lg" />
        Service Information
      </Typography>
      <ListField<ServiceAddress>
        tableName="serviceaddress"
        rowTitle="Service Address"
        empty={emptyAddress}
        maximumCount={5}
        addDescription="Added new service address"
        removeDescription="Removed service address"
      >
        {(row: MutableRow<ServiceAddress>, index: number) => {
          return (
            <AddressEditorField
              title={`Service Address ${index + 1}`}
              fullWidth
            />
          );
        }}
      </ListField>
    </div>
  );
}

function AffiantEditor(props: { case: Case }) {
  const classes = useFlowStyles();
  return (
    <div>
      <Typography className={classes.header} variant="h5">
        <FontAwesomeIcon icon={faSignature} size="lg" />
        Affiant Information
      </Typography>

      <RowRenderer<Affiant> query={sql`select * from ${"affiant"} limit 1`}>
        {(row: Affiant) => {
          return (
            <div>
              <Paper className={classes.paper}>
                <fieldset className={classes.fieldset}>
                  <ValueField
                    fullWidth
                    column="fullName"
                    title="Affiant Full Name"
                  />
                  <ValueField
                    fullWidth
                    column="pronoun"
                    title="Affiant Pronoun"
                  />
                  <ValueField fullWidth column="title" title="Affiant Title" />
                </fieldset>
              </Paper>

              <PleadingAttorneyManager />
            </div>
          );
        }}
      </RowRenderer>
    </div>
  );
}

function CaptionSummary(props: { case: Case }) {
  const classes = useFlowStyles();
  return (
    <div>
      <RowRenderer<Entity>
        query={sql`select * from ${"entity"} where kind=${EntityKind.TENANT}`}
      >
        {(tenant: Entity) => {
          return (
            <Paper className={classes.captionDisplay}>
              <RowRenderer<Entity>
                query={sql`select * from ${"entity"} where kind=${
                  EntityKind.LANDLORD
                }`}
              >
                {(row: Entity) => {
                  return <Caption title="Plaintiff/Landlord" entity={row} />;
                }}
              </RowRenderer>

              <strong>v.</strong>
              <Caption title="Defendant/Tenant" entity={tenant} />
              <BooleanFieldDisplay
                title="Tenant has a guarantor"
                column="hasGuarantor"
              >
                <strong>and</strong>
                <RowRenderer<Entity>
                  query={sql`select * from ${"entity"} where kind=${
                    EntityKind.GUARANTOR
                  }`}
                >
                  {(row: Entity) => {
                    return <Caption title="Defendant/Guarantor" entity={row} />;
                  }}
                </RowRenderer>
              </BooleanFieldDisplay>
            </Paper>
          );
        }}
      </RowRenderer>
    </div>
  );
}

function CaptionDisplay(props: { case: Case }) {
  const classes = useFlowStyles();
  return (
    <div>
      <Typography className={classes.header} variant="h5">
        <FontAwesomeIcon icon={faFileInvoice} size="lg" />
        Caption Review
      </Typography>

      <CaptionSummary case={props.case} />

      <Paper className={classes.paper}>
        <Typography className={classes.header} variant="h6">
          Court
        </Typography>
        <fieldset className={classes.fieldset}>
          <RowRenderer<Court> query={sql`select * from ${"court"}`}>
            {(row: Court) => {
              return (
                <>
                  <StateField fullWidth column="state" title="Court State" />
                  <ValueField fullWidth column="county" title="Court County" />
                  <ValueField fullWidth column="court" title="Court Name" />
                  <ValueField
                    fullWidth
                    column="docketNumber"
                    title="Docket Number"
                  />
                  <ValueField
                    multiline
                    fullWidth
                    column="barAssociation"
                    title="Bar Association"
                  />
                </>
              );
            }}
          </RowRenderer>
        </fieldset>
      </Paper>
    </div>
  );
}

function Caption(props: { title: string; entity: Entity }) {
  const classes = useFlowStyles();
  return (
    <Paper className={classes.caption}>
      <Chip
        className={classes.captionTitle}
        color="primary"
        label={props.title}
      />
      <div>
        {props.entity.fullname || "(Unspecified Name)"}
        {props.entity.alias ? ` ${props.entity.alias}` : undefined}
      </div>
      <RowRenderer<Address>
        query={sql`select * from ${"address"} where id=${
          props.entity.addressId
        }`}
      >
        {(row: Address) => {
          if (!row.address || !row.city || !row.state || !row.zipcode) {
            return <div>(Incomplete address)</div>;
          }
          return (
            <div>
              <div>{row.address}</div>
              {row.address2 && <div>{row.address2}</div>}
              <div>
                {row.city}, {row.state} {row.zipcode}
              </div>
            </div>
          );
        }}
      </RowRenderer>
    </Paper>
  );
}

function EntityView(props: { title: string }) {
  const summarize = (row: MutableRow<Entity>) => {
    const fullname = row.getField("fullname");
    const type = row.getField("type") as EntityType;
    return `${fullname}${
      type !== EntityType.UNKNOWN
        ? " (" + EntityTypeDescription[type] + ")"
        : ""
    }`;
  };
  return (
    <CollapseField<Entity>
      fullWidth={true}
      title={props.title}
      summarize={summarize}
    >
      <EnumField<EntityType>
        values={EntityTypeDescription}
        fullWidth
        column="type"
        placeholder="Type"
        title={`${props.title}: Type`}
      />
      <ValueField
        fullWidth
        column="shortName"
        placeholder="Short Name"
        title={`${props.title}: Short Name`}
      />
      <ValueField
        fullWidth
        column="fullname"
        placeholder="Full Name"
        title={`${props.title}: Full Name`}
      />
      <ValueField
        fullWidth
        column="alias"
        placeholder="Alias/DBA"
        title={`${props.title}: Alias/DBA`}
      />
    </CollapseField>
  );
}

function LitigationEditor(props: { case: Case }) {
  const classes = useFlowStyles();
  return (
    <div>
      <RowRenderer<Litigation>
        query={sql`select * from ${"litigation"} limit 1`}
      >
        {(row: Litigation) => {
          return (
            <div>
              <Typography className={classes.header} variant="h5">
                <FontAwesomeIcon icon={faGavel} size="lg" />
                Litigation
              </Typography>

              <Paper className={classes.paper}>
                <Typography className={classes.header} variant="h6">
                  Tenant Dates
                </Typography>
                <fieldset className={classes.fieldset}>
                  <DateField
                    fullWidth
                    column="tenantServiceDate"
                    title="Tenant Service Date"
                  />
                  <DateField
                    fullWidth
                    column="tenantDefaultDate"
                    title="Tenant Default Date"
                  />
                  <DateField
                    fullWidth
                    column="tenantDefaultNoticeDate"
                    title="Tenant Default Notice Date"
                  />
                  <DateField
                    fullWidth
                    column="tenantJudgementDate"
                    title="Tenant Judgment Date"
                  />
                </fieldset>
              </Paper>

              <RowRenderer<Entity>
                query={sql`select * from ${"entity"} where kind=${
                  EntityKind.TENANT
                }`}
              >
                {(row: Entity) => {
                  return (
                    <BooleanFieldDisplay
                      title="Tenant has a guarantor"
                      column="hasGuarantor"
                    >
                      <RowRenderer<Litigation>
                        query={sql`select * from ${"litigation"} limit 1`}
                      >
                        {(row: Litigation) => {
                          return (
                            <Paper className={classes.paper}>
                              <Typography
                                className={classes.header}
                                variant="h6"
                              >
                                Guarantor Dates
                              </Typography>
                              <fieldset className={classes.fieldset}>
                                <DateField
                                  fullWidth
                                  column="guarantorServiceDate"
                                  title="Guarantor Service Date"
                                />
                                <DateField
                                  fullWidth
                                  column="guarantorDefaultDate"
                                  title="Guarantor Default Date"
                                />
                                <DateField
                                  fullWidth
                                  column="guarantorDefaultNoticeDate"
                                  title="Guarantor Default Notice Date"
                                />
                                <DateField
                                  fullWidth
                                  column="guarantorJudgementDate"
                                  title="Guarantor Judgment Date"
                                />
                              </fieldset>
                            </Paper>
                          );
                        }}
                      </RowRenderer>
                    </BooleanFieldDisplay>
                  );
                }}
              </RowRenderer>

              <Paper className={classes.paper}>
                <Typography className={classes.header} variant="h6">
                  Judgment Amount / Default Judgment Amount
                </Typography>
                <fieldset className={classes.fieldset}>
                  <CurrencyField
                    fullWidth
                    column="complaintAmount"
                    title="Complaint Amount"
                  />
                  <CurrencyField
                    fullWidth
                    column="ongoingRent"
                    title="Ongoing Rent"
                  />
                  <CurrencyField
                    fullWidth
                    column="additionalAttorneysFees"
                    title="Additional Attorneys Fees"
                  />
                  <ValueField
                    fullWidth
                    column="ongoingRentDescription"
                    title="Ongoing Rent Description"
                  />
                </fieldset>
              </Paper>

              <Paper className={classes.paper}>
                <Typography className={classes.header} variant="h6">
                  Writ
                </Typography>
                <fieldset className={classes.fieldset}>
                  <CurrencyField
                    fullWidth
                    column="writPrincipal"
                    title="Writ Principal"
                  />
                  <CurrencyField
                    fullWidth
                    column="writInterest"
                    title="Writ Interest"
                  />
                  <CurrencyField
                    fullWidth
                    column="writCosts"
                    title="Writ Costs"
                  />
                  <CurrencyField
                    fullWidth
                    column="writFees"
                    title="Writ Fees"
                  />
                </fieldset>
              </Paper>
            </div>
          );
        }}
      </RowRenderer>
    </div>
  );
}

function MotionEditor(props: { case: Case }) {
  const classes = useFlowStyles();
  return (
    <div>
      <Typography className={classes.header} variant="h5">
        <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
        Motions
      </Typography>
      <CustomMissingBorder bindKey={MISSING_ACTIVE_MOTION_KEY}>
        <ListField<Motion>
          tableName="motion"
          rowTitle="Motion"
          empty={(count: number) => {
            return {
              kind: MotionKind.DISCOVERY,
              isActive: count === 0 ? 1 : 0,
            };
          }}
          maximumCount={10}
          addDescription="Added new motion"
          removeDescription="Removed motion"
        >
          {(row: MutableRow<Motion>, index: number) => {
            return (
              <div>
                <fieldset className={classes.fieldset}>
                  <RadioBooleanField
                    fullWidth
                    column="isActive"
                    title="Is Active Motion"
                  />
                  <EnumField<MotionKind>
                    values={MotionKindDescription}
                    fullWidth
                    column="kind"
                    title="Motion Kind"
                  />

                  <ListField<MotionEntity>
                    tableName="motionentity"
                    rowTitle="Motion Individual"
                    empty={{
                      motionId: row.id(),
                    }}
                    maximumCount={6}
                    addDescription={`Added new motion individual for motion #${
                      index + 1
                    }`}
                    removeDescription={`Removed motion individual for motion #${
                      index + 1
                    }`}
                    query={sql`select * from ${"motionentity"} where motionId=${row.id()}`}
                  >
                    {(
                      entityRow: MutableRow<MotionEntity>,
                      entityIndex: number
                    ) => {
                      return (
                        <div>
                          <fieldset className={classes.fieldset}>
                            <EnumField<MotionEntityType>
                              values={MotionEntityTypeDescription}
                              fullWidth
                              column="type"
                              placeholder="Relation to Motion"
                              title={`Motion individual #${
                                entityIndex + 1
                              } type`}
                            />
                            <AutocompleteField
                              column="name"
                              fullWidth
                              title={`Motion individual #${
                                entityIndex + 1
                              } name`}
                              placeholder="Name"
                              prefilledOptions={[
                                "Landlord",
                                "Tenant",
                                "Guarantor",
                              ]}
                            />
                          </fieldset>
                        </div>
                      );
                    }}
                  </ListField>

                  <DateField
                    fullWidth
                    column="complaintDate"
                    title="Complaint Date"
                  />
                  <DateField
                    fullWidth
                    column="answerDate"
                    title="Answer Date"
                  />
                  <DateField
                    fullWidth
                    column="discoveryDate"
                    title="Discovery Date"
                  />
                  <DateField
                    fullWidth
                    column="discoveryEndDate"
                    title="Discovery End Date"
                  />
                  <ValueField
                    fullWidth
                    column="discoverySent"
                    title="Discovery Sent"
                  />
                  <DocumentDateField
                    column="orderDate"
                    fullWidth
                    title="Order Date"
                  />

                  <ListField<OverdueNotice>
                    tableName="overduenotice"
                    rowTitle="Overdue Notice"
                    empty={{
                      motionId: row.id(),
                    }}
                    maximumCount={5}
                    addDescription={`Added new overdue notice for motion #${
                      index + 1
                    }`}
                    removeDescription={`Removed overdue notice for motion #${
                      index + 1
                    }`}
                    query={sql`select * from ${"overduenotice"} where motionId=${row.id()}`}
                  >
                    {(
                      noticeRow: MutableRow<OverdueNotice>,
                      noticeIndex: number
                    ) => {
                      return (
                        <div>
                          <fieldset className={classes.fieldset}>
                            <DateField
                              fullWidth
                              column="date"
                              title={`Overdue notice date for notice #${
                                noticeIndex + 1
                              }`}
                            />
                          </fieldset>
                        </div>
                      );
                    }}
                  </ListField>
                </fieldset>
              </div>
            );
          }}
        </ListField>
      </CustomMissingBorder>
    </div>
  );
}
