import React, { useContext, useEffect, useState } from "react";
import {
  DiffableDatabase,
  MutableRow,
  RowType,
} from "../../database/diffable/interfaces";
import {
  DocumentMissingTracker,
  MissingValueDescription,
  MissingValueKey,
  isEmptyStringValue,
} from "../../services/documentservice";
import { Case } from "../../types/case";
import { DocumentTemplate } from "../../types/documentemplate";

const COLORS = [
  "#9c27b0",
  "#673ab7",
  "#e91e63",
  "#2196f3",
  "#009688",
  "#4caf50",
  "#f57f17",
  "#bf360c",
  "#f48fb1",
  "#c4d191",
  "#d18651",
  "#887fd1",

  // From d3 ordinal 20.
  "#3182bd",
  "#6baed6",
  "#9ecae1",
  "#c6dbef",
  "#e6550d",
  "#fd8d3c",
  "#fdae6b",
  "#fdd0a2",
  "#31a354",
  "#74c476",
  "#a1d99b",
  "#c7e9c0",
  "#756bb1",
  "#9e9ac8",
  "#bcbddc",
  "#dadaeb",
  "#636363",
  "#969696",
  "#bdbdbd",
  "#d9d9d9",
];

export interface DocumentToGenerate {
  docTemplate: DocumentTemplate;
  missing: DocumentMissingTracker;
  color: string;
}

export type Callback = () => void;

function getPMFKey(tableName: string, id: number, columnName: string): string {
  return `${tableName}:${id}::${columnName}`;
}

function getRowKey(tableName: string, id: number): string {
  return `${tableName}:${id}`;
}

function getColKey(tableName: string, columnName: string): string {
  return `${tableName}::${columnName}`;
}

/**
 * DocumentTrackerState is the state for a DocumentRacker.
 */
export interface DocumentTrackerState {
  tracker: DocumentTracker;
  documents: DocumentToGenerate[];
}

/**
 * useDocumentTracker is a hook which updates whenever the document tracker updates. It is a singleton
 * instantiated and accessed internally via context.
 */
export function useDocumentTracker(): DocumentTrackerState {
  const tracker = useContext(DocumentTrackerContext)!;
  const [documents, setDocuments] = useState<DocumentToGenerate[]>(
    tracker.documents()
  );

  useEffect(() => {
    const removeCallback = tracker.addCallback(() => {
      setDocuments(tracker.documents());
    });

    return () => {
      removeCallback();
    };
  }, [tracker]);

  return {
    tracker: tracker,
    documents: documents,
  };
}

export type MissingForValueKey = MissingValueDescription & {
  missingColor: string | undefined;
};

/**
 * DocumentTracker is used to track the missing fields for documents that were
 * attempted to be generated.
 */
export class DocumentTracker {
  constructor(
    private cse: Case,
    private diffableDatabase: DiffableDatabase | undefined,
    private documentKindsByTemplateId: Record<string, DocumentToGenerate> = {},
    private documentByField: Record<string, DocumentToGenerate> = {},
    private documentByColumn: Record<string, DocumentToGenerate> = {},
    private documentByMissingValueKey: Record<
      MissingValueKey,
      DocumentToGenerate
    > = {},
    private rowMap: Record<string, DocumentToGenerate> = {},
    private callbacks: Callback[] = [],
    private colorIndex = 0
  ) {}

  /**
   * checkValueIsMissingForField returns the missing field information for a specific field on a row.
   */
  public checkValueIsMissingForField<T extends RowType = RowType, Q = string>(
    value: Q,
    row: MutableRow<T>,
    columnName: string,
    checker: (value: Q) => boolean = isEmptyStringValue
  ) {
    const hasMissingValue = checker(value);
    const document = this.documentForField(row.tableName, row.id(), columnName);
    return {
      hasMissingValue: hasMissingValue,
      missingColor: hasMissingValue ? document?.color : undefined,
      missingForDocument: document,
    };
  }

  /**
   * missingForValueKey returns whether a missing value is missing for at least
   * one document in the tracker.
   * @param key The missing value key.
   */
  public async missingForValueKey(
    key: MissingValueKey
  ): Promise<MissingForValueKey> {
    if (this.diffableDatabase === undefined) {
      return {
        missingColor: undefined,
        missingMessage: "",
        isMissing: false,
      };
    }

    const document = this.documentForMissingValueKey(key);
    const missingValue = document?.missing.getMissingValue(key);
    if (document === undefined || missingValue === undefined) {
      return {
        missingColor: undefined,
        missingMessage: "",
        isMissing: false,
      };
    }

    const result = await document.missing.checkIfValueIsCurrentlyMissing(
      key,
      this.cse,
      this.diffableDatabase
    );
    return {
      ...result,
      missingColor: result.isMissing ? document.color : undefined,
    };
  }

  public isReady(): boolean {
    return this.diffableDatabase !== undefined;
  }

  /**
   * missingFieldsAndValuesCount returns the count of fields from the DTF that are currently
   * missing in the database.
   */
  public async missingFieldsAndValuesCount(dtg: DocumentToGenerate) {
    if (this.diffableDatabase === undefined) {
      return 0;
    }

    return dtg.missing.currentMissingFieldsAndValuesCount(
      this.cse,
      this.diffableDatabase
    );
  }

  /**
   * addCallback adds a callback to be invoked when the documents tracked change.
   * Returns a callback to invoke to deregister the callback.
   */
  public addCallback(callback: Callback): Callback {
    this.callbacks.push(callback);
    return () => {
      const index = this.callbacks.indexOf(callback, 0);
      if (index > -1) {
        this.callbacks.splice(index, 1);
      }
    };
  }

  /**
   * rowHasMissingField returns if the given row in the given table has a missing
   * field in one or more documents.
   * @param tableName The name of the table for the row.
   * @param id The unique ID for the row in the table.
   */
  public rowHasMissingField(tableName: string, id: number) {
    const key = getRowKey(tableName, id);
    return key in this.rowMap;
  }

  /**
   * documentForMissingValueKey returns the first document to generate for the given missing value key,
   * or undefined if none.
   */
  public documentForMissingValueKey(
    key: MissingValueKey
  ): DocumentToGenerate | undefined {
    if (!(key in this.documentByMissingValueKey)) {
      return undefined;
    }

    return this.documentByMissingValueKey[key];
  }

  /**
   * documentForRow returns the first document to generate for the given row, or undefined
   * if none.
   * @param tableName The name of the table for the row.
   * @param id The unique ID for the row in the table.
   */
  public documentForRow(
    tableName: string,
    id: number
  ): DocumentToGenerate | undefined {
    if (!this.rowHasMissingField(tableName, id)) {
      return undefined;
    }

    const key = getRowKey(tableName, id);
    return this.rowMap[key];
  }

  /**
   * documentForField returns the DTG that contains a the given column, on the given
   * row, as missing.
   * @param tableName The name of the table for the row.
   * @param id The unique ID for the row in the table.
   * @param columnName The name of the column that might be missing.
   */
  public documentForField(
    tableName: string,
    id: number,
    columnName: string
  ): DocumentToGenerate | undefined {
    const key = getPMFKey(tableName, id, columnName);
    if (!(key in this.documentByField)) {
      return undefined;
    }
    return this.documentByField[key];
  }

  /**
   * documentForColumn returns the DTG that contains the given column on the given table,
   * or undefined if none.
   * @param tableName
   * @param columnName
   */
  public documentForColumn(
    tableName: string,
    columnName: string
  ): DocumentToGenerate | undefined {
    const key = getColKey(tableName, columnName);
    if (!(key in this.documentByColumn)) {
      return undefined;
    }
    return this.documentByColumn[key];
  }

  /**
   * hasDocuments returns whether there are any documents in the tracker.
   */
  public hasDocuments(): boolean {
    return this.documents().length > 0;
  }

  /**
   * documents returns all the documents in the tracker.
   */
  public documents(): DocumentToGenerate[] {
    return Object.values(this.documentKindsByTemplateId);
  }

  /**
   * hasDocument returns whether the tracker contains the document.
   */
  public hasDocument(docTemplate: DocumentTemplate) {
    return docTemplate.id in this.documentKindsByTemplateId;
  }

  /**
   * getDocument returns the DTG for the document, if any.
   */
  public getDocument(
    docTemplate: DocumentTemplate
  ): DocumentToGenerate | undefined {
    if (!this.hasDocument(docTemplate)) {
      return undefined;
    }

    return this.documentKindsByTemplateId[docTemplate.id];
  }

  /**
   * addDocument adds a document of the specific type, with the given missing fields,
   * to the tracker.
   */
  public addDocument(
    docTemplate: DocumentTemplate,
    missing: DocumentMissingTracker,
    skipRefresh?: boolean
  ): DocumentToGenerate {
    let color = COLORS[this.colorIndex % COLORS.length];
    if (docTemplate.id in this.documentKindsByTemplateId) {
      color = this.documentKindsByTemplateId[docTemplate.id].color;
    } else {
      this.colorIndex++;
    }

    const dtg = {
      docTemplate: docTemplate,
      missing: missing,
      color: color,
    };
    this.documentKindsByTemplateId[docTemplate.id] = dtg;
    this.rebuild();

    if (skipRefresh !== true) {
      this.callbacks.forEach((callback: Callback) => callback());
    }
    return dtg;
  }

  public refresh() {
    this.callbacks.forEach((callback: Callback) => callback());
  }

  public removeDocument(docTemplate: DocumentTemplate) {
    delete this.documentKindsByTemplateId[docTemplate.id];
    this.rebuild();
    this.callbacks.forEach((callback: Callback) => callback());
  }

  public removeAllDocuments() {
    this.documentKindsByTemplateId = {};
    this.rebuild();
    this.callbacks.forEach((callback: Callback) => callback());
  }

  private rebuild() {
    this.documentByField = {};
    this.documentByColumn = {};
    this.documentByMissingValueKey = {};
    this.rowMap = {};

    Object.values(this.documentKindsByTemplateId).forEach(
      (dtg: DocumentToGenerate) => {
        dtg.missing.listFieldsWithMissingValues().forEach((pmf) => {
          this.documentByField[
            getPMFKey(pmf.tableName, pmf.rowId, pmf.columnName)
          ] = dtg;
          this.rowMap[getRowKey(pmf.tableName, pmf.rowId)] = dtg;
          this.documentByColumn[getColKey(pmf.tableName, pmf.columnName)] = dtg;
        });

        dtg.missing.missingValueKeys().forEach((key: MissingValueKey) => {
          this.documentByMissingValueKey[key] = dtg;
        });
      }
    );
  }
}

/**
 * DocumentTrackerContext defines context which holds a reference to the document tracker,
 * which tracks which documents were attempted to be generated and any missing fields for them.
 */
export const DocumentTrackerContext = React.createContext<
  DocumentTracker | undefined
>(undefined);
DocumentTrackerContext.displayName = "CurrentDocumentTracker";
