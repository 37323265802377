import { ApolloClient } from "@apollo/client";
import {
  faCircleQuestion,
  faSave,
  faTimesCircle,
  faUndo,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField, Typography, alpha } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import clsx from "clsx";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useKeyboardShortcuts } from "use-keyboard-shortcuts";
import { DatabaseContext } from "../database/diffable/context";
import { useMutationWatcher } from "../database/diffable/hooks";
import { DiffableDatabase } from "../database/diffable/interfaces";
import { ApplicationConfig } from "../services/configservice";
import { GPTServiceState, useGPT } from "../services/gptservice";
import { Case } from "../types/case";
import { MutationPanel } from "./MutationPanel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    gpt: {
      position: "absolute",
      bottom: "-100px",
      right: "70px",
      width: "60vw",
      opacity: 0,
      padding: theme.spacing(2),
      backgroundColor: alpha(theme.palette.background.default, 0.8),
      borderRadius: "6px",
      transition: "all ease-in-out 300ms",
    },
    gptVisible: {
      opacity: 1,
      bottom: "0px",
    },
  })
);

export function FloatingActionButton(props: {
  case: Case;
  database: DiffableDatabase;
  client: ApolloClient<any>;
  appConfig: ApplicationConfig;
}) {
  const classes = useStyles();

  useKeyboardShortcuts([
    {
      keys: ["ctrl", "s"],
      onEvent: (event) => {
        (async () => {
          applyChanges();
        })();
      },
    },
    {
      keys: ["ctrl", "h"],
      onEvent: (event) => {
        if (gpt.supported) {
          setIsGPTVisible(true);
        }
      },
    },
  ]);

  const applyChanges = () => {
    props.database.applyAndSave();
    setOpen(false);
  };

  const undoChanges = () => {
    props.database.revertChanges();
    setOpen(false);
  };

  const mutationInfo = useMutationWatcher(props.database, 100);
  const mutationCount = mutationInfo.currentTransaction.currentMutationCount();

  const [open, setOpen] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const hasMutations = mutationCount !== 0;
  const history = useHistory();

  const database = useContext(DatabaseContext)!;
  const gpt = useGPT(database, props.case, props.client, props.appConfig);
  const [isGPTVisible, setIsGPTVisible] = useState(false);

  const handleFabClicked = async () => {
    if (hasMutations) {
      applyChanges();
      return;
    }

    if (gpt.supported) {
      setIsGPTVisible(!isGPTVisible);
      return;
    }

    history.push(`/c/${props.case.id}/documents?tab=new`);
  };

  const isDisabled = props.database.applying || gpt.running;

  return (
    <div className={classes.fab}>
      <GPTView
        gpt={gpt}
        isVisible={isGPTVisible}
        onClose={() => setIsGPTVisible(false)}
      />
      <Badge
        color="secondary"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent={mutationCount}
      >
        <SpeedDial
          ariaLabel="Save changes"
          icon={
            props.database.applying ? (
              <CircularProgress />
            ) : hasMutations ? (
              <FontAwesomeIcon icon={faSave} size="2x" />
            ) : gpt.supported ? (
              isGPTVisible ? (
                gpt.running ? (
                  <CircularProgress />
                ) : (
                  <FontAwesomeIcon icon={faTimesCircle} size="2x" />
                )
              ) : (
                <FontAwesomeIcon icon={faCircleQuestion} size="2x" />
              )
            ) : (
              <NoteAddIcon fontSize="large" color="action" />
            )
          }
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          direction="up"
          FabProps={{ disabled: isDisabled, onClick: handleFabClicked }}
        >
          {!isDisabled && hasMutations && (
            <SpeedDialAction
              icon={<FontAwesomeIcon icon={faSave} />}
              tooltipTitle="Save Changes"
              tooltipOpen
              onClick={applyChanges}
            />
          )}
          {!isDisabled && hasMutations && (
            <SpeedDialAction
              icon={<FontAwesomeIcon icon={faUndo} />}
              tooltipTitle="Undo Changes"
              tooltipOpen
              onClick={undoChanges}
            />
          )}
          {!isDisabled && hasMutations && (
            <SpeedDialAction
              icon={<FontAwesomeIcon icon={faUserEdit} />}
              tooltipTitle="Review Changes"
              tooltipOpen
              onClick={() => setDrawerOpen(true)}
            />
          )}
        </SpeedDial>
      </Badge>
      <MutationPanel
        database={props.database}
        isOpen={drawerOpen}
        closePanel={() => setDrawerOpen(false)}
      />
    </div>
  );
}

function GPTView(props: {
  gpt: GPTServiceState;
  isVisible: boolean;
  onClose: () => void;
}) {
  const classes = useStyles();
  const [query, setQuery] = useState("");
  const isRunning = props.gpt.running;

  useEffect(() => {
    if (!isRunning) {
      setQuery("");
    }
  }, [isRunning]);

  return (
    <div
      className={clsx(classes.gpt, { [classes.gptVisible]: props.isVisible })}
    >
      {props.gpt.outputs.map((output, index) => {
        return (
          <Typography
            key={index}
            variant="subtitle2"
            color={output.kind === "thought" ? "textSecondary" : "textPrimary"}
          >
            {output.content}
          </Typography>
        );
      })}
      <TextField
        fullWidth
        placeholder="Enter a question or a request"
        disabled={props.gpt.running}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyUp={(e) => {
          if (e.key.toLowerCase() === "enter" && query) {
            props.gpt.run(query);
          }
        }}
      />
    </div>
  );
}
