import { gql } from "@apollo/client";
import { WITH_ERRORS_FRAGMENT, WithErrors } from "./lib/hooks";

export const INVOKE_GPT_QUERY = {
  gql: gql`
 mutation InvokeGptQuery($caseId: ID!, $fields: [TitleAndDescription]!, $documents: [TitleAndDescription]!, $litigations: [TitleAndDescription]!, $existing: [GPTMessage]!, $message: String!) {
  invokeGptQuery(caseId: $caseId, fields: $fields, documents: $documents, litigations: $litigations, existing: $existing, message: $message) {
    ${WITH_ERRORS_FRAGMENT}
    response
  }
 }`,
  workingMessage: "Making request to GPT",
  errorMessage: "Could not invoke query",
  refetchQueries: [],
};

type TitleAndDescription = {
  title: string;
  description: string;
};

export type GPTMessage = {
  content: string;
  usermsg: boolean;
};

export interface InvokeGPTQueryData {
  caseId: string;
  fields: TitleAndDescription[];
  documents: TitleAndDescription[];
  litigations: TitleAndDescription[];
  existing: GPTMessage[];
  message: string;
}

export type InvokeGPTQueryResponse = {
  invokeGptQuery: WithErrors & {
    response: string;
  };
};
