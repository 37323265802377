import { ApolloConsumer } from "@apollo/client";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import * as H from "history";
import React, { useEffect, useMemo, useState } from "react";
import { Prompt, Route, Switch } from "react-router-dom";
import { DatabaseContext } from "../database/diffable/context";
import {
  useDiffableVersionedDatabase,
  useMutationWatcher,
} from "../database/diffable/hooks";
import { DiffableDatabase } from "../database/diffable/interfaces";
import { useAuthenticationService } from "../services/authentication";
import {
  useApplicationConfig,
  WithApplicationConfig,
} from "../services/confighook";
import { ApplicationConfig } from "../services/configservice";
import { TEMPLATES } from "../templates";
import {
  BuildFlowUIRenderer,
  BuildFlowUISidebarRenderer,
} from "../templates/uibuilder";
import { Case } from "../types/case";
import { useAlert } from "./AlertProvider";
import { CurrentCaseContext } from "./CurrentContext";
import {
  DocumentTracker,
  DocumentTrackerContext,
} from "./documents/DocumentTracker";
import { TrackedDocumentView } from "./documents/TrackedDocumentView";
import { FloatingActionButton } from "./FloatingActionButton";
import LoadingView from "./LoadingView";
import SideDrawer from "./SideDrawer";

interface CaseTemplatedLayoutProps {
  /**
   * case is the currently loaded case.
   */
  case: Case;

  /**
   * Whether the SideDrawer is currently open or collapsed.
   */
  isSideBarOpen: boolean;

  /**
   * Callback function to toggle the drawer.
   */
  toggleDrawer: () => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    templateWrap: {
      display: "flex",
      width: "100%",
    },
    content: {
      flexGrow: 1,
    },
    toolbarSpacer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  })
);

function DatabasePrompt(props: { database: DiffableDatabase; case: Case }) {
  const { currentTransaction } = useMutationWatcher(props.database, 100);
  return (
    <Prompt
      when={currentTransaction.currentMutationCount() > 0}
      message={(location: H.Location) => {
        if (!location.pathname.startsWith(`/c/${props.case.id}`)) {
          return `Are you sure you want to leave this case? You have unsaved changes!`;
        }
        return true;
      }}
    />
  );
}

export default function CaseTemplatedLayout(props: CaseTemplatedLayoutProps) {
  const appConfig = useApplicationConfig();
  if (appConfig === undefined) {
    return <span></span>;
  }

  return (
    <WithApplicationConfig>
      {(appConfig: ApplicationConfig) => {
        return (
          <CaseTemplatedLayoutWithConfig appConfig={appConfig} {...props} />
        );
      }}
    </WithApplicationConfig>
  );
}

function CaseTemplatedLayoutWithConfig(
  props: CaseTemplatedLayoutProps & { appConfig: ApplicationConfig }
) {
  const classes = useStyles();
  const appConfig = props.appConfig;

  const { getToken } = useAuthenticationService();
  const [updateURL, setUpdateURL] = useState(
    `${appConfig.endpoint}${props.case.currentDatabaseRevision?.updateUrl}`
  );
  const [currentRevision, setCurrentRevision] = useState(
    props.case.currentDatabaseRevision!
  );
  const { showAlert } = useAlert();

  const handleUpdateComplete = (success: boolean, data: any) => {
    if (!success) {
      switch (data["error"]) {
        case "INTERNAL_ERROR":
          showAlert({
            title: "Save Failed",
            content:
              "Your update could not be saved. Please try again shortly.",
            buttonTitle: "Okay",
          });
          return;

        case "OUT_OF_SYNC":
          showAlert({
            title: "Save Failed",
            content:
              "Your update could not be saved because the case has been changed in another session or by another user. Please reload the page.",
            buttonTitle: "Okay",
          });
          return;
      }
      return;
    }

    setCurrentRevision({
      id: data["current_revision"]["id"],
      downloadUrl: data["current_revision"]["download_url"],
      updateUrl: data["current_revision"]["update_url"],
      createdAt: data["current_revision"]["created_at"],
      revisionIndex: data["current_revision"]["revision_index"],
    });
    setUpdateURL(
      `${appConfig.endpoint}/${data["current_revision"]["update_url"]}`
    );
  };

  const caseTemplate = TEMPLATES[props.case.templateId!];
  const {
    loading,
    diffableDatabase,
    error,
    downloading,
    migrating,
    migrationProgress,
  } = useDiffableVersionedDatabase(caseTemplate.schema, {
    databaseURL: `${appConfig.endpoint}${props.case.currentDatabaseRevision?.downloadUrl}`,
    updateURL: updateURL,
    getAuthorizationToken: getToken,
    updateComplete: handleUpdateComplete,
  });

  const CaseTemplateRenderer = useMemo(
    () => BuildFlowUIRenderer(caseTemplate),
    [caseTemplate]
  );
  const CaseSidebarRenderer = useMemo(
    () => BuildFlowUISidebarRenderer(caseTemplate),
    [caseTemplate]
  );

  const [documentTracker, setDocumentTracker] = useState<DocumentTracker>(
    new DocumentTracker(props.case, diffableDatabase)
  );

  const cse = props.case;
  useEffect(() => {
    if (!documentTracker.isReady() && diffableDatabase !== undefined) {
      setDocumentTracker(new DocumentTracker(cse, diffableDatabase));
    }
  }, [documentTracker, diffableDatabase, cse]);

  return (
    <div className={classes.root}>
      {error !== undefined && (
        <Snackbar>
          <Alert severity="error">{error}</Alert>
        </Snackbar>
      )}
      {loading && !migrating && <LoadingView message="Preparing database" />}
      {loading && migrating && (
        <LoadingView message={`Updating database: ${migrationProgress}%`} />
      )}
      {downloading === "unknown" && <LinearProgress />}
      {downloading !== undefined && downloading !== "unknown" && (
        <LinearProgress variant="determinate" value={downloading} />
      )}
      {diffableDatabase !== undefined && !loading && (
        <DatabaseContext.Provider value={diffableDatabase}>
          <DocumentTrackerContext.Provider value={documentTracker}>
            <CurrentCaseContext.Provider value={props.case}>
              <DatabasePrompt case={props.case} database={diffableDatabase} />

              <div className={classes.templateWrap}>
                <SideDrawer
                  content={
                    <CaseSidebarRenderer
                      case={props.case}
                      currentRevision={currentRevision}
                    />
                  }
                  drawerWidth={240}
                  isSideBarOpen={props.isSideBarOpen}
                  closeDrawer={props.toggleDrawer}
                />
                <main className={classes.content}>
                  <div className={classes.toolbarSpacer} />
                  <CaseTemplateRenderer
                    case={props.case}
                    currentRevision={currentRevision}
                  />
                </main>
              </div>
              <Switch>
                <Route path="/c/:caseid/document/:documentid" exact />
                <Route path="/c/">
                  <ApolloConsumer>
                    {(client) => (
                      <FloatingActionButton
                        database={diffableDatabase}
                        case={props.case}
                        client={client}
                        appConfig={props.appConfig}
                      />
                    )}
                  </ApolloConsumer>
                </Route>
              </Switch>
              <TrackedDocumentView
                case={props.case}
                currentRevision={currentRevision}
                database={diffableDatabase}
              />
            </CurrentCaseContext.Provider>
          </DocumentTrackerContext.Provider>
        </DatabaseContext.Provider>
      )}
    </div>
  );
}
