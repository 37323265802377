import {
  createStyles,
  FormControl,
  InputAdornment,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import NotchedOutline from "@material-ui/core/OutlinedInput/NotchedOutline";
import React, { useContext } from "react";
import { DatabaseContext, ResultContext } from "../database/diffable/context";
import { useMutationWatcher } from "../database/diffable/hooks";
import { MutableRow } from "../database/diffable/interfaces";
import { currency } from "../shareddata/shareddata";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      borderRadius: "6px",
      backgroundColor: theme.palette.background.default,
    },
    outline: {
      borderRadius: "6px",
      borderColor:
        theme.palette.type === "light"
          ? "rgba(0, 0, 0, 0.23)"
          : "rgba(255, 255, 255, 0.23)",
    },
    content: {
      display: "inline-grid",
      gridTemplateColumns: "auto auto auto",
      alignItems: "center",
      "&:hover": {
        "& $calculation": {
          opacity: 1,
        },
      },
      cursor: "default",
    },
    calculation: {
      display: "inline-grid",
      gridTemplateColumns:
        "auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto",
      alignItems: "center",
      columnGap: theme.spacing(1),
      marginLeft: theme.spacing(1),
      opacity: 0,
      transition: "350ms opacity ease-in-out",
    },
    description: {
      fontSize: "80%",
      color: theme.palette.text.hint,
    },
  })
);

type WithCurrency = { currencyTotal: number };

type AspectFunction = (row: MutableRow) => WithCurrency | undefined;

interface Aspect {
  title: string;
  fieldNameOrFunction: string | AspectFunction;
}

export function aspect(
  title: string,
  fieldNameOrFunction: string | AspectFunction
): Aspect {
  return {
    title: title,
    fieldNameOrFunction: fieldNameOrFunction,
  };
}

interface ComputedValue {
  title: string;
  value: number;
}

export function CalculatedTotalDisplay(props: { aspects: Aspect[] }) {
  const database = useContext(DatabaseContext)!;
  const row = useContext(ResultContext)!;
  const classes = useStyles();

  useMutationWatcher(database, 100);

  const computed: ComputedValue[] = [];
  for (const aspect of props.aspects) {
    if (typeof aspect.fieldNameOrFunction === "string") {
      const parsed = parseFloat(row.getField(aspect.fieldNameOrFunction) || "");
      if (!isNaN(parsed)) {
        computed.push({
          title: aspect.title,
          value: parsed,
        });
      }
    } else {
      const withCurrency = aspect.fieldNameOrFunction(row);
      if (withCurrency) {
        computed.push({
          title: aspect.title,
          value: withCurrency.currencyTotal,
        });
      }
    }
  }

  const total = computed.reduce<number>((pv: number, cv) => pv + cv.value, 0);

  return (
    <FormControl fullWidth className={classes.root}>
      <Typography variant="subtitle1">
        <InputLabel
          style={{ position: "absolute" }}
          shrink={true}
          variant="outlined"
        >
          Total
        </InputLabel>
        <NotchedOutline
          className={classes.outline}
          labelWidth={100}
          notched={true}
        />
        <div className={classes.content}>
          <InputAdornment position="start">$</InputAdornment>
          <span>{currency(total).substring(1)}</span>
          {computed.length > 0 && (
            <div className={classes.calculation}>
              <span>=</span>
              {computed.map((c, index) => {
                return (
                  <React.Fragment key={index}>
                    {index > 0 && <span>+</span>}
                    <span>{currency(c.value)}</span>
                    <span className={classes.description}>{c.title}</span>
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </div>
      </Typography>
    </FormControl>
  );
}
