import {
  DataFieldDefinitions,
  field,
  processor,
} from "../../services/datafielddefinitions";

const Firm_Name = field(
  {
    description: "The name of the firm",
    example: "Smith and Doe, LLP",
  },
  async ({ ctx }) => ctx.case.firm?.title
);

const Firm_Phone = field(
  {
    description: "The phone number of the firm",
    example: "215-555-5555",
  },
  async ({ ctx }) => ctx.case.firm?.phoneNumber
);

const Firm_Fax = field(
  {
    description: "The fax number of the firm",
    example: "215-555-5555",
  },
  async ({ ctx }) => ctx.case.firm?.faxNumber
);

const Firm_Address = field(
  {
    description: "The address of the firm",
    example: "1234 Fake Street\nFake City, FS 12345",
  },
  async ({ ctx }) => ctx.case.firm?.address
);

const Firm_Address_Inline = field(
  {
    description: "The address of the firm, with no newlines",
    example: "1234 Fake Street Fake City, FS 12345",
    isHelperField: true,
  },
  async ({ ctx }) => ctx.case.firm?.address!.split("\n").join(" ")
);

const CaseNumber = processor(
  {
    id: "case_number",
    description: "The case number for this case",
  },
  async ({ ctx }) => {
    return ctx.case.firmCaseReference;
  }
);

const Firm_Case_No = field(
  {
    description: "The internal case number for this case, inside of the firm",
    example: "Case-12345",
    parameters: {
      caseNumber: CaseNumber,
    },
  },
  async ({ caseNumber }) => caseNumber
);

export const FirmFields: DataFieldDefinitions = {
  Firm_Name: Firm_Name,
  Firm_Phone: Firm_Phone,
  Firm_Address: Firm_Address,
  Firm_Fax: Firm_Fax,
  Firm_Address_Inline: Firm_Address_Inline,
  Firm_Case_No: Firm_Case_No,
};
